export const head = {
  home: "بيت",
  team: "فريق",
  case: "قضية",
  industry: "صناعة",
  contact: "اتصال",
  about: "عن"
};
  
export const foot = {
  services: {
      title: "خدمات",
      home: "بيت"
  },
  case: {
      title: "قضية",
      case: "قضية"
  },
  company: {
      title: "شركة",
      contact: "اتصال",
      about: "عن"
  },
  resources: {
      title: "موارد",
      industry: "صناعة"
  },
  stay: {
      title: "ابقى معنا.",
      desc: "أكثر من 2000 عميل مشتركون حول العالم"
  },
  copyright: {
      contact: "اتصل بنا"
  }
};

export const home = {
  banner22: {
    title: "تهانينا الحارة",
    desc: "كان العشاء في بوينس آيرس ناجحًا تمامًا."
  },
  banner1: {
      title: "KNight Consortium",
      desc: "من مستثمري التجزئة إلى المصرفيين، زعيم KNight Consortium"
  },
  banner2: {
      title: "التزامنا",
      desc: "المنافسة العادلة في القطاع المالي"
  },
  banner3: {
      title: "السادة يحبون الثروة، بطريقة مناسبة",
      desc: "إن Knight Consortium على استعداد لتوجيه هذا الطريق الواسع لمزيد من الأشخاص لاكتشافه، حتى يتمكن الجميع من تحقيق الرخاء والازدهار الأولي، ومن ثم تحقيق تحقيق الذات!"
  },
  servicefive: {
      title: "التقدم والتطوير والرعاية هي القيم الأساسية لـ KNight Consortium",
    //   desc: "We are committed to promoting global economic relations and economic development. By integrating global knowledge, technology, funds and talents, we have established overseas service teams and provided funds to help areas lacking in agriculture, industry, economic construction, medical care and education around the world enjoy the same development opportunities. , work together to create a future vision of equality, dignity, prosperity and health.",
      desc: "",
      item1: {
          title: "ملف الشركة",
        //   desc: "KNight Consortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.",
        desc: "تم تأسيس KNight Consortium في سنغافورة في عام 2010 برأس مال أولي يبلغ حوالي 5 ملايين دولار أمريكي، والآن يبلغ رأس المال المُدار 1 مليار دولار أمريكي. نحن ملتزمون بتعزيز العلاقات الاقتصادية العالمية والتنمية الاقتصادية، وإنشاء فرق الخدمة الخارجية من خلال دمج المعرفة العالمية والتكنولوجيا والأموال والمواهب."
      },
      item2: {
          title: "ثقافة الشركة",
          desc: "الغرض التأسيسي لاتحاد KNight هو جمع الحب ونقل الدفء ومساعدة الناس على المشاركة في الأعمال الخيرية ومشاركة حياة أفضل وفرص التنمية معًا. تعزيز الاتصالات حول العالم وتقديم المساعدة للاجئين في البلدان والمناطق المتضررة من الكوارث الطبيعية.<br><br><br>",
      },
      item3: {
          title: "رؤية الشركة",
          desc: "إن الأهداف والدلالات المهمة لتطوير شؤون التعاون الدولي والتنمية المستدامة هي رؤية المنظمة. وفي هذا الاتجاه، نعمل مع المجتمع الدولي والمجتمع المدني ومساعدات التنمية الأخرى لدمج المعرفة والتكنولوجيا والأموال والمواهب بشكل فعال لخلق مستقبل من المساواة والكرامة والرخاء والصحة.",
      }
  },
  portfolio: {
      title: "صناعة الاستثمار كونسورتيوم KNight والحالات الناجحة",
      desc: "نحن نسعى جاهدين لتعزيز التنمية الاقتصادية والاجتماعية العالمية والعلاقات الاقتصادية، وإنشاء فرق خدمة خارجية للمساعدة في تطوير البناء الطبي والزراعي والصناعي والاقتصادي العالمي والتعليم، وما إلى ذلك.",
      cat1: {
          name: "finance",
          item1: {
              title: "قام فريق KNight Consortium الاستثماري بشراء أسهم في عدد من شركات تعدين المعادن الثمينة. كان أداء هذه الشركات جيدًا خلال عام 2012 حيث أدى ارتفاع أسعار المعادن الثمينة إلى ارتفاع أسعار أسهمها، مما أدى إلى تحقيق مكاسب ضخمة لنقابة الفرسان.",
          },
          item2: {
              title: "خلال الفترة من يناير إلى فبراير 2012، تقلبت أسعار الذهب العالمية. انتهز المحللون الماليون من KNight Consortium الفرصة للشراء بأسعار منخفضة وحققوا أرباحًا تقارب 60٪ في ثلاثة أشهر فقط.",
          }
      },
      cat2: {
          name: "medical",
          item1: {
              title: "في عام 2020، تم إنشاء مركز الخدمة الطبية KNight Consortium، ونجحت طريقة علاج \"العلاج المناعي للورم التي تم تطويرها بالاشتراك مع مجموعة Merck Pharmaceutical Group.",
          },
          item2: {
              title: "قام مركز الخدمات الطبية KNight Consortium وشركة Novartis Pharmaceuticals بتطوير عقار Houng-592 الذي يستهدف السرطان.",
          }
      },
      cat3: {
          name: "metaverse",
          item1: {
              title: "في عام 2021، تم إنشاء مركز KNight Consortium web3.0 لإنشاء منصة اجتماعية لامركزية، والتي جذبت اهتمامًا واسع النطاق ولاقت استحسان المستخدمين.",
          },
          item2: {
              title: "في السوق الصاعدة للعملات المشفرة في يناير 2017، اغتنم اتحاد KNight الفرصة وقاد أعضاء المؤسسة إلى تحقيق أرباح عالية، وسرعان ما تضاعفت أصول جميع الأعضاء.",
          }
      }
  },
  counterdata: {
    title: "الوضع الحالي والخطط المستقبلية لـ KNight Consortium",
    desc: "",
    item1: {
        title: 'أموال',
        unit: "مليار",
    },
    item2: {
        title: 'المستخدمين',
        unit: "مليار",
    },
    item3: {
        title: 'يستثمر',
        unit: "شريك",
    },
    item4: {
        title: 'يتبرع',
        unit: "مليار",
    }
  },
  leader: {
      title: "مقدمة لأعضاء تحالف KNight Consortium",
      desc: "يستثمر أعضاء رأس المال النقابي معًا ويقومون بأعمال خيرية ويحققون الحرية المالية ويساهمون في عالم أفضل.",
      item1: {
          name: "Zhang Guofu",
          desc: "حصل على درجة الماجستير في الاقتصاد المالي من جامعة أكسفورد بالمملكة المتحدة. في أغسطس 2010، أسس مؤسسة KNight Consortium Capital Foundation وشغل منصب رئيس مجلس إدارة Consortium Capital."
      },
      item2: {
          name: "Remus John Worm",
          desc: "ماجستير في الاقتصاد من كلية الاقتصاد بجامعة شيكاغو. شغل منصب نائب رئيس مجلس إدارة مؤسسة نايت كابيتال <br><br><br> "
      },
      item3: {
          name: "Li Rongcheng",
          desc: "ماجستير العلوم وماجستير إدارة الأعمال من جامعة أوتاوا. شغل منصب نائب رئيس مجلس إدارة مؤسسة KNight Capital Foundation<br><br>"
      }
  },
  blogpost: {
    title: "KNight كونسورتيوم الرعاية الاجتماعية",
    desc: "الثروة ستجلب بالتأكيد الأمل والحب المتدفق. يحذو اتحاد KNight حذو أباطرة المال الأجانب ويستخدم جزءًا من دخل المؤسسة للأعمال الخيرية لتخفيف الحصار والمساعدة في التنمية، مما جعل اتحاد KNight يحظى بإشادة دولية وثقة المزيد من المستخدمين والمستثمرين.",
    item1: {
        // title: "After the 7.8-magnitude earthquake in Turkey in February 2023, the KNight Consortium donated US$2.3 million to refugees"
        title: 'إن التبرع بالمواد هو مساعدتنا المباشرة والعملية للضحايا. وفي فبراير 2023، تعرضت تركيا لزلزال بقوة 7.8 درجة، وتبرعت مؤسسة نايتس بمبلغ 2.3 مليون دولار أمريكي للاجئين لإعادة بناء منازلهم.',
    },
    item2: {
        // title: "In July 2021, heavy rains caused floods in Zhengzhou, Henan Province, China. The KNight Consortium donated US$1.8 million;"
        title: 'في سبتمبر 2022، ذهب فريق مؤسسة نايت الخيرية إلى أفريقيا لتقديم الرعاية والمساعدة للأطفال الفقراء وضمان تلبية الاحتياجات المعيشية الأساسية للأطفال. يمكن لطريقة التمويل من نظير إلى نظير أن تحسن حياة الأطفال بشكل مباشر وتمنحهم الأمل والتشجيع.',
    },
    item3: {
        // title: "During the 2019 Australian forest fires, Consortium Capital donated US$1.2 million for relief supplies and recovery."
        title: 'وفي فبراير 2023، تعرضت تركيا لزلزال بقوة 7.8 درجة، وتعرضت العديد من المناطق لأضرار وخسائر جسيمة. وأرسلت مؤسسة الفارس فرق إنقاذ لإنقاذ الضحايا، ومنحهم الدفء والأمل، وجعلهم يشعرون بدفء ورعاية الأسرة الاجتماعية.',
    },
    item4: {
      title: 'ولم يتلق المدنيون أي حماية خلال الحرب الإسرائيلية الفلسطينية. ضمن اتحاد الفرسان حصول الناس على المساعدة الأساسية وقام بنشاط بتوفير عمال الإغاثة المهمين.',
    },
    item5: {
      title: 'وفي الحرب الفلسطينية الإسرائيلية، يعد فتح خطوط الإمداد والمساعدات الإنسانية من الاحتياجات الحرجة والعاجلة. يغتنم اتحاد الفرسان فترة النافذة لتوصيل إمدادات المساعدات التي تعتبر ضرورية لرفاهية سكان غزة، وينسق مع المنظمات الإنسانية الدولية لضمان وصول إمدادات المساعدات.',
    },
    item6: {
      title: 'ومع استمرار الحرب الإسرائيلية الفلسطينية، يستمر عدد الضحايا في التزايد. أصبح إنقاذ الضحايا وشيكًا، وينظم Knight Consortium بنشاط فرق الإنقاذ لتوفير الإنقاذ الفوري.',
    },
    item7: {
        title: 'لقد كانت مجموعة Knight Consortium معروفة دائمًا بالمساعدة المهنية التي تقدمها. لدينا فريق من ذوي الخبرة يمكنه الاستجابة بسرعة وتقديم حلول عملية. نعمل جميعًا بطريقة تتسم بالكفاءة والشفافية لضمان استخدام كل جزء من المساعدة بشكل مناسب وفعال.',
    },
    item8: {
        title: 'إن اختيار المساعدة المهنية من Knight Consortium يعني أنك اخترت شريكًا موثوقًا به، فريقًا مخصصًا لتحسين الحياة وإعادة بناء الأمل. ولن ندخر أي جهد لتزويد الضحايا بالدعم الذي يحتاجونه لمساعدتهم على التغلب على الصعوبات والتحرك نحو مستقبل أفضل.',
    },
    item9: {
        title: 'وفي خضم ضباب الحرب، تشكل إعادة الإعمار بعد الحرب خطوة حاسمة يجب أن نتخذها. إن إعادة بناء المجتمعات، وإصلاح المباني المتضررة، وإعادة دمج الضحايا في المجتمع تتطلب مساعدة مهنية ودعمًا ملتزمًا.',
    },
    item10: {
        title: 'وهبت مؤسسة الفرسان على الفور للإنقاذ وقدمت بصيصًا من المساعدة لضحايا الحرب الفلسطينية الإسرائيلية. في مواجهة تحديات الحرب الإسرائيلية الفلسطينية، تقف مؤسسة فرساننا بثبات إلى جانب الضحايا. كفريق ملتزم بالعدالة المالية والمسؤولية الاجتماعية، فقد اعتبرنا دائمًا أن الرعاية والمساعدة هي إحدى مسؤولياتنا.',
    },
    item11: {
        title: 'وفي الحرب الفلسطينية الإسرائيلية، ندرك جيدا التحديات والصعوبات التي يواجهها الضحايا. لقد اتخذنا إجراءات طارئة لتقديم المساعدة المادية المطلوبة بشكل عاجل إلى المناطق المتضررة. وتشمل هذه الإمدادات الغذاء والماء والإمدادات الطبية ومعدات الإنقاذ في حالات الطوارئ، وهي مصممة لتقديم المساعدة والدعم للمتضررين من الكارثة.',
    },
    item12: {
        title: 'لقد التزمت مؤسسة الفرسان منذ فترة طويلة بدعم التنشيط بعد الحرب. إن إعادة بناء المجتمعات ومساعدة المتضررين أمر بالغ الأهمية. ولذلك، فإننا لا نتردد في التواصل لضمان أن يتمكن المتضررون من الحرب من إعادة بناء حياتهم.',
    },
    item13: {
      title: 'وفي خضم الحرب، تكاتفنا لتقديم المساعدة للضحايا. لقد أيدت مؤسسة الفرسان دائمًا الشعور بالمسؤولية الاجتماعية وهي ملتزمة بمساعدة المتضررين من الحرب. دعونا نضيء حياة ضحايا الحرب بنور الأمل!',
    },
    item14: {
      title: 'في اللحظات الصعبة بعد الحرب، فإن اتحاد الفرسان على استعداد لأن يكون شريكك الموثوق به وأن يتكاتف في مهمة الإنقاذ وإعادة الإعمار. ونحن نعلم أن كل ضحية من ضحايا الكوارث يحتاج إلى الرعاية والمساعدة، لذلك نشارك بفعالية في عمليات الإغاثة المختلفة لتقديم الدعم اللازم للمتضررين.',
    },
    item15: {
      title: 'لقد التزمت مؤسسة الفرسان بنشر هذه الروح. ومن خلال الأعمال الخيرية ومشاريع المساعدات والعمل التطوعي، نسعى جاهدين لتحسين الحياة ودعم المجتمعات وجعل العالم مكانًا أكثر دفئًا. ولن نتمكن من خلق مستقبل أفضل وأكثر أملا إلا بالعمل معا.',
    },
    item16: {
        title: 'تعرضت الأرجنتين لعاصفة قوية للغاية، مما أدى إلى مقتل 16 شخصًا بريئًا. شعر اتحاد الفرسان بحزن عميق واستجاب على الفور، وشارك فريقنا المحترف على الفور في أعمال الإغاثة من الكارثة. خلال حالة الطوارئ هذه، نبقى ملتزمين بدعم المناطق المتضررة.',
    },
    item17: {
        title: 'علمنا بوقوع زلزال بقوة 6.2 درجة على مقياس ريختر في مدينة قانسو بالصين، وقد تسببت هذه الكارثة في معاناة كبيرة للسكان المحليين. في هذا الوقت العصيب، تقف مؤسسة الفرسان إلى جانب الناس في منطقة الكارثة وتتقدم بأحر التعازي والدعم.',
    },
    item18: {
        title: 'هناك لحظات في رحلة الحياة تتطلب المزيد من الرعاية والدعم. تأخذ مؤسسة نايت المسؤولية الاجتماعية على أنها مسؤوليتها الخاصة، ونحن نؤمن إيمانًا راسخًا بأن مساعدة المحتاجين جزء مهم من بناء مجتمع أفضل، وسنكون دائمًا بجانبك.',
    },
    item19: {
        title: 'اتحاد الفرسان على وشك الانطلاق مرة أخرى! وبالنظر إلى الحليب والزيت والمعكرونة والبسكويت وغيرها من المواد الغذائية المرتبة بعناية على الأرض، كانوا على وشك تسليمها إلى عائلة محلية محتاجة. عند رؤية وجوههم السعيدة، نعرف مقدار الدفء والأمل الذي ستجلبه هذه الإمدادات. الحب والدفء، تفسر مؤسسة نايت المسؤولية الاجتماعية بالأفعال، دعونا نتطلع إلى اللمسة التي يجلبها هذا التسليم.',
    },
    item20: {
        title: 'لقد شهدنا مؤخرًا فريقًا كبيرًا من أعضاء اتحاد الفرسان، المليئين بالطاقة والعاطفة، وهم يحملون كميات كبيرة من الطعام إلى المجتمعات المحلية لزيارة النساء وكبار السن والأطفال. يتمتع كل عضو في هذا الفريق بحماس ورعاية لا حدود لهما، كما أن وجودهم يضخ تيارًا دافئًا في المجتمع. وشاهدنا خلال الفعالية شباب وشابات يتفاعلون بكل ود مع كبار السن والأطفال، وكانت ابتساماتهم مليئة بالسعادة والملامسة. سواء كان ذلك بتوزيع الطعام أو الدردشة، الجميع يشاركون ويجلبون الدفء والرعاية للآخرين بكل قلوبهم. يُظهر هذا الفريق الديناميكي التابع لمجموعة Knight Financial Group روح الوحدة والصداقة ويضخ حبًا قويًا في المجتمع.',
    },
    item21: {
        title: 'في الآونة الأخيرة، قامت مجموعة من أعضاء اتحاد الفرسان بزيارة مؤثرة. ذهبوا إلى المنطقة المحلية وقاموا بزيارة النساء وكبار السن والأطفال، حيث جلبوا لهم الدفء والرعاية. هذا الحدث ليس مجرد زيارة بسيطة، ولكنه أيضًا انعكاس للرعاية والدعم الصادقين للمجتمع. خلال هذا الحدث، أجرى أعضاء مؤسسة الفرسان محادثات ودية مع الأشخاص الذين تمت مقابلتهم وتبادلوا قصص وتجارب بعضهم البعض. كان كبار السن يبتسمون، والأطفال يضحكون، والنساء يشعرن بالامتنان. لقد استمعوا باهتمام مصحوبًا بالحب، مما جعل كل من تمت مقابلتهم يشعر بالدفء والرعاية من المجتمع.',
    },
    item22: {
        title: 'في يوم مشمس، قام فريق سان خوان التابع لاتحاد الفرسان بزيارة العائلات المحلية المحتاجة بعناية كاملة. قام أعضاء الفريق بتوزيع الضروريات اليومية مثل الأرز وزيت الطهي والمعكرونة المجوفة والمعلبات والخبز والبسكويت، وقدموا التعازي الودية والمحادثات مع كل عائلة. إن التوزيع المادي هذه المرة ليس فقط دعمًا عمليًا للاحتياجات اليومية، بل أيضًا راحة روحية لكل عائلة. يشارك متطوعو Knight اللحظات الدافئة مع العائلات التي يتم مساعدتها، وينقلون الرعاية والأمل لبعضهم البعض. وأعربت الأسر التي تمت مساعدتها عن امتنانها لمتطوعي الفارس، وهذا الدفء جعلهم ممتلئين بالثقة والتوقعات للمستقبل. وعندما غادر المتطوعون، أرسلت العائلات بركاتها الصادقة وشكرت فريق سان خوان التابع لمؤسسة الفرسان على الرعاية والدفء الذي قدموه لهم.',
    },
    item23: {
        title: 'مد أعضاء مؤسسة الفرسان يد العون للعديد من العائلات الفقيرة المحلية وأرسلوا لهم الضروريات اليومية الوفيرة! في حدث سابق، ذهب متطوعون متحمسون من مؤسسة الفرسان إلى المنطقة المحلية حاملين إمدادات وفيرة مثل زيت الطهي والمعكرونة والقمح والدقيق، وقاموا بتسليم هذه الضروريات اليومية للعديد من الأسر الفقيرة. وهذا ليس مجرد تبرع بسيط، ولكنه أيضًا تعازي وتواصل صادق. وأثناء تلقيها هذا الدفء، أعربت الأسر التي تلقت المساعدة عن امتنانها العميق لمتطوعي نايت. ويبدو أن صعوبات الحياة تصبح أكثر دفئا ومليئة بالأمل والتوقع بسبب هذه الرعاية.',
    },
    item24: {
        title: 'انطلق متطوعو مؤسسة الفارس في رحلة خيرية مؤثرة مع الضروريات اليومية مثل الخبز والزيت والحليب والسكر وغيرها. لقد توغلوا في المناطق الجبلية وقدموا مساعدة ملموسة لتلك العائلات المحتاجة التي تعيش على سفوح التلال وكانت وسائل النقل محدودة. ويغطي توزيع المواد جميع جوانب الحياة اليومية للعائلة، بما في ذلك زيت الطهي والدقيق والحليب والسكر وما إلى ذلك، مما يضمن أن تشعر كل أسرة بالرعاية الدافئة لمؤسسة الفرسان. المتطوعون ليسوا مجرد حاملين للإمدادات، بل هم أيضًا ناقلون للرفقة والتعازي والتواصل. وتتواجد الأسر التي تتلقى المساعدة في مناطق جبلية نائية وغالباً ما تواجه تحديات مثل وسائل النقل غير الملائمة والظروف المعيشية الصعبة. لذلك، كان وصول متطوعي الفرسان بمثابة نعمة لهم وملأهم بالامتنان. شارك الجميع قصص بعضهم البعض أثناء المحادثة، مما جعل هذا الحدث الخيري أكثر دفئًا وذات مغزى.',
    },
    item25: {
      title: 'في الآونة الأخيرة، كانت الشمس مشرقة، وجاء أعضاء Knight Consortium إلى المنطقة المحلية ومعهم دلاء من زيت الطهي والعصير لتقديم يد العون وإرسال الدفء والرعاية لأولئك الذين يحتاجون إلى الدعم والمساعدة. في هذا اليوم الجميل، نحن لسنا فقط أعضاء في اتحاد الفرسان، ولكن أيضًا جزء من المجتمع. نحن نعلم أنه كفريق، مسؤولياتنا ومهامنا تقع على عاتقنا. ولذلك، نحن على استعداد للعمل الجاد واستخدام الإجراءات لإظهار رعايتنا ودعمنا للمجتمع. دعونا ننشر حبنا معًا ونملأ العالم بالدفء والأمل. شكرًا لدعمكم، دعونا نستمر في المضي قدمًا معًا والمساهمة في تنمية وتقدم المجتمع!',
    },
    item26: {
      title: 'مؤخرًا، جاء فريقنا من Knight Consortium إلى قرطبة، الأرجنتين، هذا المكان الجميل مليء بالدفء والأمل. يجتمع هنا فريقنا الذي يضم ما يقرب من 30 عضوًا بقلب دافئ وبحبكم ودعمكم، وسنساعد المزيد من الأشخاص المحتاجين ونقدم لهم الدفء والرعاية. في هذا العمل المليء بالحب، نحن لسنا فقط أعضاء في اتحاد الفرسان، ولكننا أيضًا المتحدث باسمكم ورسولكم. بفضل كرمكم ومحبتكم، أصبحنا قادرين على الوصول إلى هنا وتقديم الامتنان لعدد أكبر من الأشخاص. دعونا نتكاتف للمساهمة في المجتمع وملء العالم بمزيد من الدفء والأمل. شكرا لدعمكم، دعونا نستمر في نشر قوة الحب!',
    }
  }
}

export const team = {
    leader1: {
        title: "رئيس",
        name: "Zhang Guofu",
        desc: "درجة الماجستير في الاقتصاد المالي من جامعة أكسفورد بالمملكة المتحدة"
    },
    leader2: {
        title: "نائب رئيس مجلس الإدارة والمدير القانوني",
        item1: {
            name: "Remus John Worm",
            desc: "ماجستير في الاقتصاد، كلية الاقتصاد بجامعة شيكاغو"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "ماجستير العلوم وماجستير إدارة الأعمال، جامعة أوتاوا"
        },
        item3: {
            name: "Liu Caijun",
            desc: "تخرج من جامعة بكين، ماجستير في الإدارة"
        }
    },
    leader3: {
        title: "محلل مالي",
        item1: {
            name: "Maxwell",
            desc: "بكالوريوس في التمويل الدولي، جامعة فودان"
        },
        item2: {
            name: "Yang Jun",
            desc: "تخرج من جامعة نانيانغ التكنولوجية، سنغافورة، تخصص في المالية"
        },
        item3: {
            name: "Hubert",
            desc: "شهادة مزدوجة من جامعة لندن، المملكة المتحدة، وجامعة هارفارد، الولايات المتحدة الأمريكية"
        }
    },
    leader4: {
        title: "مستشار",
        item1: {
            name: "Анфия",
            desc: "درجة البكالوريوس، جامعة فلوريدا"
        },
        item2: {
            name: "Xu Hao",
            desc: "شهادة الهندسة من جامعة جورج واشنطن"
        },
        item3: {
            name: "Dorothy",
            desc: "ماجستير في إدارة الأعمال من المعهد الهندي للتكنولوجيا"
        }
    },
    leader5: {
        title: "عضو أساسي",
        item1: {
            name: "sun horse",
            desc: "يدرس الاقتصاد في جامعة رويال هولواي، جامعة لندن، المملكة المتحدة"
        },
        item2: {
            name: "Yvonne",
            desc: "درجة الماجستير من جامعة بروكسل الحرة"
        },
        item3: {
            name: "Yan Tao",
            desc: "درجة الماجستير من جامعة واشنطن (درجة الإدارة الهندسية)"
        }
    }
}

export const casepage = {
    title: "مقدمة في الرعاية الاجتماعية",
    item1: {
        title: "بعد الزلزال الذي ضرب تركيا بقوة 7.8 درجة في فبراير 2023، تبرعت KNight Consortium بمبلغ 2.3 مليون دولار أمريكي للاجئين"
    },
    item2: {
        title: "في يوليو 2021، تسببت الأمطار الغزيرة في حدوث فيضانات في مدينة تشنغتشو بمقاطعة خنان في الصين. تبرع اتحاد KNight بمبلغ 1.8 مليون دولار أمريكي."
    },
    item3: {
        title: "خلال حرائق الغابات الأسترالية عام 2019، تبرعت كونسورتيوم كابيتال بمبلغ 1.2 مليون دولار أمريكي لإمدادات الإغاثة والتعافي."
    },
    item4: {
        title: "وفي فبراير 2023، ضرب زلزال بقوة 7.8 درجة تركيا، مما تسبب في مقتل أكثر من 41 ألف شخص. وأصبح 1.5 مليون شخص بلا مأوى في المنطقة الجنوبية، وكان هناك حاجة إلى بناء ما لا يقل عن 500 ألف منزل جديد. وفي شمال غرب سوريا، تأثر ما يصل إلى 9 ملايين شخص وفقد ما لا يقل عن 6000 شخص حياتهم. تبرعت كونسورتيوم كابيتال بمبلغ 2.3 مليون دولار أمريكي للاجئين لتوفير المواد المعيشية وإعادة بناء منازلهم.",
    },
    item5: {
        title: "وفي يونيو/حزيران 2022، أدى الزلزال الذي ضرب أفغانستان إلى مقتل أكثر من 1000 شخص وإصابة أكثر من 2000 شخص. تبرع رأس مال الكونسورتيوم بمليون دولار أمريكي لإعادة بناء المساكن بعد الكارثة، وتم استخدام جزء منه لتلبية الاحتياجات اليومية.",
    },
    item6: {
        title: "إن التبرع بالمواد هو مساعدتنا المباشرة والعملية للضحايا. وفي فبراير 2023، تعرضت تركيا لزلزال بقوة 7.8 درجة، وتبرعت مؤسسة نايتس بمبلغ 2.3 مليون دولار أمريكي للاجئين لإعادة بناء منازلهم.",
    },
    item7: {
        title: "وفي فبراير 2023، تعرضت تركيا لزلزال بقوة 7.8 درجة، وتعرضت العديد من المناطق لأضرار وخسائر جسيمة. وأرسلت مؤسسة الفارس فرق إنقاذ لإنقاذ الضحايا، ومنحهم الدفء والأمل، وجعلهم يشعرون بدفء ورعاية الأسرة الاجتماعية.",
    },
    item8: {
        title: "وأدى الزلزال الذي ضرب أفغانستان في يونيو 2022 إلى مقتل أكثر من 1000 شخص وإصابة أكثر من 2000 شخص. تبرع رأس مال الكونسورتيوم بمليون دولار أمريكي لإعادة بناء المساكن بعد الكارثة، وتم استخدام جزء منه لتغطية نفقات المعيشة.",
    },
    item9: {
        title: "تشارك مؤسسة الفرسان بنشاط في الأعمال الخيرية التي تهتم بالأطفال الفقراء. يتم تقديم المساعدة المالية والرعاية المنتظمة من خلال التبرعات والخدمات التطوعية. هذا النوع من الرعاية والمساعدة لا يغير مصير الأطفال فحسب، بل يضخ أيضًا المزيد من الطاقة الإيجابية في تنمية المجتمع بأكمله.",
    },
    item10: {
        title: "في سبتمبر 2022، ذهب فريق مؤسسة نايت الخيرية إلى أفريقيا لتقديم الرعاية والمساعدة للأطفال الفقراء والتأكد من تلبية الاحتياجات المعيشية الأساسية للأطفال. يمكن لطريقة التمويل من نظير إلى نظير أن تحسن حياة الأطفال بشكل مباشر وتمنحهم الأمل والتشجيع.",
    },
    item11: {
        title: "ولم يتلق المدنيون أي حماية خلال الحرب الإسرائيلية الفلسطينية. ضمن اتحاد الفرسان حصول الناس على المساعدة الأساسية وقام بنشاط بتوفير عمال الإغاثة المهمين.",
    },
    item12: {
        title: "وفي الحرب الفلسطينية الإسرائيلية، يعد فتح خطوط الإمداد والمساعدات الإنسانية من الاحتياجات الحرجة والعاجلة. يغتنم اتحاد الفرسان فترة النافذة لتوصيل إمدادات المساعدات التي تعتبر ضرورية لرفاهية سكان غزة، وينسق مع المنظمات الإنسانية الدولية لضمان وصول إمدادات المساعدات.",
    },
    item13: {
        title: "ومع استمرار الحرب الإسرائيلية الفلسطينية، يستمر عدد الضحايا في التزايد. أصبح إنقاذ الضحايا وشيكًا، وينظم Knight Consortium بنشاط فرق الإنقاذ لتوفير الإنقاذ الفوري.",
    },
    item14: {
        title: "في يوليو 2023، تأثرت بإعصار رقم 5 \"دوسوري'، وهطلت أمطار غزيرة للغاية في بكين وتيانجين وهيبي وأماكن أخرى في الصين، مما تسبب في كوارث فيضانات. أطلق اتحاد الفرسان عملية إنقاذ طارئة وقدم قنوات إنقاذ في الوقت المناسب لمساعدة كبار السن والأشخاص الذين يعانون من محنة على الخروج من المشاكل.",
    },
    item15: {
        title: 'وهبت مؤسسة الفرسان على الفور للإنقاذ وقدمت بصيصًا من المساعدة لضحايا الحرب الفلسطينية الإسرائيلية. في مواجهة تحديات الحرب الإسرائيلية الفلسطينية، تقف مؤسسة فرساننا بثبات إلى جانب الضحايا. كفريق ملتزم بالعدالة المالية والمسؤولية الاجتماعية، فقد اعتبرنا دائمًا أن الرعاية والمساعدة هي إحدى مسؤولياتنا.',
    },
    item16: {
        title: 'وفي الحرب الفلسطينية الإسرائيلية، ندرك جيدا التحديات والصعوبات التي يواجهها الضحايا. لقد اتخذنا إجراءات طارئة لتقديم المساعدة المادية المطلوبة بشكل عاجل إلى المناطق المتضررة. وتشمل هذه الإمدادات الغذاء والماء والإمدادات الطبية ومعدات الإنقاذ في حالات الطوارئ، وهي مصممة لتقديم المساعدة والدعم للمتضررين من الكارثة.',
    },
    item17: {
        title: 'لقد التزمت مؤسسة الفرسان منذ فترة طويلة بدعم التنشيط بعد الحرب. إن إعادة بناء المجتمعات ومساعدة المتضررين أمر بالغ الأهمية. ولذلك، فإننا لا نتردد في التواصل لضمان أن يتمكن المتضررون من الحرب من إعادة بناء حياتهم.',
    },
    item18: {
        title: 'لقد كانت مجموعة Knight Consortium معروفة دائمًا بالمساعدة المهنية التي تقدمها. لدينا فريق من ذوي الخبرة يمكنه الاستجابة بسرعة وتقديم حلول عملية. نعمل جميعًا بطريقة تتسم بالكفاءة والشفافية لضمان استخدام كل جزء من المساعدة بشكل مناسب وفعال.',
    },
    item19: {
        title: 'إن اختيار المساعدة المهنية من Knight Consortium يعني أنك اخترت شريكًا موثوقًا به، فريقًا مخصصًا لتحسين الحياة وإعادة بناء الأمل. ولن ندخر أي جهد لتزويد الضحايا بالدعم الذي يحتاجونه لمساعدتهم على التغلب على الصعوبات والتحرك نحو مستقبل أفضل.',
    },
    item20: {
        title: 'وفي خضم ضباب الحرب، تشكل إعادة الإعمار بعد الحرب خطوة حاسمة يجب أن نتخذها. إن إعادة بناء المجتمعات، وإصلاح المباني المتضررة، وإعادة دمج الضحايا في المجتمع تتطلب مساعدة مهنية ودعمًا ملتزمًا.',
    },
    item21: {
      title: 'وفي خضم الحرب، تكاتفنا لتقديم المساعدة للضحايا. لقد أيدت مؤسسة الفرسان دائمًا الشعور بالمسؤولية الاجتماعية وهي ملتزمة بمساعدة المتضررين من الحرب. دعونا نضيء حياة ضحايا الحرب بنور الأمل!',
    },
    item22: {
      title: 'في اللحظات الصعبة بعد الحرب، فإن اتحاد الفرسان على استعداد لأن يكون شريكك الموثوق به وأن يتكاتف في مهمة الإنقاذ وإعادة الإعمار. ونحن نعلم أن كل ضحية من ضحايا الكوارث يحتاج إلى الرعاية والمساعدة، لذلك نشارك بفعالية في عمليات الإغاثة المختلفة لتقديم الدعم اللازم للمتضررين.',
    },
    item23: {
      title: 'لقد التزمت مؤسسة الفرسان بنشر هذه الروح. ومن خلال الأعمال الخيرية ومشاريع المساعدات والعمل التطوعي، نسعى جاهدين لتحسين الحياة ودعم المجتمعات وجعل العالم مكانًا أكثر دفئًا. ولن نتمكن من خلق مستقبل أفضل وأكثر أملا إلا بالعمل معا.',
    },
    item24: {
        title: 'تعرضت الأرجنتين لعاصفة قوية للغاية، مما أدى إلى مقتل 16 شخصًا بريئًا. شعر اتحاد الفرسان بحزن عميق واستجاب على الفور، وشارك فريقنا المحترف على الفور في أعمال الإغاثة من الكارثة. خلال حالة الطوارئ هذه، نبقى ملتزمين بدعم المناطق المتضررة.',
    },
    item25: {
        title: 'علمنا بوقوع زلزال بقوة 6.2 درجة على مقياس ريختر في مدينة قانسو بالصين، وقد تسببت هذه الكارثة في معاناة كبيرة للسكان المحليين. في هذا الوقت العصيب، تقف مؤسسة الفرسان إلى جانب الناس في منطقة الكارثة وتتقدم بأحر التعازي والدعم.',
    },
    item26: {
        title: 'هناك لحظات في رحلة الحياة تتطلب المزيد من الرعاية والدعم. تأخذ مؤسسة نايت المسؤولية الاجتماعية على أنها مسؤوليتها الخاصة، ونحن نؤمن إيمانًا راسخًا بأن مساعدة المحتاجين جزء مهم من بناء مجتمع أفضل، وسنكون دائمًا بجانبك.',
    },
    item27: {
        title: 'اتحاد الفرسان على وشك الانطلاق مرة أخرى! وبالنظر إلى الحليب والزيت والمعكرونة والبسكويت وغيرها من المواد الغذائية المرتبة بعناية على الأرض، كانوا على وشك تسليمها إلى عائلة محلية محتاجة. عند رؤية وجوههم السعيدة، نعرف مقدار الدفء والأمل الذي ستجلبه هذه الإمدادات. الحب والدفء، تفسر مؤسسة نايت المسؤولية الاجتماعية بالأفعال، دعونا نتطلع إلى اللمسة التي يجلبها هذا التسليم.',
    },
    item28: {
        title: 'لقد شهدنا مؤخرًا فريقًا كبيرًا من أعضاء اتحاد الفرسان، المليئين بالطاقة والعاطفة، وهم يحملون كميات كبيرة من الطعام إلى المجتمعات المحلية لزيارة النساء وكبار السن والأطفال. يتمتع كل عضو في هذا الفريق بحماس ورعاية لا حدود لهما، كما أن وجودهم يضخ تيارًا دافئًا في المجتمع. وشاهدنا خلال الفعالية شباب وشابات يتفاعلون بكل ود مع كبار السن والأطفال، وكانت ابتساماتهم مليئة بالسعادة والملامسة. سواء كان ذلك بتوزيع الطعام أو الدردشة، الجميع يشاركون ويجلبون الدفء والرعاية للآخرين بكل قلوبهم. يُظهر هذا الفريق الديناميكي التابع لمجموعة Knight Financial Group روح الوحدة والصداقة ويضخ حبًا قويًا في المجتمع.',
    },
    item29: {
        title: 'في الآونة الأخيرة، قامت مجموعة من أعضاء اتحاد الفرسان بزيارة مؤثرة. ذهبوا إلى المنطقة المحلية وقاموا بزيارة النساء وكبار السن والأطفال، حيث جلبوا لهم الدفء والرعاية. هذا الحدث ليس مجرد زيارة بسيطة، ولكنه أيضًا انعكاس للرعاية والدعم الصادقين للمجتمع. خلال هذا الحدث، أجرى أعضاء مؤسسة الفرسان محادثات ودية مع الأشخاص الذين تمت مقابلتهم وتبادلوا قصص وتجارب بعضهم البعض. كان كبار السن يبتسمون، والأطفال يضحكون، والنساء يشعرن بالامتنان. لقد استمعوا باهتمام مصحوبًا بالحب، مما جعل كل من تمت مقابلتهم يشعر بالدفء والرعاية من المجتمع.',
    },
    item30: {
        title: 'في يوم مشمس، قام فريق سان خوان التابع لاتحاد الفرسان بزيارة العائلات المحلية المحتاجة بعناية كاملة. قام أعضاء الفريق بتوزيع الضروريات اليومية مثل الأرز وزيت الطهي والمعكرونة المجوفة والمعلبات والخبز والبسكويت، وقدموا التعازي الودية والمحادثات مع كل عائلة. إن التوزيع المادي هذه المرة ليس فقط دعمًا عمليًا للاحتياجات اليومية، بل أيضًا راحة روحية لكل عائلة. يشارك متطوعو Knight اللحظات الدافئة مع العائلات التي يتم مساعدتها، وينقلون الرعاية والأمل لبعضهم البعض. وأعربت الأسر التي تمت مساعدتها عن امتنانها لمتطوعي الفارس، وهذا الدفء جعلهم ممتلئين بالثقة والتوقعات للمستقبل. وعندما غادر المتطوعون، أرسلت العائلات بركاتها الصادقة وشكرت فريق سان خوان التابع لمؤسسة الفرسان على الرعاية والدفء الذي قدموه لهم.',
    },
    item31: {
        title: 'مد أعضاء مؤسسة الفرسان يد العون للعديد من العائلات الفقيرة المحلية وأرسلوا لهم الضروريات اليومية الوفيرة! في حدث سابق، ذهب متطوعون متحمسون من مؤسسة الفرسان إلى المنطقة المحلية حاملين إمدادات وفيرة مثل زيت الطهي والمعكرونة والقمح والدقيق، وقاموا بتسليم هذه الضروريات اليومية للعديد من الأسر الفقيرة. وهذا ليس مجرد تبرع بسيط، ولكنه أيضًا تعازي وتواصل صادق. وأثناء تلقيها هذا الدفء، أعربت الأسر التي تلقت المساعدة عن امتنانها العميق لمتطوعي نايت. ويبدو أن صعوبات الحياة تصبح أكثر دفئا ومليئة بالأمل والتوقع بسبب هذه الرعاية.',
    },
    item32: {
        title: 'انطلق متطوعو مؤسسة الفارس في رحلة خيرية مؤثرة مع الضروريات اليومية مثل الخبز والزيت والحليب والسكر وغيرها. لقد توغلوا في المناطق الجبلية وقدموا مساعدة ملموسة لتلك العائلات المحتاجة التي تعيش على سفوح التلال وكانت وسائل النقل محدودة. ويغطي توزيع المواد جميع جوانب الحياة اليومية للعائلة، بما في ذلك زيت الطهي والدقيق والحليب والسكر وما إلى ذلك، مما يضمن أن تشعر كل أسرة بالرعاية الدافئة لمؤسسة الفرسان. المتطوعون ليسوا مجرد حاملين للإمدادات، بل هم أيضًا ناقلون للرفقة والتعازي والتواصل. وتتواجد الأسر التي تتلقى المساعدة في مناطق جبلية نائية وغالباً ما تواجه تحديات مثل وسائل النقل غير الملائمة والظروف المعيشية الصعبة. لذلك، كان وصول متطوعي الفرسان بمثابة نعمة لهم وملأهم بالامتنان. شارك الجميع قصص بعضهم البعض أثناء المحادثة، مما جعل هذا الحدث الخيري أكثر دفئًا وذات مغزى.',
    },
    item33: {
      title: 'في الآونة الأخيرة، كانت الشمس مشرقة، وجاء أعضاء Knight Consortium إلى المنطقة المحلية ومعهم دلاء من زيت الطهي والعصير لتقديم يد العون وإرسال الدفء والرعاية لأولئك الذين يحتاجون إلى الدعم والمساعدة. في هذا اليوم الجميل، نحن لسنا فقط أعضاء في اتحاد الفرسان، ولكن أيضًا جزء من المجتمع. نحن نعلم أنه كفريق، مسؤولياتنا ومهامنا تقع على عاتقنا. ولذلك، نحن على استعداد للعمل الجاد واستخدام الإجراءات لإظهار رعايتنا ودعمنا للمجتمع. دعونا ننشر حبنا معًا ونملأ العالم بالدفء والأمل. شكرًا لدعمكم، دعونا نستمر في المضي قدمًا معًا والمساهمة في تنمية وتقدم المجتمع!',
    },
    item34: {
      title: 'مؤخرًا، جاء فريقنا من Knight Consortium إلى قرطبة، الأرجنتين، هذا المكان الجميل مليء بالدفء والأمل. يجتمع هنا فريقنا الذي يضم ما يقرب من 30 عضوًا بقلب دافئ وبحبكم ودعمكم، وسنساعد المزيد من الأشخاص المحتاجين ونقدم لهم الدفء والرعاية. في هذا العمل المليء بالحب، نحن لسنا فقط أعضاء في اتحاد الفرسان، ولكننا أيضًا المتحدث باسمكم ورسولكم. بفضل كرمكم ومحبتكم، أصبحنا قادرين على الوصول إلى هنا وتقديم الامتنان لعدد أكبر من الأشخاص. دعونا نتكاتف للمساهمة في المجتمع وملء العالم بمزيد من الدفء والأمل. شكرا لدعمكم، دعونا نستمر في نشر قوة الحب!',
    },
    item35: {
      title: 'اجتمع أعضاء اتحاد الفرسان في وضع عدم الاتصال لمشاركة احتفال نهائيات كأس أمريكا وكأس أوروبا! لم نستمتع فقط بالطعام اللذيذ على الطاولة، بل شاركنا أيضًا حماسنا وتوقعاتنا للمباراة وسط الضحك والضحك. بغض النظر عن الفريق الذي نشجعه، في هذه اللحظة، نحن جميعًا مشجعون مخلصون لكرة القدم، نهتف للبطل في قلوبنا. إن نهائيات كأس أمريكا وكأس أوروبا ليست مجرد احتفال بكرة القدم، ولكنها أيضًا شهادة على وحدة وصداقة أعضاء اتحاد الفرسان.',
    },
    item36: {
      title: 'إننا نجتمع في هذه اللحظة الخاصة ليس فقط للاحتفال بسحر كرة القدم، ولكن أيضًا لتعزيز الروابط والتعاون المتبادلين. كل مباراة هي جهد جماعي، وكل هدف هو فرحتنا الجماعية! شكرا لجميع أعضاء اتحاد الفرسان الذين شاركوا في هذا العشاء. أضاف حماسك وطاقتك إثارة لا نهاية لها لهذا المساء. دعونا نواصل المضي قدمًا جنبًا إلى جنب ونرحب بمزيد من الانتصارات والمجد معًا!',
    },
}

export const industry = {
    title: "صناعة الاستثمار",
    cat1: {
        name: "finance",
        item1: {
            title: "قام فريق KNight Consortium الاستثماري بشراء أسهم في عدد من شركات تعدين المعادن الثمينة. كان أداء هذه الشركات جيدًا خلال عام 2012 حيث أدى ارتفاع أسعار المعادن الثمينة إلى ارتفاع أسعار أسهمها، مما أدى إلى تحقيق مكاسب ضخمة لنقابة الفرسان.",
        },
        item2: {
            title: "خلال الفترة من يناير إلى فبراير 2012، تقلبت أسعار الذهب العالمية. انتهز المحللون الماليون من KNight Consortium الفرصة للشراء بأسعار منخفضة وحققوا أرباحًا تقارب 60٪ في ثلاثة أشهر فقط.",
        }
    },
    cat2: {
        name: "medical",
        item1: {
            title: "في عام 2020، تم إنشاء مركز الخدمة الطبية KNight Consortium، ونجحت طريقة علاج \"العلاج المناعي للورم\" التي تم تطويرها بالاشتراك مع مجموعة Merck Pharmaceutical Group.",
        },
        item2: {
            title: "قام مركز الخدمات الطبية KNight Consortium وشركة Novartis Pharmaceuticals بتطوير عقار Houng-592 الذي يستهدف السرطان.",
        }
    },
    cat3: {
        name: "metaverse",
        item1: {
            title: "في عام 2021، تم إنشاء مركز KNight Consortium web3.0 لإنشاء منصة اجتماعية لامركزية، والتي جذبت اهتمامًا واسع النطاق ولاقت استحسان المستخدمين.",
        },
        item2: {
            title: "في السوق الصاعدة للعملات المشفرة في يناير 2017، اغتنم اتحاد KNight الفرصة وقاد أعضاء المؤسسة إلى تحقيق أرباح عالية، وسرعان ما تضاعفت أصول جميع الأعضاء.",
        }
    }
}


export const about = {
    title: "يوحد KNight Consortium قوة مستثمري التجزئة لتحقيق حلم الحرية المالية.",
    desc: "السادة يحبون الثروة، بطريقة مناسبة. إن KNight Consortium على استعداد لتوجيه هذا الطريق الواسع لمزيد من الأشخاص لاكتشافه، حتى يتمكن الجميع من تحقيق الرخاء والازدهار الأولي، ومن ثم تحقيق تحقيق الذات! مساعدة الاستثمارات الفردية على الهروب من مصير الحصاد من قبل المؤسسات وتحقيق الأرباح من خلال عمليات رأسمالية احترافية وموثوقة، وبالتالي مساعدة أعضاء الفريق على تحقيق أحلامهم في الحرية المالية.",
    service: {
        title: "أهدافنا من رأس المال النقابي",
        item1: {
            title: "",
            desc: "بذل كل جهد ممكن لتعزيز التنمية الاقتصادية والاجتماعية العالمية والعلاقات الاقتصادية.<br><br><br><br><br>"
        },
        item2: {
            title: "",
            desc: "تعزيز مواصلة تطوير الأعمال الخيرية لتحسين معيشة الناس، وتقديم المساعدة الوطنية للاجئين الدوليين الذين يعانون من الكوارث الطبيعية، واستخدام الإجراءات العملية لمساعدة الفئات المحرومة على الخروج من المشاكل والسماح لهم بالعيش حياة سعيدة وسلمية."
        },
        item3: {
            title: "",
            desc: "إنشاء فرق خدمة خارجية للمساعدة في تطوير الزراعة العالمية والصناعة والبناء الاقتصادي والرعاية الطبية والتعليم وما إلى ذلك.<br><br><br><br>"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "وينصب التركيز على أمريكا الجنوبية باعتبارها المنطقة الرئيسية للمساعدة والإغاثة من قبل مؤسسة KNight Capital Foundation، ومن المتوقع أن تستثمر ما لا يقل عن 50 مليون دولار أمريكي في المساعدات الخاصة. في غضون ثلاث سنوات، سيتم إنشاء 1000 مكتب خيري غير متصل بمؤسسة KNight Capital Foundation و5000 فرصة عمل في أمريكا الجنوبية، مع التركيوالأرجنتين، وتشيلي."            
        },
        item2: {
            title: "2025",
            desc: "يصل دخل الاستثمار ومبلغ التبرعات لمؤسسة KNight Capital Foundation إلى 100 مليون دولار أمريكي سنويًا."
        },
        item3: {
            title: "2026",
            desc: "تحقيق 5000 مكتب خيري لمؤسسة KNight Capital Foundation حول العالم وأكثر من 20000 فرصة عمل"
        },
        item4: {
            title: "2027",
            desc: "ومن المتوقع أن يصل دخل الاستثمار ومبلغ التبرعات لمؤسسة KNight Capital Foundation إلى أكثر من 300 مليون دولار أمريكي. ومن خلال دعم التكنولوجيا الزراعية وإنشاء البنية التحتية، ستساعد أكثر من 200 مليون شخص حول العالم على الهروب من خط الفقر الدولي، مما يتيح لهم الحصول على الصحة والسعادة والفرح."
        }
    },
    counterdata: {
      title: "الوضع الحالي والخطط المستقبلية لـ KNight Consortium",
      desc: "",
      item1: {
          title: 'أموال',
          unit: "مليار",
      },
      item2: {
          title: 'المستخدمين',
          unit: "مليار",
      },
      item3: {
          title: 'يستثمر',
          unit: "شريك",
      },
      item4: {
          title: 'يتبرع',
          unit: "مليار",
      }
    },
    teamdata: {
        title: "أعضاء",
        item1: {
            name: "Remus John Worm",
            desc: "ماجستير في الاقتصاد، كلية الاقتصاد بجامعة شيكاغو"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "ماجستير العلوم وماجستير إدارة الأعمال، جامعة أوتاوا"
        },
        item3: {
            name: "Liu Caijun",
            desc: "تخرج من جامعة بكين، ماجستير في الإدارة"
        }
    },
    article: {
        title: "من خلال جمع قوة المستثمرين الأفراد، يلتزم Knight Consortium بمواجهة المؤسسات والنضال من أجل المزيد من الحقوق للمستثمرين العاديين.",
        desc: "في سوق الأوراق المالية، هناك نوعان من اللاعبين، أحدهما مستثمرو التجزئة (الأفراد)، والآخر مستثمرون من المؤسسات (وكلاء المراهنات). وكانت القوة المالية المؤسسية تعمل دائماً على الضغط على الحيز المعيشي للمستثمرين الأفراد، واحتكار سوق الأوراق المالية والتلاعب بها. لقد شعر غالبية مستثمري التجزئة بالظلم من هذا لفترة طويلة، وبدأت معركة ملحمية بين مستثمري التجزئة والبائعين على المكشوف في وول ستريت - حادثة \"GameStop Bloodbath on Wall Street\". وبمجرد توحيد قوة مستثمري التجزئة، إنهم القوة الأقوى ولديهم هيمنة لا جدال فيها على اتجاه سعر السهم."
    },
    certdata: {
        title:"شهادات التأسيس الوطنية",
        item1: {
            title: "",
            desc: "الولايات المتحدة الأمريكية",
        },
        item2: {
            title: "",
            desc: "سنغافورة",
        },
        item3: {
            title: "",
            desc: "المملكة المتحدة.",
        },
    },
    purpose: {
        title:"Knight Consortium Mission",
        btntitle:"PDF Download",
        image: require('@/assets/images/pdf/pdf_en.png'),
        downlink:"/en.pdf",
    }
}



export const digitalFour = {
    digita: {
        title:"الأنشطة الخيرية لمؤسسة الفرسان"
    }
};


export const BlogDinner = {
    dinnerGrid: {
        title:"أعضاء اتحاد Knight يتناولون العشاء خارج الإنترنت"
    }
};


export const homeNotice = {
    notice:{
        title:"إعلان خاص من اتحاد الفرسان",
        desc:""
    }
}


export const homeDinner = {
    title: "كان عشاء فرسان كونسورتيوم بوينس آيرس ناجحًا تمامًا",
    desc: "لا يعد هذا العشاء مجرد حدث كبير للوحدة والمشاركة والتطلع إلى المستقبل، ولكنه أيضًا لحظة مهمة لأعضاء اتحاد الفرسان للاجتماع معًا لمشاهدة التاريخ المجيد للاتحاد. وفي المستقبل، نتطلع إلى مواصلة العمل يدًا بيد مع الجميع لاستقبال المزيد من الأوقات الجميلة وتحقيق المزيد من النتائج الرائعة.",
}

export const BlogGalaDinner = {
    dinnerGrid: {
        title:"عشاء اتحاد الفرسان في بوينس آيرس"
    }
};