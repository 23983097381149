//葡萄牙
export const head = {
  home: "Lar",
  team: "Equipe",
  case: "Caso",
  industry: "Indústria",
  contact: "Contato",
  about: "Sobre"
};
  
export const foot = {
  services: {
      title: "Serviços",
      home: "Lar"
  },
  case: {
      title: "Caso",
      case: "Caso"
  },
  company: {
      title: "Empresa",
      contact: "Contato",
      about: "Sobre"
  },
  resources: {
      title: "Recursos",
      industry: "Indústria"
  },
  stay: {
      title: "Fique conosco.",
      desc: "Mais de 2.000 nossos clientes estão inscritos em todo o mundo"
  },
  copyright: {
      contact: "Contate-nos"
  }
};

export const home = {
  banner22: {
    title: "Parabéns calorosos",
    desc: "O jantar em Buenos Aires foi um sucesso total."
  },
  banner1: {
      title: "Consórcio Knight",
      desc: "De investidores de varejo a banqueiros, o líder do KNight Consortium"
  },
  banner2: {
      title: "Nosso compromisso",
      desc: "Concorrência Leal no Setor Financeiro"
  },
  banner3: {
      title: "Cavalheiros amam a fortuna, de uma maneira adequada",
      desc: "O Consórcio Knight está disposto a guiar este amplo caminho para que mais pessoas possam descobrir, para que todos possam alcançar a prosperidade e a prosperidade iniciais, e então alcançar a auto-realização!"
  },
  servicefive: {
      title: "Progresso, desenvolvimento e cuidado são os valores fundamentais do KNight Consortium",
    //   desc: "We are committed to promoting global economic relations and economic development. By integrating global knowledge, technology, funds and talents, we have established overseas service teams and provided funds to help areas lacking in agriculture, industry, economic construction, medical care and education around the world enjoy the same development opportunities. , work together to create a future vision of equality, dignity, prosperity and health.",
      desc: "",
      item1: {
          title: "perfil de companhia",
        //   desc: "KNight Consortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.",
        desc: "O Consórcio KNight foi estabelecido em Cingapura em 2010 com um capital inicial de aproximadamente US$ 5 milhões e agora tem um capital gerenciado de US$ 1 bilhão. Estamos comprometidos em promover as relações econômicas globais e o desenvolvimento econômico, e em estabelecer equipes de serviços no exterior, integrando o conhecimento global. , tecnologia, fundos e talentos."
      },
      item2: {
          title: "Cultura da empresa",
          desc: "O objetivo fundador do KNight Consortium é reunir amor, transmitir carinho, ajudar as pessoas a participarem de caridade e compartilharem juntos uma vida melhor e oportunidades de desenvolvimento. Promova conexões ao redor do mundo e forneça assistência a refugiados em países e regiões afetadas por desastres naturais.<br><br><br>",
      },
      item3: {
          title: "Visão da Empresa",
          desc: "Os objetivos e conotações importantes do desenvolvimento de assuntos com cooperação internacional e desenvolvimento sustentável são a visão da organização. Neste sentido, trabalhamos com a comunidade internacional, a sociedade civil e outras entidades de ajuda ao desenvolvimento para integrar ativamente o conhecimento, a tecnologia, os fundos e os talentos para criar um futuro de igualdade, dignidade, prosperidade e saúde.",
      }
  },
  portfolio: {
      title: "Setor de investimentos do Consórcio KNight e casos de sucesso",
      desc: "Nós nos esforçamos para promover o desenvolvimento econômico e social global e as relações econômicas, e estabelecer equipes de serviços no exterior para auxiliar o desenvolvimento da construção médica, agrícola, industrial, econômica, educação, etc.",
      cat1: {
          name: "finance",
          item1: {
              title: "A equipe de investimentos do KNight Consortium comprou ações de várias empresas de mineração de metais preciosos. Estas empresas tiveram um bom desempenho durante 2012, uma vez que o aumento dos preços dos metais preciosos fez com que os preços das suas ações subissem, resultando em enormes ganhos para o sindicato KNights.",
          },
          item2: {
              title: "Durante o período de janeiro a fevereiro de 2012, o preço internacional do ouro oscilou. Os analistas financeiros do KNight Consortium aproveitaram a oportunidade para comprar a preços baixos e obtiveram lucro de quase 60% em apenas três meses.",
          }
      },
      cat2: {
          name: "medical",
          item1: {
              title: "Em 2020, o Centro de Serviços Médicos do Consórcio KNight foi estabelecido e o método de tratamento de \"imunoterapia tumoral\" desenvolvido em conjunto com o Grupo Farmacêutico Merck foi bem-sucedido.",
          },
          item2: {
              title: "O KNight Consortium Medical Services Center e a Novartis Pharmaceuticals desenvolveram o medicamento contra o câncer Houng-592",
          }
      },
      cat3: {
          name: "metaverse",
          item1: {
              title: "Em 2021, o centro web3.0 do KNight Consortium foi estabelecido para criar uma plataforma social descentralizada, que atraiu ampla atenção e foi bem recebida pelos usuários.",
          },
          item2: {
              title: "No mercado altista de criptomoedas em janeiro de 2017, o Consórcio KNight aproveitou a oportunidade e levou os membros da fundação a obter lucros elevados, e os ativos de todos os membros dobraram rapidamente.",
          }
      }
  },
  counterdata: {
    title: "Status atual e planos futuros do KNight Consortium",
    desc: "",
    item1: {
        title: 'Fundos',
        unit: "Bilhão",
    },
    item2: {
        title: 'Usuários',
        unit: "Bilhão",
    },
    item3: {
        title: 'Investir',
        unit: "Parceiro",
    },
    item4: {
        title: 'Doar',
        unit: "Bilhão",
    }
  },
  leader: {
      title: "Introdução aos membros da KNight Consortium Alliance",
      desc: "Os membros do capital sindical investem juntos, fazem caridade, alcançam a liberdade financeira e contribuem para um mundo melhor.",
      item1: {
          name: "Zhang Guofu",
          desc: "Obteve o título de mestre em economia financeira pela Universidade de Oxford, Reino Unido. Em agosto de 2010, ele fundou a KNight Consortium Capital Foundation e atuou como presidente do Consortium Capital."
      },
      item2: {
          name: "Remus John Worm",
          desc: "Mestre em Economia pela Escola de Economia da Universidade de Chicago. Atuou como vice-presidente da KNight Capital Foundation. <br><br><br>"
      },
      item3: {
          name: "Li Rongcheng",
          desc: "Mestre em Ciências e Mestre em Administração de Empresas pela Universidade de Ottawa. Atuou como vice-presidente da KNight Capital Foundation<br><br>"
      }
  },
  blogpost: {
    title: "Assistência Social do Consórcio KNight",
    desc: "A riqueza certamente trará esperança e amor. O Consórcio KNight segue o exemplo dos magnatas financeiros estrangeiros e usa parte da receita da fundação para caridade para aliviar o cerco e ajudar o desenvolvimento, fazendo com que o Consórcio KNight ganhe reconhecimento internacional e a confiança de mais usuários e investidores.",
    item1: {
        // title: "After the 7.8-magnitude earthquake in Turkey in February 2023, the KNight Consortium donated US$2.3 million to refugees"
        title: 'Doar materiais é a nossa ajuda mais direta e prática às vítimas. Em Fevereiro de 2023, a Turquia sofreu um terramoto de magnitude 7,8 e a Fundação Knights doou 2,3 milhões de dólares aos refugiados para reconstruírem as suas casas.',
    },
    item2: {
        // title: "In July 2021, heavy rains caused floods in Zhengzhou, Henan Province, China. The KNight Consortium donated US$1.8 million;"
        title: 'Em Setembro de 2022, a equipa de caridade da Fundação Knight foi a África para prestar cuidados e ajuda a crianças pobres e garantir que as necessidades básicas de vida das crianças fossem satisfeitas. O método de financiamento entre pares pode melhorar mais diretamente a vida das crianças e dar-lhes esperança e incentivo.',
    },
    item3: {
        // title: "During the 2019 Australian forest fires, Consortium Capital donated US$1.2 million for relief supplies and recovery."
        title: 'Em fevereiro de 2023, a Turquia sofreu um terremoto de magnitude 7,8, muitas áreas sofreram graves danos e vítimas. A Fundação Knight enviou equipes de resgate para resgatar as vítimas, dar-lhes calor e esperança e deixá-las sentir o calor e o cuidado da família social.',
    },
    item4: {
      title: 'Os civis não receberam qualquer protecção durante a guerra israelo-palestiniana. O Consórcio dos Cavaleiros garantiu que o povo recebesse assistência básica e forneceu ativamente importantes trabalhadores humanitários.',
    },
    item5: {
      title: 'Na guerra israelo-palestiniana, a abertura de linhas de abastecimento e a assistência humanitária são necessidades críticas e urgentes. O Consórcio dos Cavaleiros aproveita o período de janela para entregar suprimentos de ajuda que são cruciais para o bem-estar do povo de Gaza, e coordena-se com organizações humanitárias internacionais para garantir a chegada de suprimentos de ajuda.',
    },
    item6: {
      title: 'À medida que a guerra israelo-palestiniana continua, o número de vítimas continua a aumentar. O resgate das vítimas é iminente e o Consórcio Knight organiza ativamente equipes de resgate para fornecer resgate imediato.',
    },
    item7: {
        title: 'O Grupo Knight sempre foi conhecido por sua assistência profissional. Contamos com uma equipe experiente que pode responder rapidamente e fornecer soluções práticas. Todos nós operamos de maneira eficiente e transparente para garantir que toda ajuda seja usada de forma adequada e eficaz.',
    },
    item8: {
        title: 'Escolher a assistência profissional do Knight Consortium significa que você escolheu um parceiro confiável, uma equipe dedicada a melhorar vidas e reconstruir a esperança. Não pouparemos esforços para proporcionar às vítimas o apoio de que necessitam para as ajudar a ultrapassar as dificuldades e a avançar em direcção a um futuro melhor.',
    },
    item9: {
        title: 'Na névoa da guerra, a reconstrução pós-guerra é um passo crítico que devemos dar. A reconstrução de comunidades, a reparação de edifícios danificados e a reintegração das vítimas na sociedade requerem assistência profissional e apoio empenhado.',
    },
    item10: {
        title: 'A Fundação Knights veio imediatamente em socorro e forneceu um raio de ajuda às vítimas da guerra palestino-israelense. Face aos desafios da guerra israelo-palestiniana, a nossa Fundação Knights apoia firmemente as vítimas. Como equipe comprometida com a equidade financeira e a responsabilidade social, sempre consideramos o cuidado e a assistência uma de nossas responsabilidades.',
    },
    item11: {
        title: 'Na guerra israelo-palestiniana, estamos bem conscientes dos desafios e dificuldades enfrentados pelas vítimas. Tomamos medidas de emergência para fornecer assistência material urgentemente necessária às áreas afetadas. Esses suprimentos incluem alimentos, água, suprimentos médicos e equipamentos de resgate de emergência e são projetados para fornecer a ajuda e o apoio tão necessários às pessoas afetadas pelo desastre.',
    },
    item12: {
        title: 'A Fundação Knights está há muito comprometida em apoiar a revitalização do pós-guerra. Reconstruir as comunidades e ajudar as pessoas afectadas é crucial. Portanto, não hesitamos em estender a mão para garantir que as pessoas afectadas pela guerra possam reconstruir as suas vidas.',
    },
    item13: {
      title: 'Na névoa da guerra, unimos as mãos para prestar ajuda às vítimas. A Fundação Knights sempre defendeu um sentido de responsabilidade social e está empenhada em ajudar as pessoas afetadas pela guerra. Vamos iluminar as vidas das vítimas da guerra com a luz da esperança!',
    },
    item14: {
      title: 'Nos momentos difíceis do pós-guerra, o Consórcio dos Cavaleiros está disposto a ser seu parceiro confiável e dar as mãos na missão de resgate e reconstrução. Sabemos que todas as vítimas de desastres precisam de cuidados e assistência, por isso participamos ativamente em diversas operações de resgate para fornecer o apoio necessário aos que sofrem.',
    },
    item15: {
      title: 'A Fundação Knights tem o compromisso de difundir esse espírito. Através de ações de caridade, projetos de ajuda e trabalho voluntário, nos esforçamos para melhorar vidas, apoiar comunidades e tornar o mundo um lugar mais quente. Somente trabalhando juntos poderemos criar um futuro melhor e mais esperançoso.',
    },
    item16: {
        title: 'A Argentina foi atingida por uma tempestade superforte, resultando na perda de 16 vidas inocentes. O Consórcio Knight ficou profundamente triste e respondeu imediatamente, e nossa equipe profissional participou imediatamente do trabalho de socorro. Durante esta emergência, continuamos empenhados em apoiar as áreas afetadas.',
    },
    item17: {
        title: 'Soubemos que um terremoto de magnitude 6,2 ocorreu em Gansu, na China. Este desastre causou grande sofrimento aos moradores locais. Neste momento difícil, a Fundação Knights está ao lado das pessoas na área do desastre e apresenta as suas mais profundas condolências e apoio.',
    },
    item18: {
        title: 'Há momentos na jornada da vida que exigem mais cuidado e apoio. A Fundação Knight assume a responsabilidade social como sua própria responsabilidade. Acreditamos firmemente que ajudar os necessitados é uma parte importante da construção de uma sociedade melhor e estaremos sempre ao seu lado.',
    }
    ,
    item19: {
        title: 'O Consórcio Knight está prestes a partir novamente! Olhando para o leite, óleo, macarrão, biscoitos e outros alimentos bem arrumados no chão, eles estavam prestes a entregá-los a uma família local necessitada. Vendo seus rostos felizes, sabemos quanto calor e esperança esses suprimentos trarão. Um amor, um carinho, a Fundação Knight interpreta a responsabilidade social com ações, ansiamos pelo toque que essa entrega traz.',
    },
    item20: {
        title: 'Recentemente, testemunhamos uma grande equipa de membros do consórcio de cavaleiros, cheios de energia e paixão, transportando cargas de alimentos para as comunidades locais para visitar mulheres, idosos e crianças. Cada membro desta equipe exala entusiasmo e cuidado sem limites, e sua presença injeta uma corrente calorosa na comunidade. Durante o evento, vimos rapazes e moças interagindo cordialmente com idosos e crianças, com sorrisos repletos de alegria e comoção. Seja distribuindo comida ou conversando, todos estão envolvidos e levam carinho e carinho aos outros de coração. Esta equipe dinâmica do Knight Financial Group demonstra o espírito de unidade e amizade e injeta um forte amor na comunidade.',
    },
    item21: {
        title: 'Recentemente, um grupo de membros do Consórcio Knight conduziu uma visita comovente. Eles foram até o local e visitaram mulheres, idosos e crianças, trazendo carinho e carinho. Este evento não é apenas uma simples visita, mas também um reflexo do sincero cuidado e apoio à comunidade. Durante o evento, os membros da Fundação Knights tiveram conversas cordiais com os entrevistados e compartilharam histórias e experiências uns com os outros. Os idosos sorriam, as crianças riam e as mulheres estavam gratas. Eles ouviram com atenção e acompanharam com carinho, fazendo com que cada entrevistado sentisse o carinho e carinho da comunidade.',
    },
    item22: {
        title: 'Em um dia ensolarado, a equipe de San Juan do Consórcio Knight visitou famílias necessitadas locais com todo o cuidado. Os membros da equipe distribuíram itens de primeira necessidade, como arroz, óleo de cozinha, macarrão oco, latas, pão e biscoitos, e conduziram cordiais condolências e conversas com cada família. A distribuição material deste tempo não é apenas um apoio prático para as necessidades diárias, mas também um conforto espiritual para cada família. Os voluntários da Knight compartilham momentos calorosos com as famílias atendidas, transmitindo cuidado e esperança uns aos outros. As famílias atendidas expressaram sua gratidão aos voluntários Knight, e esse carinho os deixou cheios de confiança e expectativas para o futuro. Quando os voluntários partiram, as famílias enviaram suas sinceras bênçãos e agradeceram à equipe de San Juan da Fundação Knights pelo carinho e carinho que lhes proporcionaram.',
    },
    item23: {
        title: 'Os membros da Fundação Knights estenderam a mão a várias famílias pobres locais e enviaram-lhes abundantes necessidades diárias! Num evento passado, voluntários entusiasmados da Fundação Knights foram para a área local carregando suprimentos abundantes, como óleo de cozinha, macarrão, trigo e farinha, e entregaram essas necessidades diárias a várias famílias pobres. Esta não é apenas uma simples doação, mas também uma sincera condolência e comunicação. Ao receberem esse carinho, as famílias atendidas expressaram sua profunda gratidão aos voluntários Knight. As dificuldades da vida parecem ficar mais calorosas e cheias de esperança e expectativa por causa desse cuidado.',
    },
    item24: {
        title: 'Os voluntários da Fundação Knight embarcaram em uma comovente jornada de caridade com necessidades diárias como pão, óleo, leite, açúcar, etc. Eles penetraram profundamente nas áreas montanhosas e forneceram ajuda tangível às famílias necessitadas que viviam nas encostas e tinham transporte limitado. Esta distribuição de material cobre todos os aspectos da vida diária da família, incluindo óleo de cozinha, farinha, leite, açúcar, etc., garantindo que cada família possa sentir o caloroso cuidado da Fundação Knights. Os voluntários não são apenas transportadores de suprimentos, mas também entregadores de companheirismo, condolências e comunicação. As famílias ajudadas estão localizadas em áreas montanhosas remotas e muitas vezes enfrentam desafios como transporte inconveniente e condições de vida difíceis. Portanto, a chegada dos voluntários Cavaleiros foi uma bênção oportuna para eles e os encheu de gratidão. Todos compartilharam histórias uns com os outros durante a conversa, tornando este evento de caridade mais caloroso e significativo.',
    },
    item25: {
      title: 'Recentemente, o sol estava brilhando intensamente e os membros do Consórcio Knight chegaram à área local com baldes de óleo de cozinha e suco para ajudar e enviar calor e cuidado àqueles que precisavam de apoio e ajuda. Neste lindo dia, não somos apenas membros do Consórcio dos Cavaleiros, mas também parte da comunidade. Sabemos que, como equipa, as nossas responsabilidades e missões são os nossos ombros. Portanto, estamos dispostos a trabalhar duro e usar ações para demonstrar nosso cuidado e apoio à comunidade. Vamos espalhar nosso amor juntos e encher o mundo de calor e esperança. Obrigado pelo seu apoio, vamos continuar avançando juntos e contribuir para o desenvolvimento e progresso da comunidade!',
    },
    item26: {
      title: 'Recentemente, nossa equipe do Consórcio Knight veio a Córdoba, Argentina. Este lindo lugar está cheio de calor e esperança. Nossa equipe de quase 30 membros está reunida aqui. Com um coração caloroso e seu amor e apoio, ajudaremos mais pessoas necessitadas e lhes traremos carinho e carinho. Nesta ação cheia de amor, não somos apenas membros do Consórcio dos Cavaleiros, mas também seu porta-voz e mensageiro. Graças à sua generosidade e amor, conseguimos chegar até aqui e levar gratidão a mais pessoas. Vamos dar as mãos para contribuir com a sociedade e encher o mundo de mais calor e esperança. Obrigado pelo seu apoio, vamos continuar a espalhar o poder do amor!',
    }
  }
}

export const team = {
    leader1: {
        title: "Presidente",
        name: "Zhang Guofu",
        desc: "Mestrado em Economia Financeira pela Universidade de Oxford, Reino Unido"
    },
    leader2: {
        title: "Vice-presidente e Diretor Jurídico",
        item1: {
            name: "Remus John Worm",
            desc: "Mestre em Economia, Escola de Economia da Universidade de Chicago"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Mestre em Ciências e Mestre em Administração de Empresas, Universidade de Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Graduado pela Universidade de Pequim, Mestre em Gestão"
        }
    },
    leader3: {
        title: "Analista financeiro",
        item1: {
            name: "Maxwell",
            desc: "Bacharel em Finanças Internacionais, Universidade Fudan"
        },
        item2: {
            name: "Yang Jun",
            desc: "Graduado pela Nanyang Technological University, Singapura, com especialização em Finanças"
        },
        item3: {
            name: "Hubert",
            desc: "Diploma duplo da Universidade de Londres, Reino Unido, e Universidade de Harvard, EUA"
        }
    },
    leader4: {
        title: "Consultor",
        item1: {
            name: "Анфия",
            desc: "Bacharelado, Universidade da Flórida"
        },
        item2: {
            name: "Xu Hao",
            desc: "Graduação em Engenharia pela Universidade George Washington"
        },
        item3: {
            name: "Dorothy",
            desc: "MBA do Instituto Indiano de Tecnologia"
        }
    },
    leader5: {
        title: "Membro principal",
        item1: {
            name: "sun horse",
            desc: "Estudando Economia na Royal Holloway, Universidade de Londres, Reino Unido"
        },
        item2: {
            name: "Yvonne",
            desc: "Mestrado pela Universidade Livre de Bruxelas"
        },
        item3: {
            name: "Yan Tao",
            desc: "Mestrado pela Universidade de Washington (Graduação em Gestão de Engenharia)"
        }
    }
}

export const casepage = {
    title: "Introdução ao bem-estar social",
    item1: {
        title: "Após o terremoto de magnitude 7,8 na Turquia em fevereiro de 2023, o Consórcio KNight doou US$ 2,3 milhões para refugiados"
    },
    item2: {
        title: "Em julho de 2021, fortes chuvas causaram inundações em Zhengzhou, província de Henan, China. O Consórcio KNight doou US$ 1,8 milhão;"
    },
    item3: {
        title: "Durante os incêndios florestais australianos de 2019, a Consortium Capital doou US$ 1,2 milhão para suprimentos de socorro e recuperação."
    },
    item4: {
        title: "Em fevereiro de 2023, um terremoto de magnitude 7,8 atingiu a Turquia, causando mais de 41 mil mortes. 1,5 milhões de pessoas ficaram desalojadas na região sul e pelo menos 500 mil novas casas tiveram de ser construídas. No noroeste da Síria, cerca de 9 milhões de pessoas foram afetadas e pelo menos 6.000 pessoas perderam a vida. A Consortium Capital doou US$ 2,3 milhões aos refugiados para fornecer materiais de subsistência e reconstruir suas casas.",
    },
    item5: {
        title: "Em Junho de 2022, o terramoto no Afeganistão matou mais de 1.000 pessoas e feriu mais de 2.000 pessoas. O capital do consórcio doou 1 milhão de dólares para a reconstrução de habitações pós-desastre, parte do qual foi utilizado para necessidades diárias.",
    },
    item6: {
        title: "Doar materiais é a nossa ajuda mais direta e prática às vítimas. Em Fevereiro de 2023, a Turquia sofreu um terramoto de magnitude 7,8 e a Fundação Knights doou 2,3 milhões de dólares aos refugiados para reconstruírem as suas casas.",
    },
    item7: {
        title: "Em fevereiro de 2023, a Turquia sofreu um terremoto de magnitude 7,8, muitas áreas sofreram graves danos e vítimas. A Fundação Knight enviou equipes de resgate para resgatar as vítimas, dar-lhes calor e esperança e deixá-las sentir o calor e o cuidado da família social.",
    },
    item8: {
        title: "O terremoto no Afeganistão em junho de 2022 matou mais de 1.000 pessoas e feriu mais de 2.000 pessoas. O capital do consórcio doou 1 milhão de dólares para a reconstrução de habitações pós-desastre, parte do qual foi utilizado para despesas de subsistência.",
    },
    item9: {
        title: "A Fundação Knights participa ativamente de ações beneficentes que cuidam de crianças carentes. Assistência financeira e cuidados regulares são fornecidos por meio de doações e serviços voluntários. Este tipo de cuidado e ajuda não só muda o destino das crianças, mas também injeta mais energia positiva no desenvolvimento de toda a sociedade.",
    },
    item10: {
        title: "Em Setembro de 2022, a equipa de caridade da Fundação Knight foi a África para prestar cuidados e ajuda a crianças pobres e garantir que as suas necessidades básicas de vida fossem satisfeitas. O método de financiamento entre pares pode melhorar mais diretamente a vida das crianças e dar-lhes esperança e incentivo.",
    },
    item11: {
        title: "Os civis não receberam qualquer protecção durante a guerra israelo-palestiniana. O Consórcio dos Cavaleiros garantiu que o povo recebesse assistência básica e forneceu ativamente importantes trabalhadores humanitários.",
    },
    item12: {
        title: "Na guerra israelo-palestiniana, a abertura de linhas de abastecimento e a assistência humanitária são necessidades críticas e urgentes. O Consórcio dos Cavaleiros aproveita o período de janela para entregar suprimentos de ajuda que são cruciais para o bem-estar do povo de Gaza, e coordena-se com organizações humanitárias internacionais para garantir a chegada de suprimentos de ajuda.",
    },
    item13: {
        title: "À medida que a guerra israelo-palestiniana continua, o número de vítimas continua a aumentar. O resgate das vítimas é iminente e o Consórcio Knight organiza ativamente equipes de resgate para fornecer resgate imediato.",
    },
    item14: {
        title: "Em julho de 2023, afetado pelo tufão nº 5 \"Dusuri\", chuvas extremamente fortes ocorreram em Pequim, Tianjin, Hebei e outros lugares na China, causando desastres de enchentes. O Consórcio Knight lançou resgate de emergência e forneceu canais de resgate oportunos para ajudar idosos e pessoas em perigo a sair de problemas.",
    },
    item15: {
        title: 'A Fundação Knights veio imediatamente em socorro e forneceu um raio de ajuda às vítimas da guerra palestino-israelense. Face aos desafios da guerra israelo-palestiniana, a nossa Fundação Knights apoia firmemente as vítimas. Como equipe comprometida com a equidade financeira e a responsabilidade social, sempre consideramos o cuidado e a assistência uma de nossas responsabilidades.',
    },
    item16: {
        title: 'Na guerra israelo-palestiniana, estamos bem conscientes dos desafios e dificuldades enfrentados pelas vítimas. Tomamos medidas de emergência para fornecer assistência material urgentemente necessária às áreas afetadas. Esses suprimentos incluem alimentos, água, suprimentos médicos e equipamentos de resgate de emergência e são projetados para fornecer a ajuda e o apoio tão necessários às pessoas afetadas pelo desastre.',
    },
    item17: {
        title: 'A Fundação Knights está há muito comprometida em apoiar a revitalização do pós-guerra. Reconstruir as comunidades e ajudar as pessoas afectadas é crucial. Portanto, não hesitamos em estender a mão para garantir que as pessoas afectadas pela guerra possam reconstruir as suas vidas.',
    },
    item18: {
        title: 'O Grupo Knight sempre foi conhecido por sua assistência profissional. Contamos com uma equipe experiente que pode responder rapidamente e fornecer soluções práticas. Todos nós operamos de maneira eficiente e transparente para garantir que toda ajuda seja usada de forma adequada e eficaz.',
    },
    item19: {
        title: 'Escolher a assistência profissional do Knight Consortium significa que você escolheu um parceiro confiável, uma equipe dedicada a melhorar vidas e reconstruir a esperança. Não pouparemos esforços para proporcionar às vítimas o apoio de que necessitam para as ajudar a ultrapassar as dificuldades e a avançar em direcção a um futuro melhor.',
    },
    item20: {
        title: 'Na névoa da guerra, a reconstrução pós-guerra é um passo crítico que devemos dar. A reconstrução de comunidades, a reparação de edifícios danificados e a reintegração das vítimas na sociedade requerem assistência profissional e apoio empenhado.',
    },
    item21: {
      title: 'Na névoa da guerra, unimos as mãos para prestar ajuda às vítimas. A Fundação Knights sempre defendeu um sentido de responsabilidade social e está empenhada em ajudar as pessoas afetadas pela guerra. Vamos iluminar as vidas das vítimas da guerra com a luz da esperança!',
    },
    item22: {
      title: 'Nos momentos difíceis do pós-guerra, o Consórcio dos Cavaleiros está disposto a ser seu parceiro confiável e dar as mãos na missão de resgate e reconstrução. Sabemos que todas as vítimas de desastres precisam de cuidados e assistência, por isso participamos ativamente em diversas operações de resgate para fornecer o apoio necessário aos que sofrem.',
    },
    item23: {
      title: 'A Fundação Knights tem o compromisso de difundir esse espírito. Através de ações de caridade, projetos de ajuda e trabalho voluntário, nos esforçamos para melhorar vidas, apoiar comunidades e tornar o mundo um lugar mais quente. Somente trabalhando juntos poderemos criar um futuro melhor e mais esperançoso.',
    },
    item24: {
        title: 'A Argentina foi atingida por uma tempestade superforte, resultando na perda de 16 vidas inocentes. O Consórcio Knight ficou profundamente triste e respondeu imediatamente, e nossa equipe profissional participou imediatamente do trabalho de socorro. Durante esta emergência, continuamos empenhados em apoiar as áreas afetadas.',
    },
    item25: {
        title: 'Soubemos que um terremoto de magnitude 6,2 ocorreu em Gansu, na China. Este desastre causou grande sofrimento aos moradores locais. Neste momento difícil, a Fundação Knights está ao lado das pessoas na área do desastre e apresenta as suas mais profundas condolências e apoio.',
    },
    item26: {
        title: 'Há momentos na jornada da vida que exigem mais cuidado e apoio. A Fundação Knight assume a responsabilidade social como sua própria responsabilidade. Acreditamos firmemente que ajudar os necessitados é uma parte importante da construção de uma sociedade melhor e estaremos sempre ao seu lado.',
    },
    item27: {
        title: 'O Consórcio Knight está prestes a partir novamente! Olhando para o leite, óleo, macarrão, biscoitos e outros alimentos bem arrumados no chão, eles estavam prestes a entregá-los a uma família local necessitada. Vendo seus rostos felizes, sabemos quanto calor e esperança esses suprimentos trarão. Um amor, um carinho, a Fundação Knight interpreta a responsabilidade social com ações, ansiamos pelo toque que essa entrega traz.',
    },
    item28: {
        title: 'Recentemente, testemunhamos uma grande equipa de membros do consórcio de cavaleiros, cheios de energia e paixão, transportando cargas de alimentos para as comunidades locais para visitar mulheres, idosos e crianças. Cada membro desta equipe exala entusiasmo e cuidado sem limites, e sua presença injeta uma corrente calorosa na comunidade. Durante o evento, vimos rapazes e moças interagindo cordialmente com idosos e crianças, com sorrisos repletos de alegria e comoção. Seja distribuindo comida ou conversando, todos estão envolvidos e levam carinho e carinho aos outros de coração. Esta equipe dinâmica do Knight Financial Group demonstra o espírito de unidade e amizade e injeta um forte amor na comunidade.',
    },
    item29: {
        title: 'Recentemente, um grupo de membros do Consórcio Knight conduziu uma visita comovente. Eles foram até o local e visitaram mulheres, idosos e crianças, trazendo carinho e carinho. Este evento não é apenas uma simples visita, mas também um reflexo do sincero cuidado e apoio à comunidade. Durante o evento, os membros da Fundação Knights tiveram conversas cordiais com os entrevistados e compartilharam histórias e experiências uns com os outros. Os idosos sorriam, as crianças riam e as mulheres estavam gratas. Eles ouviram com atenção e acompanharam com carinho, fazendo com que cada entrevistado sentisse o carinho e carinho da comunidade.',
    },
    item30: {
        title: 'Em um dia ensolarado, a equipe de San Juan do Consórcio Knight visitou famílias necessitadas locais com todo o cuidado. Os membros da equipe distribuíram itens de primeira necessidade, como arroz, óleo de cozinha, macarrão oco, latas, pão e biscoitos, e conduziram cordiais condolências e conversas com cada família. A distribuição material deste tempo não é apenas um apoio prático para as necessidades diárias, mas também um conforto espiritual para cada família. Os voluntários da Knight compartilham momentos calorosos com as famílias atendidas, transmitindo cuidado e esperança uns aos outros. As famílias atendidas expressaram sua gratidão aos voluntários Knight, e esse carinho os deixou cheios de confiança e expectativas para o futuro. Quando os voluntários partiram, as famílias enviaram suas sinceras bênçãos e agradeceram à equipe de San Juan da Fundação Knights pelo carinho e carinho que lhes proporcionaram.',
    },
    item31: {
        title: 'Os membros da Fundação Knights estenderam a mão a várias famílias pobres locais e enviaram-lhes abundantes necessidades diárias! Num evento passado, voluntários entusiasmados da Fundação Knights foram para a área local carregando suprimentos abundantes, como óleo de cozinha, macarrão, trigo e farinha, e entregaram essas necessidades diárias a várias famílias pobres. Esta não é apenas uma simples doação, mas também uma sincera condolência e comunicação. Ao receberem esse carinho, as famílias atendidas expressaram sua profunda gratidão aos voluntários Knight. As dificuldades da vida parecem ficar mais calorosas e cheias de esperança e expectativa por causa desse cuidado.',
    },
    item32: {
        title: 'Os voluntários da Fundação Knight embarcaram em uma comovente jornada de caridade com necessidades diárias como pão, óleo, leite, açúcar, etc. Eles penetraram profundamente nas áreas montanhosas e forneceram ajuda tangível às famílias necessitadas que viviam nas encostas e tinham transporte limitado. Esta distribuição de material cobre todos os aspectos da vida diária da família, incluindo óleo de cozinha, farinha, leite, açúcar, etc., garantindo que cada família possa sentir o caloroso cuidado da Fundação Knights. Os voluntários não são apenas transportadores de suprimentos, mas também entregadores de companheirismo, condolências e comunicação. As famílias ajudadas estão localizadas em áreas montanhosas remotas e muitas vezes enfrentam desafios como transporte inconveniente e condições de vida difíceis. Portanto, a chegada dos voluntários Cavaleiros foi uma bênção oportuna para eles e os encheu de gratidão. Todos compartilharam histórias uns com os outros durante a conversa, tornando este evento de caridade mais caloroso e significativo.',
    },
    item33: {
      title: 'Recentemente, o sol estava brilhando intensamente e os membros do Consórcio Knight chegaram à área local com baldes de óleo de cozinha e suco para ajudar e enviar calor e cuidado àqueles que precisavam de apoio e ajuda. Neste lindo dia, não somos apenas membros do Consórcio dos Cavaleiros, mas também parte da comunidade. Sabemos que, como equipa, as nossas responsabilidades e missões são os nossos ombros. Portanto, estamos dispostos a trabalhar duro e usar ações para demonstrar nosso cuidado e apoio à comunidade. Vamos espalhar nosso amor juntos e encher o mundo de calor e esperança. Obrigado pelo seu apoio, vamos continuar avançando juntos e contribuir para o desenvolvimento e progresso da comunidade!',
    },
    item34: {
      title: 'Recentemente, nossa equipe do Consórcio Knight veio a Córdoba, Argentina. Este lindo lugar está cheio de calor e esperança. Nossa equipe de quase 30 membros está reunida aqui. Com um coração caloroso e seu amor e apoio, ajudaremos mais pessoas necessitadas e lhes traremos carinho e carinho. Nesta ação cheia de amor, não somos apenas membros do Consórcio dos Cavaleiros, mas também seu porta-voz e mensageiro. Graças à sua generosidade e amor, conseguimos chegar até aqui e levar gratidão a mais pessoas. Vamos dar as mãos para contribuir com a sociedade e encher o mundo de mais calor e esperança. Obrigado pelo seu apoio, vamos continuar a espalhar o poder do amor!',
    },
    item35: {
      title: 'Membros do consórcio Knights se reuniram offline para compartilhar a festa das finais da Copa América e da Copa Europeia! Não apenas saboreamos uma comida deliciosa à mesa, mas também compartilhamos nosso entusiasmo e expectativas pelo jogo em meio a risadas e risadas. Não importa para qual time torcemos, neste momento somos todos torcedores fiéis do futebol, torcendo pelo campeão em nossos corações. As finais da Taça América e da Taça dos Campeões Europeus não são apenas uma festa do futebol, mas também um testemunho da unidade e amizade dos membros do Consórcio dos Cavaleiros.',
    },
    item36: {
      title: 'Reunimo-nos neste momento especial não só para celebrar o encanto do futebol, mas também para fortalecer as ligações e a cooperação mútuas. Cada jogo é um esforço de equipe e cada gol é nossa alegria coletiva! Obrigado a todos os membros do Consórcio dos Cavaleiros que participaram deste jantar. Seu entusiasmo e energia acrescentaram uma emoção sem fim a esta noite. Vamos continuar avançando de mãos dadas e receber mais vitórias e glórias juntos!',
    },
}

export const industry = {
    title: "Indústria de investimento",
    cat1: {
        name: "finance",
        item1: {
            title: "A equipe de investimentos do KNight Consortium comprou ações de várias empresas de mineração de metais preciosos. Estas empresas tiveram um bom desempenho durante 2012, uma vez que o aumento dos preços dos metais preciosos fez com que os preços das suas ações subissem, resultando em enormes ganhos para o sindicato KNights.",
        },
        item2: {
            title: "Durante o período de janeiro a fevereiro de 2012, o preço internacional do ouro oscilou. Os analistas financeiros do KNight Consortium aproveitaram a oportunidade para comprar a preços baixos e obtiveram lucro de quase 60% em apenas três meses.",
        }
    },
    cat2: {
        name: "medical",
        item1: {
            title: "Em 2020, o Centro de Serviços Médicos do Consórcio KNight foi estabelecido e o método de tratamento de \"imunoterapia tumoral\" desenvolvido em conjunto com o Grupo Farmacêutico Merck foi bem-sucedido.",
        },
        item2: {
            title: "O KNight Consortium Medical Services Center e a Novartis Pharmaceuticals desenvolveram o medicamento contra o câncer Houng-592",
        }
    },
    cat3: {
        name: "metaverse",
        item1: {
            title: "Em 2021, o centro web3.0 do KNight Consortium foi estabelecido para criar uma plataforma social descentralizada, que atraiu ampla atenção e foi bem recebida pelos usuários.",
        },
        item2: {
            title: "No mercado altista de criptomoedas em janeiro de 2017, o Consórcio KNight aproveitou a oportunidade e levou os membros da fundação a obter lucros elevados, e os ativos de todos os membros dobraram rapidamente.",
        }
    }
}


export const about = {
    title: "O Consórcio KNight une o poder dos investidores de varejo para realizar o sonho da liberdade financeira.",
    desc: "Os cavalheiros amam a fortuna, de uma maneira adequada. O Consórcio KNight está disposto a guiar este amplo caminho para que mais pessoas possam descobrir, para que todos possam alcançar a prosperidade e a prosperidade iniciais, e então alcançar a auto-realização! Ajude os investimentos individuais a escapar do destino de serem colhidos pelas instituições e a obter lucros através de operações de capital profissionais e confiáveis, ajudando assim os membros da equipe a realizarem seus sonhos de liberdade financeira.",
    service: {
        title: "Nossos objetivos de capital sindicalizado",
        item1: {
            title: "",
            desc: "Envidar todos os esforços para promover o desenvolvimento económico e social global e as relações económicas.<br><br><br><br><br><br>"
        },
        item2: {
            title: "",
            desc: "Promover o desenvolvimento da caridade para melhorar a subsistência das pessoas, prestar assistência nacional aos refugiados internacionais que sofrem de catástrofes naturais e utilizar ações práticas para ajudar os grupos desfavorecidos a sair dos problemas e deixá-los viver uma vida feliz e pacífica."
        },
        item3: {
            title: "",
            desc: "Estabelecer equipes de serviços no exterior para auxiliar o desenvolvimento global da agricultura, indústria, construção econômica, assistência médica, educação, etc.<br><br><br><br>"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "O foco está na América do Sul como a área chave para assistência e ajuda humanitária da Fundação KNight Capital, e espera-se que invista pelo menos 50 milhões de dólares em assistência privada. Dentro de três anos, 1.000 escritórios de caridade offline da KNight Capital Foundation e 5.000 oportunidades de emprego serão estabelecidos na América do Sul."
        },
        item2: {
            title: "2025",
            desc: "A receita de investimento e o valor das doações da KNight Capital Foundation atinge US$ 100 milhões anualmente."
        },
        item3: {
            title: "2026",
            desc: "Alcançar 5.000 escritórios de caridade off-line da KNight Capital Foundation em todo o mundo e mais de 20.000 oportunidades de emprego"
        },
        item4: {
            title: "2027",
            desc: "Espera-se que a receita de investimento e o valor das doações da KNight Capital Foundation cheguem a mais de 300 milhões de dólares americanos. Através do apoio à tecnologia agrícola e da construção de infra-estruturas, ajudará mais de 200 milhões de pessoas em todo o mundo a escapar do limiar de pobreza internacional, permitindo-lhes obter saúde, felicidade e alegria."
        }
    },
    counterdata: {
      title: "Status atual e planos futuros do KNight Consortium",
      desc: "",
      item1: {
          title: 'Fundos',
          unit: "Bilhão",
      },
      item2: {
          title: 'Usuários',
          unit: "Bilhão",
      },
      item3: {
          title: 'Investir',
          unit: "Parceiro",
      },
      item4: {
          title: 'Doar',
          unit: "Bilhão",
      }
    },
    teamdata: {
        title: "Membros",
        item1: {
            name: "Remus John Worm",
            desc: "Mestre em Economia, Escola de Economia da Universidade de Chicago"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Mestre em Ciências e Mestre em Administração de Empresas, Universidade de Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Graduado pela Universidade de Pequim, Mestre em Gestão"
        }
    },
    article: {
        title: "Ao reunir o poder dos investidores de varejo, o Knight Consortium está empenhado em confrontar as instituições e lutar por mais direitos para os investidores comuns.",
        desc: "No mercado de ações existem dois tipos de players, um são os investidores de varejo (pessoas físicas) e o outro são os investidores institucionais (casas de apostas). O poder financeiro institucional sempre oprimiu o espaço de vida dos pequenos investidores, monopolizando e manipulando o mercado de ações. A maioria dos investidores de varejo ficou prejudicada com isso há muito tempo e iniciou uma batalha épica entre investidores de varejo e vendedores a descoberto de Wall Street - o incidente \"GameStop Bloodbath em Wall Street\".Uma vez unido o poder dos investidores de varejo, eles são a força mais poderosa e têm domínio inquestionável sobre a tendência dos preços das ações."
    },
    certdata: {
        title:"Certificados de fundação nacional",
        item1: {
            title: "",
            desc: "EUA",
        },
        item2: {
            title: "",
            desc: "Cingapura",
        },
        item3: {
            title: "",
            desc: "REINO UNIDO.",
        },
    },
    purpose: {
        title:"Fundação Missão dos Cavaleiros",
        btntitle:"Baixar PDF",
        image: require('@/assets/images/pdf/pdf_pt.png'),
        downlink:"/pt.pdf",
    }
}


export const digitalFour = {
    digita: {
        title:"Atividades de caridade da Fundação Knights"
    }
};


export const BlogDinner = {
    dinnerGrid: {
        title:"Membros do consórcio Knight têm um jantar offline"
    }
};


export const homeNotice = {
    notice:{
        title:"Anúncio especial do consórcio Knights",
        desc:""
    }
}


export const homeDinner = {
    title: "O jantar do Knights Consortium Buenos Aires foi um sucesso total",
    desc: "Este jantar não é apenas um grande evento de união, partilha e olhar para o futuro, mas também um momento importante para os membros do Consórcio Knight se reunirem para testemunhar a gloriosa história do consórcio. No futuro, esperamos continuar a trabalhar de mãos dadas com todos para acolher tempos mais bonitos e criar resultados mais brilhantes.",
}

export const BlogGalaDinner = {
    dinnerGrid: {
        title:"Jantar do Consórcio de Cavaleiros em Buenos Aires"
    }
};