export const head = {
    home: "首页",
    team: "成员简介",
    case: "成功案例",
    industry: "投资行业",
    contact: "联系我们",
    about: "关于我们"
};
  
export const foot = {
    services: {
        title: "服务",
        home: "服务中心"
    },
    case: {
        title: "成功案例",
        case: "成功案例"
    },
    company: {
        title: "公司介绍",
        contact: "联系我们",
        about: "关于我们"
    },
    resources: {
        title: "公司资源",
        industry: "投资行业"
    },
    stay: {
        title: "与我们一起",
        desc: "世界各地有 2000+ 客户订阅我们"
    },
    copyright: {
        contact: "联系我们"
    }
};

export const home = {
    banner22: {
        title: "热烈祝贺",
        desc: "布宜诺斯艾利斯晚宴圆满成功。"
    },
    banner1: {
        title: "骑士财团",
        desc: "从散户到庄家，骑士财团引领者"
    },
    banner2: {
        title: "我们承诺",
        desc: "金融领域的公平竞争"
    },
    banner3: {
        title: "君子爱财，取之有道",
        desc: "骑士财团愿将这条康庄大道指引给更多人发现，让所有人都能实现初步小富小康，进而成就自我！"
    },
    servicefive: {
        title: "进步，发展，关怀是骑士财团的核心价值",
        // desc: "我们致力于推动全球经济关系和经济发展，通过整合全球知识、技术、资金与人才，成立海外服务团队并提供资金，以帮助全球农业，工业，经建，医疗，教育匮乏地区享有同样的发展机遇，齐心创造平等，尊严，繁荣，健康的未来愿景。",
        desc: "",
        item1: {
            title: "公司简介",
            // desc: "骑士财团2010年成立于新加坡，初始成立资金约500万美金，如今管理资金规模达到10亿美元。",
            desc: "KNight Consortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.We are committed to promoting global economic relations and economic development, and establishing overseas service teams by integrating global knowledge, technology, funds and talents."
        },
        item2: {
            title: "公司文化",
            desc: "骑士财团的创立宗旨是汇聚爱心，传递温暖，帮助人们参与到慈善事业中，共同分享美好生活和发展机会。增进全球各地之间的联系，并向遭受自然灾害的国家和地区的难民救助。",
        },
        item3: {
            title: "公司愿景",
            desc: "与国际合作发展事务目标及重要内涵，永续发展，为机构愿景。依此方向，我们与国际社会，公民社会等开发援助，积极整合知识，技术，资金与人才，齐心创造平等，尊严，繁荣，健康的未来。",
        }
    },
    portfolio: {
        title: "骑士财团投资行业和成功案例",
        desc: "全力增进全球间经济社会发展以及经济关系，成立海外服务工作团以协助全球的医疗，农业，工业，经建，教育等之发展。",
        cat1: {
            name: "金融",
            item1: {
                title: "2012年1月至2月期间国际金价震荡，骑士财团金融分析师把握时机低位买入，在短短的3个月内从中获利将近60%。",
            },
            item2: {
                title: "骑士财团的国际投资团队还购买了一些贵金属开采公司的股票。这些公司在2012年期间表现良好，因为贵金属价格上涨导致这些公司的股价上涨，为骑士财团带来丰厚收益。",
            }
        },
        cat2: {
            name: "医疗",
            item1: {
                title: "2020年，骑士财团医疗服务中心成立，致力于研发创新药物以改善人类健康。",
            },
            item2: {
                title: "骑士财团医疗服务中心携手诺华制药，研发出了癌症靶向药Houng-592",
            }
        },
        cat3: {
            name: "元宇宙",
            item1: {
                title: "2021成立骑士财团web3.0中心，创建了去中心化社交平台，吸引了广泛的关注，并一直深受用户好评。",
            },
            item2: {
                title: "2017年1月加密币牛市，骑士财团抓住机会，带领基金会成员取得高额收益，所有成员资产快速翻番",
            }
        }
    },
    counterdata: {
        title: "骑士财团现状及未来规划",
        desc: "",
        item1: {
            title: '管理资金',
            unit: "亿",
        },
        item2: {
            title: '用户数',
            unit: "亿",
        },
        item3: {
            title: '全球排名',
            unit: "强",
        },
        item4: {
            title: 'Funding reaches US$1 billion',
            unit: "亿",
        }
      },
    leader: {
        title: "骑士财团联盟成员简介",
        desc: "财团资本的成员一起投资，做公益，并实现了财富自由以及为美好世界做出贡献。",
        item1: {
            name: "张国富",
            desc: "英国牛津大学金融经济学，获得硕士学位。2010年8月创立骑士财团资本基金会，并担任财团资本的主席"
        },
        item2: {
            name: "莱姆斯·约翰·沃姆",
            desc: "芝加哥大学经济学院经济硕士。骑士财团资本基金会担任副主席"
        },
        item3: {
            name: "李荣成",
            desc: "渥太华大学科学硕士及工商管理硕士学位。骑士财团资本基金会担任副主席。"
        }
    },
    blogpost: {
      title: "骑士财团社会公益",
      desc: "财富必将带来流动的希望和爱，骑士财团效仿国外金融大亨，将基金会一部分收入全部用于慈善事业，救济围困，帮助发展，使得骑士财团赢得国际好评，赢得更多用户和投资者的信赖。",
      item1: {
        //   title: '2023年2月份土耳其7.8级地震，骑士财团向难民捐赠230万美元'
        title: '捐赠物资是我们对灾民们最直接、最实际的帮助。2023年2月份土耳其7.8级地震，并且骑士财团向难民捐赠230万美元用于重建家园。',
      },
      item2: {
        //   title: '2021年7月中国河南省郑州暴雨引发洪灾，骑士财团捐赠180万美金；'
        title: '2022年9月，骑士财团慈善团队前往非洲，为贫困儿童提供关爱和帮助，确保儿童的基本生活需求得到满足，点对点的资助方式能够更直接地改善儿童的生活，并给予他们希望和鼓励。',
      },
      item3: {
        //   title: '2019澳大利亚森林大火，财团资本捐赠120万美金用于救援物资和恢复。'
        title: '2023年2月份土耳其7.8级地震，许多地区面临着严重的破坏和人员伤亡，骑士财团派救援队，拯救受难群众，给予他们温暖和希望，让他们感受到社会大家庭的温情和关怀。',
      },
      item4: {
        title: '巴以战争中平民得不到任何保护，骑士财团确保人民获得基本的救助，积极提供重要的援助工作者。',
      },
      item5: {
        title: '巴以战争中，补给线的开放，人道主义援助是关键而紧迫的需求。骑士财团抓紧窗口期运送对加沙民众福祉至关重要的援助物资，并与国际人道主义组织协调，确保援助物资抵达。',
      },
      item6: {
        title: '随着巴以战争的持续，伤亡人数的不断增加。受害人民的救援迫在眉睫，骑士财团积极组织救援团队进行第一时间的救助。',
      },
      item7: {
        title: '骑士财团一直以来都以其专业援助著称。我们拥有经验丰富的团队，能够迅速响应并提供切实可行的解决方案。我们都以高效和透明的方式运作，确保每一分帮助都被合理而有效地利用。',
      },
      item8: {
        title: '选择骑士财团的专业援助，意味着您选择了可靠的合作伙伴，一个致力于改善生活、重建希望的团队。我们将不遗余力地为受灾者提供所需的支持，助力他们度过难关，迈向更好的未来。',
      },
      item9: {
        title: '在战争的阴霾中，战后重建是我们必须迈出的关键一步。重建社区，修复受损建筑，帮助受灾者重新融入社会，这些任务都需要专业的援助和坚定的支持。',
      },
      item10: {
        title: '骑士财团第一时间救援，为巴以战争受灾者提供援助之光。在巴以战争的挑战面前，我们骑士财团坚定地站在受灾者身边。作为一个致力于金融公平和社会责任的团队，我们始终认为，关怀和援助是我们的责任之一。',
      },
      item11: {
        title: '在巴以战争中，我们深知受灾者面临的挑战和困难。我们已经采取了紧急行动，向受灾地区提供急需的物资援助。这些物资包括食品、水、医疗用品和紧急救援设备，旨在为受灾者提供急需的帮助和支持。',
      },
      item12: {
        title: '骑士财团一直以来都致力于支持战后振兴。重建社区和帮助受灾者是至关重要的。因此，我们毫不犹豫地伸出援手，以确保那些受到战争影响的人们能够重新建立自己的生活。',
      },
      item13: {
        title: '在战乱的阴霾下，我们携手助力，为受灾者送去援手。骑士财团一直秉持着社会责任感，致力于帮助那些在战争中受到影响的人们。一起为战争受害者点亮生活的希望之光！',
      },
      item14: {
        title: '在战后的艰难时刻，骑士财团愿成为您可靠的伙伴，共同投身于救助与重建的使命中。我们深知每一位受灾者都需要关怀和援助，因此，我们积极参与各类救助行动，为那些饱受苦难的人们提供必要的支持。',
      },
      item15: {
        title: '骑士财团一直致力于传播这一精神，通过慈善行动，援助项目，以及志愿者工作，我们努力改善生活，支持社区，让世界变得更加温暖。只有共同努力，我们才能创造一个更美好、更有希望的未来。',
      },
      item16: {
        title: '阿根廷遭遇了超强暴风袭击，导致16位无辜生命的失落。骑士财团深感痛心，第一时间响应，我们的专业团队立即参与到救灾工作中。在这场紧急情况下，我们坚守承诺，全力以赴为灾区提供支持。',
      },
      item17: {
        title: '我们得知中国甘肃发生了6.2级地震，这场灾难给当地居民带来了巨大的困扰。在这个艰难时刻，骑士财团站在灾区人民的一边，致以最深切的慰问和支持。',
      },
      item18: {
        title: '在人生旅途中，有些时刻需要更多的关心和支持。骑士财团以社会责任为己任，我们坚信帮助那些需要救援的人，是建设更美好社会的重要一环，而我们会一直在您身边。',
      },
      item19: {
        title: '骑士财团又要出发了！看着地上整齐排列的牛奶、油、面、饼干等食物，他们即将送往当地的一户困难家庭。看着他们开心的样子，我们知道这些物资将带来多少温暖和希望。一份爱心，一份温暖，骑士财团用行动诠释着社会责任，让我们共同期待这次的送达带来的感动吧',
      },
      item20: {
        title: '近日，我们见证了骑士财团成员们的庞大队伍，他们充满活力和激情，带着满满的食物，前往当地的社区，看望妇女、老人和儿童们。这个团队的每个成员都散发着无限的热情和关爱，他们的出现为社区注入了一股暖流。在活动中，我们看到了年轻男女们与老人、儿童们亲切交流的场景，他们的笑容洋溢着快乐和感动。无论是分发食物还是聊天，每个人都投入其中，用心带给他人温暖与关怀。这支充满活力的骑士财团队伍，展现了团结友爱的精神，为社区注入了一份浓厚的爱。',
      },
      item21: {
        title: '近日，骑士财团的一组成员进行了一次感人至深的看望活动。他们前往当地，看望了妇女、老人和儿童们，带去了温暖和关怀。这次活动不仅是一次简单的看望，更是对社区的真挚关爱和支持的体现。在活动中，骑士财团的成员们与受访者亲切交谈，分享彼此的故事和经历。老人们面露微笑，孩子们欢声笑语，妇女们感激不尽。他们用心倾听，用爱心陪伴，让每个受访者都感受到了来自社区的温暖和关怀。',
      },
      item22: {
        title: '骑士财团San Juan团队在阳光明媚的日子里，携着满满的关爱，走进当地困难家庭。团队成员们分发了大米、食用油、空心粉、罐头、面包、饼干等生活必需品，与每个家庭进行了亲切的慰问和交谈。这一次的物资发放不仅是对生活所需的实际支持，更是对每个家庭的心灵抚慰。骑士志愿者们与被帮助的家庭分享着温馨时光，传递着彼此之间的关怀与希望。被帮助的家庭纷纷表达对骑士志愿者们的感激之情，这份温暖让他们对未来充满了信心与期待。志愿者们离开时，家庭们纷纷送上真挚的祝福，感谢骑士财团San Juan团队为他们带来的关爱和温暖。',
      },
      item23: {
        title: '骑士财团成员向当地数家贫困家庭伸出援手，为他们送去了丰富的生活必需品！ 在过去的一次活动中，骑士财团的热心志愿者们携带着食用油、面、小麦、粉等丰富的物资前往当地，将这些生活必需品送到了数家贫困家庭。这不仅是一场简单的捐赠，更是一次真挚的慰问和交流。被帮助的家庭在收到这份温暖的同时，表达出了对骑士志愿者们的深深感激。生活中的困境似乎因为这份关爱而变得温暖，充满了希望与期待。',
      },
      item24: {
        title: '骑士财团的志愿者们带着面，油，牛奶，糖等生活物资，踏上了一次感人至深的慈善之旅。他们深入山区，为那些生活在山坡上、平时交通不便的困难家庭送去了实实在在的帮助。这次的物资分发涵盖了家庭日常生活的方方面面，包括食用油、面粉、牛奶和糖等，确保每个家庭都能感受到骑士财团的温暖关怀。志愿者们不仅是物资的搬运工，更是陪伴、慰问和交流的传递者。被帮助的家庭位于偏远山区，平时面临交通不便、生活困难的挑战。因此，骑士志愿者们的到来对他们来说是一次及时雨，充满了感激之情。大家在交谈中分享着彼此的故事，让这次的慈善活动更显温馨和有意义。',
      }
      ,
      item25: {
        title: '近日，阳光灿烂，骑士财团的成员们带着一桶桶食用油和果汁，来到当地效外，伸出援手，给那些需要支持和帮助的人们送去温暖和关怀。在这美好的一天里，我们不仅是骑士财团的成员，更是社区的一份子。我们深知身为一支团队，责任和使命是我们肩上的担子。因此，我们愿意付出努力，用行动诠释着对社区的关爱和支持。让我们一起将爱心传递，让这个世界充满温暖和希望。感谢您的支持，让我们继续携手前行，为社区的发展和进步贡献一份力量！',
      },
      item26: {
        title: '近日，我们骑士财团的团队来到了阿根廷科尔多瓦，这个美丽的地方充满了温暖和希望。我们近30名成员组成的分队汇聚在这里，怀着一颗热忱的心，带着您的爱和支持，我们将帮助更多有需要的人，给他们带去温暖和关怀。在这个充满爱的行动中，我们不仅是骑士财团的一员，更是您的代言人和使者。因为您的慷慨和爱心，我们能够走到这里，给更多人带来感激之情。让我们携手并肩，共同为社会贡献一份力量，让世界充满更多的温暖和希望。感谢您的支持，让我们继续传递爱的力量！',
      }
    }
}



export const team = {
    leader1: {
        title: "Chairman",
        name: "张国富",
        desc: "英国牛津大学金融经济学，获得硕士学位。2010年8月创立骑士财团资本基金会，并担任财团资本的主席"
    },
    leader2: {
        title: "Vice Chairman and Legal Director",
        item1: {
            name: "莱姆斯·约翰·沃姆",
            desc: "芝加哥大学经济学院经济硕士。骑士财团资本基金会担任副主席"
        },
        item2: {
            name: "李荣成",
            desc: "渥太华大学科学硕士及工商管理硕士学位。骑士财团资本基金会担任副主席。"
        },
        item3: {
            name: "刘彩君",
            desc: "北京大学管理硕士毕业"
        }
    },
    leader3: {
        title: "金融分析师",
        item1: {
            name: "麦克斯韦",
            desc: "复旦大学国际金融学士"
        },
        item2: {
            name: "杨军",
            desc: "毕业于新加坡南洋理工大学，金融专业"
        },
        item3: {
            name: "休伯特",
            desc: "英国伦敦大学，美国哈佛大学双学位"
        }
    },
    leader4: {
        title: "顾问",
        item1: {
            name: "安菲亚",
            desc: "佛罗里达大学学士"
        },
        item2: {
            name: "许浩",
            desc: "乔治华盛顿大学工程学位"
        },
        item3: {
            name: "多萝西",
            desc: "印度理工学院工商管理硕士学位"
        }
    },
    leader5: {
        title: "核心成员",
        item1: {
            name: "孫馬",
            desc: "英国伦敦大学皇家霍洛威学院攻读经济学"
        },
        item2: {
            name: "伊冯娜",
            desc: "布鲁塞尔自由大学硕士学位"
        },
        item3: {
            name: "严涛",
            desc: "华盛顿大学硕士（工程管理学位）"
        }
    }
}

export const casepage = {
    title: "社会公益介绍",
    item1: {
        title: "2023年2月份土耳其7.8级地震，骑士财团向难民捐赠230万美元"
    },
    item2: {
        title: "2021年7月中国河南省郑州暴雨引发洪灾，骑士财团捐赠180万美金；"
    },
    item3: {
        title: "2019澳大利亚森林大火，财团资本捐赠120万美金用于救援物资和恢复。"
    },
    item4: {
        title: "2022年6月阿富汗地震造成死亡1000余人，2000余人受伤，财团资本捐赠100万美元用于灾后房屋重建，部分用于生活物资。",
    },
    item5: {
        title: "2023年2月份土耳其发生7.8级地震，造成死亡人数已超过4.1万人，南部地区有150万人无家可归，需要建造至少50万所新房屋。在叙利亚西北部，多达900万人受到影响，至少6000人丧生，财团资本向难民捐赠230万美元用于提供生活物资以及重建家园。",
    },
    item6: {
        title: "捐赠物资是我们对灾民们最直接、最实际的帮助。2023年2月份土耳其7.8级地震，并且骑士财团向难民捐赠230万美元用于重建家园。",
    },
    item7: {
        title: "2023年2月份土耳其7.8级地震，许多地区面临着严重的破坏和人员伤亡，骑士财团派救援队，拯救受难群众，给予他们温暖和希望，让他们感受到社会大家庭的温情和关怀。",
    },
    item8: {
        title: "2022年6月阿富汗地震造成死亡1000余人，2000余人受伤，财团资本捐赠100万美元用于灾后房屋重建，部分用于生活。",
    },
    item9: {
        title: "骑士财团积极参与到关爱贫困儿童的慈善行动中。通过捐款和志愿服务等方式，定期给予经济援助和关怀。这种关爱和帮助不仅改变了儿童的命运，也为整个社会的发展注入了更多正能量。",
    },
    item10: {
        title: "2022年9月，骑士财团慈善团队前往非洲，为贫困儿童提供关爱和帮助，确保儿童的基本生活需求得到满足，点对点的资助方式能够更直接地改善儿童的生活，并给予他们希望和鼓励。",
    },
    item11: {
        title: "巴以战争中平民得不到任何保护，骑士财团确保人民获得基本的救助，积极提供重要的援助工作者。",
    },
    item12: {
        title: "巴以战争中，补给线的开放，人道主义援助是关键而紧迫的需求。骑士财团抓紧窗口期运送对加沙民众福祉至关重要的援助物资，并与国际人道主义组织协调，确保援助物资抵达。",
    },
    item13: {
        title: "随着巴以战争的持续，伤亡人数的不断增加。受害人民的救援迫在眉睫，骑士财团积极组织救援团队进行第一时间的救助。",
    },
    item14: {
        title: "2023年7月，受第5号台风“杜苏芮”影响，中国京津冀等地出现极端强降雨，并引发洪水灾害。骑士财团展开紧急救援，为受灾群众提供更畅通及时的救助通道，帮助老年群体和困境人群脱离困境。",
    },
    item15: {
        title: '骑士财团一直以来都以其专业援助著称。我们拥有经验丰富的团队，能够迅速响应并提供切实可行的解决方案。我们都以高效和透明的方式运作，确保每一分帮助都被合理而有效地利用。',
    },
    item16: {
        title: '选择骑士财团的专业援助，意味着您选择了可靠的合作伙伴，一个致力于改善生活、重建希望的团队。我们将不遗余力地为受灾者提供所需的支持，助力他们度过难关，迈向更好的未来。',
    },
    item17: {
        title: '在战争的阴霾中，战后重建是我们必须迈出的关键一步。重建社区，修复受损建筑，帮助受灾者重新融入社会，这些任务都需要专业的援助和坚定的支持。',
    },
    item18: {
        title: '骑士财团第一时间救援，为巴以战争受灾者提供援助之光。在巴以战争的挑战面前，我们骑士财团坚定地站在受灾者身边。作为一个致力于金融公平和社会责任的团队，我们始终认为，关怀和援助是我们的责任之一。',
    },
    item19: {
        title: '在巴以战争中，我们深知受灾者面临的挑战和困难。我们已经采取了紧急行动，向受灾地区提供急需的物资援助。这些物资包括食品、水、医疗用品和紧急救援设备，旨在为受灾者提供急需的帮助和支持。',
    },
    item20: {
        title: '骑士财团一直以来都致力于支持战后振兴。重建社区和帮助受灾者是至关重要的。因此，我们毫不犹豫地伸出援手，以确保那些受到战争影响的人们能够重新建立自己的生活。',
    },
    item21: {
        title: '在战乱的阴霾下，我们携手助力，为受灾者送去援手。骑士财团一直秉持着社会责任感，致力于帮助那些在战争中受到影响的人们。一起为战争受害者点亮生活的希望之光！',
    },
    item22: {
        title: '在战后的艰难时刻，骑士财团愿成为您可靠的伙伴，共同投身于救助与重建的使命中。我们深知每一位受灾者都需要关怀和援助，因此，我们积极参与各类救助行动，为那些饱受苦难的人们提供必要的支持。',
    },
    item23: {
        title: '骑士财团一直致力于传播这一精神，通过慈善行动，援助项目，以及志愿者工作，我们努力改善生活，支持社区，让世界变得更加温暖。只有共同努力，我们才能创造一个更美好、更有希望的未来。',
    },
    item24: {
        title: '阿根廷遭遇了超强暴风袭击，导致16位无辜生命的失落。骑士财团深感痛心，第一时间响应，我们的专业团队立即参与到救灾工作中。在这场紧急情况下，我们坚守承诺，全力以赴为灾区提供支持。',
    },
    item25: {
        title: '我们得知中国甘肃发生了6.2级地震，这场灾难给当地居民带来了巨大的困扰。在这个艰难时刻，骑士财团站在灾区人民的一边，致以最深切的慰问和支持。',
    },
    item26: {
        title: '在人生旅途中，有些时刻需要更多的关心和支持。骑士财团以社会责任为己任，我们坚信帮助那些需要救援的人，是建设更美好社会的重要一环，而我们会一直在您身边。',
    },
    item27: {
        title: '骑士财团又要出发了！看着地上整齐排列的牛奶、油、面、饼干等食物，他们即将送往当地的一户困难家庭。看着他们开心的样子，我们知道这些物资将带来多少温暖和希望。一份爱心，一份温暖，骑士财团用行动诠释着社会责任，让我们共同期待这次的送达带来的感动吧',
    },
    item28: {
        title: '近日，我们见证了骑士财团成员们的庞大队伍，他们充满活力和激情，带着满满的食物，前往当地的社区，看望妇女、老人和儿童们。这个团队的每个成员都散发着无限的热情和关爱，他们的出现为社区注入了一股暖流。在活动中，我们看到了年轻男女们与老人、儿童们亲切交流的场景，他们的笑容洋溢着快乐和感动。无论是分发食物还是聊天，每个人都投入其中，用心带给他人温暖与关怀。这支充满活力的骑士财团队伍，展现了团结友爱的精神，为社区注入了一份浓厚的爱。',
    },
    item29: {
        title: '近日，骑士财团的一组成员进行了一次感人至深的看望活动。他们前往当地，看望了妇女、老人和儿童们，带去了温暖和关怀。这次活动不仅是一次简单的看望，更是对社区的真挚关爱和支持的体现。在活动中，骑士财团的成员们与受访者亲切交谈，分享彼此的故事和经历。老人们面露微笑，孩子们欢声笑语，妇女们感激不尽。他们用心倾听，用爱心陪伴，让每个受访者都感受到了来自社区的温暖和关怀。',
    },
    item30: {
        title: '骑士财团San Juan团队在阳光明媚的日子里，携着满满的关爱，走进当地困难家庭。团队成员们分发了大米、食用油、空心粉、罐头、面包、饼干等生活必需品，与每个家庭进行了亲切的慰问和交谈。这一次的物资发放不仅是对生活所需的实际支持，更是对每个家庭的心灵抚慰。骑士志愿者们与被帮助的家庭分享着温馨时光，传递着彼此之间的关怀与希望。被帮助的家庭纷纷表达对骑士志愿者们的感激之情，这份温暖让他们对未来充满了信心与期待。志愿者们离开时，家庭们纷纷送上真挚的祝福，感谢骑士财团San Juan团队为他们带来的关爱和温暖。',
    },
    item31: {
        title: '骑士财团成员向当地数家贫困家庭伸出援手，为他们送去了丰富的生活必需品！ 在过去的一次活动中，骑士财团的热心志愿者们携带着食用油、面、小麦、粉等丰富的物资前往当地，将这些生活必需品送到了数家贫困家庭。这不仅是一场简单的捐赠，更是一次真挚的慰问和交流。被帮助的家庭在收到这份温暖的同时，表达出了对骑士志愿者们的深深感激。生活中的困境似乎因为这份关爱而变得温暖，充满了希望与期待。',
    },
    item32: {
        title: '骑士财团的志愿者们带着面，油，牛奶，糖等生活物资，踏上了一次感人至深的慈善之旅。他们深入山区，为那些生活在山坡上、平时交通不便的困难家庭送去了实实在在的帮助。这次的物资分发涵盖了家庭日常生活的方方面面，包括食用油、面粉、牛奶和糖等，确保每个家庭都能感受到骑士财团的温暖关怀。志愿者们不仅是物资的搬运工，更是陪伴、慰问和交流的传递者。被帮助的家庭位于偏远山区，平时面临交通不便、生活困难的挑战。因此，骑士志愿者们的到来对他们来说是一次及时雨，充满了感激之情。大家在交谈中分享着彼此的故事，让这次的慈善活动更显温馨和有意义。',
    },
    item33: {
      title: '近日，阳光灿烂，骑士财团的成员们带着一桶桶食用油和果汁，来到当地效外，伸出援手，给那些需要支持和帮助的人们送去温暖和关怀。在这美好的一天里，我们不仅是骑士财团的成员，更是社区的一份子。我们深知身为一支团队，责任和使命是我们肩上的担子。因此，我们愿意付出努力，用行动诠释着对社区的关爱和支持。让我们一起将爱心传递，让这个世界充满温暖和希望。感谢您的支持，让我们继续携手前行，为社区的发展和进步贡献一份力量！',
    },
    item34: {
      title: '近日，我们骑士财团的团队来到了阿根廷科尔多瓦，这个美丽的地方充满了温暖和希望。我们近30名成员组成的分队汇聚在这里，怀着一颗热忱的心，带着您的爱和支持，我们将帮助更多有需要的人，给他们带去温暖和关怀。在这个充满爱的行动中，我们不仅是骑士财团的一员，更是您的代言人和使者。因为您的慷慨和爱心，我们能够走到这里，给更多人带来感激之情。让我们携手并肩，共同为社会贡献一份力量，让世界充满更多的温暖和希望。感谢您的支持，让我们继续传递爱的力量！',
    },
    item35: {
      title: '骑士财团成员线下聚餐，共享美洲杯和欧洲杯总决赛盛宴！我们不仅在餐桌上享受美食，还在欢声笑语中分享对比赛的热情与期待。无论是支持哪支球队，这一刻，我们都是足球的忠实粉丝，为心中的冠军呐喊助威。美洲杯和欧洲杯的总决赛不仅是足球的盛宴，更是骑士财团成员们团结与友谊的见证。',
    },
    item36: {
      title: '我们在这个特别的时刻相聚，不仅是为了庆祝足球的魅力，更是为了加强彼此的联系与合作。每一场比赛都是一次团队的凝聚，每一个进球都是我们共同的欢呼！感谢所有参与这次聚餐活动的骑士财团成员，你们的热情和活力为这个夜晚增添了无尽的精彩。让我们继续携手前行，共同迎接更多的胜利与荣耀！',
    },
}

export const industry = {
    title: "投资行业",
    cat1: {
        name: "金融",
        item1: {
            title: "2012年1月至2月期间国际金价震荡，骑士财团金融分析师把握时机低位买入，在短短的3个月内从中获利将近60%。",
        },
        item2: {
            title: "骑士财团的国际投资团队还购买了一些贵金属开采公司的股票。这些公司在2012年期间表现良好，因为贵金属价格上涨导致这些公司的股价上涨，为骑士财团带来丰厚收益。",
        }
    },
    cat2: {
        name: "医疗",
        item1: {
            title: "2020年，骑士财团医疗服务中心成立，致力于研发创新药物以改善人类健康。",
        },
        item2: {
            title: "骑士财团医疗服务中心携手诺华制药，研发出了癌症靶向药Houng-592",
        }
    },
    cat3: {
        name: "元宇宙",
        item1: {
            title: "2021成立骑士财团web3.0中心，创建了去中心化社交平台，吸引了广泛的关注，并一直深受用户好评。",
        },
        item2: {
            title: "2017年1月加密币牛市，骑士财团抓住机会，带领基金会成员取得高额收益，所有成员资产快速翻番",
        }
    }
}


export const about = {
    title: "骑士财团联合散户力量，实现财富自由的梦想",
    desc: "君子爱财，取之有道。骑士财团愿将这条康庄大道指引给更多人发现，让所有人都能实现初步小富小康，进而成就自我！帮助个人投资摆脱被机构收割的命运，并通过专业可靠的资本运作获取盈利，从而帮助团队成员实现财富自由的梦想。",
    service: {
        title: "我们财团资本的目标",
        item1: {
            title: "",
            desc: "全力增进全球间经济社会发展以及经济关系。"
        },
        item2: {
            title: "",
            desc: "2.增进慈善事业的进一步发展，去改善民生，给予国际难民遭受天然灾害的国家救助，以实际的行动去帮助困难群体走出困境，让他们过上幸福与和平的生活。"
        },
        item3: {
            title: "",
            desc: "成立海外服务工作团以协助全球的农业，工业，经建，医疗，教育等之发展。"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "重心将南美洲地区作为骑士财团资本基金会帮扶救济重点地区，预计至少投入资金5000万美金以上用于民间援助。在三年内将会在南美洲地区成立1000家骑士财团资本基金会线下慈善办事处和5000个就业机会。"
        },
        item2: {
            title: "2025",
            desc: "骑士财团资本基金会投资所得以及捐赠资金金额年达到1亿美金。"
        },
        item3: {
            title: "2026",
            desc: "实现全球5000家骑士财团资本基金会线下慈善办事处和20000个以上就业机会"
        },
        item4: {
            title: "2027",
            desc: "骑士财团资本基金会投资所得以及捐赠资金金额预计达到3亿美金以上，通过农业技术扶持和基础设置建设，帮助全球2亿人口以上脱离国际贫困线，让他们得到健康，幸福与快乐"
        }
    },
    teamdata: {
        title: "无匹配",
        item1: {
            name: "莱姆斯·约翰·沃姆",
            desc: "芝加哥大学经济学院经济硕士。骑士财团资本基金会担任副主席"
        },
        item2: {
            name: "李荣成",
            desc: "渥太华大学科学硕士及工商管理硕士学位。骑士财团资本基金会担任副主席。"
        },
        item3: {
            name: "刘彩君",
            desc: "北京大学管理硕士毕业"
        }
    },
    article: {
        title: "骑士财团通过汇聚广在散户的力量，致力于与机构对抗，为普通投资者争取更多权益的存在。",
        desc: "在股市中，有两类玩家，一种是散户投资者（个人），另一种是机构投资者（庄家），一直以来，机构资金力量都在挤压散户的生存空间，垄断、操纵股票市场。广大散户对此愤愤不平已久，开启场散户与华尔街做空机构的史诗级斗争——“游戏驿站血洗华尔街”事件。散户的力量一旦团结起来，就是最强大的力量，对股价的走势拥有毫无疑问的主导权。"
    },
    certdata: {
        title:"各国基金会证书",
        item1: {
            title: "",
            desc: "美国",
        },
        item2: {
            title: "",
            desc: "新加坡",
        },
        item3: {
            title: "",
            desc: "英国",
        },
    },
    purpose: {
        title:"骑士财团宗旨",
        btntitle:"PDF 下载",
        image: require('@/assets/images/pdf/pdf_cn.png'),
        downlink:"/cn.pdf",
    }
}


export const digitalFour = {
    digita: {
        title:"骑士财团的慈善活动"
    }
};

export const BlogDinner = {
    dinnerGrid: {
        title:"骑士财团成员线下聚餐"
    }
};

export const homeNotice = {
    notice:{
        title:"骑士财团特别通告",
        desc:""
    }
}

export const homeDinner = {
    title: "骑士财团布宜诺斯艾利斯晚宴圆满成功",
    desc: "本次晚宴不仅是一次团结、分享与展望未来的盛会，更是骑士财团成员齐聚一堂，共同见证财团辉煌历程的重要时刻。未来的道路上，我们期待着与大家继续携手并肩，迎接更多美好的时光，创造更加辉煌的成果。",
}

export const BlogGalaDinner = {
    dinnerGrid: {
        title:"骑士财团布宜诺斯艾利斯晚宴"
    }
};