<template>
    <div class="rn-card box-card-style-default"
         :class="{'card-list-view': blogPostStyle === 'list'}">
        <div class="inner">
            <div class="thumbnail">
                <router-link class="image" :to="''">
                    <img class="w-100" :src="blog.img" :alt="blog.title">
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BlogDinnerPost',
        props: {
            blog: {},
            blogPostStyle: {
                type: String,
                default: 'card'
            }
        }
    }
</script>