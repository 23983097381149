// 德语
export const head = {
  home: "Heim",
  team: "Team",
  case: "Fall",
  industry: "Industrie",
  contact: "Kontakt",
  about: "Um"
};
  
export const foot = {
  services: {
      title: "Dienstleistungen",
      home: "Heim"
  },
  case: {
      title: "Fall",
      case: "Fall"
  },
  company: {
      title: "Unternehmen",
      contact: "Kontakt",
      about: "Um"
  },
  resources: {
      title: "Ressourcen",
      industry: "Industrie"
  },
  stay: {
      title: "Bleib bei uns.",
      desc: "2000+ Unsere Kunden sind auf der ganzen Welt abonniert"
  },
  copyright: {
      contact: "Kontaktiere uns"
  }
};

export const home = {
  banner22: {
    title: "Herzliche Glückwünsche",
    desc: "Das Abendessen in Buenos Aires war ein voller Erfolg."
  }, 
  banner1: {
      title: "Knight-Konsortium",
      desc: "Vom Privatanleger bis zum Bankier, der Leiter des Knight-Konsortiums"
  },
  banner2: {
      title: "Unser Engagement",
      desc: "Fairer Wettbewerb im Finanzsektor"
  },
  banner3: {
      title: "Herren lieben das Glück, und zwar auf die richtige Art und Weise",
      desc: "Das Knight Consortium ist bereit, diesen breiten Weg zu mehr Entdeckungen für Menschen zu führen, damit jeder zunächst Wohlstand und Wohlstand erreichen und dann Selbstverwirklichung erreichen kann!"
  },
  servicefive: {
      title: "Fortschritt, Entwicklung und Fürsorge sind die Grundwerte des Knight-Konsortiums",
    //   desc: "We are committed to promoting global economic relations and economic development. By integrating global knowledge, technology, funds and talents, we have established overseas service teams and provided funds to help areas lacking in agriculture, industry, economic construction, medical care and education around the world enjoy the same development opportunities. , work together to create a future vision of equality, dignity, prosperity and health.",
      desc: "",
      item1: {
          title: "Unternehmensprofil",
        //   desc: "Knight-Konsortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.",
        desc: "Das Knight-Konsortium wurde 2010 in Singapur mit einem Anfangskapital von etwa 5 Millionen US-Dollar gegründet und verfügt nun über ein verwaltetes Kapital von 1 Milliarde US-Dollar. Wir engagieren uns für die Förderung globaler Wirtschaftsbeziehungen und wirtschaftlicher Entwicklung sowie für den Aufbau von Serviceteams im Ausland durch die globale Integration Wissen, Technologie, Gelder und Talente."
      },
      item2: {
          title: "Unternehmenskultur",
          desc: "Der Gründungszweck des Knight-Konsortiums besteht darin, Liebe zu sammeln, Wärme zu vermitteln, Menschen bei der Teilnahme an Wohltätigkeitsorganisationen zu unterstützen und gemeinsam ein besseres Leben und Entwicklungsmöglichkeiten zu teilen. Fördern Sie Verbindungen auf der ganzen Welt und leisten Sie Hilfe für Flüchtlinge in Ländern und Regionen, die von Naturkatastrophen betroffen sind.<br><br><br>",
      },
      item3: {
          title: "Unternehmensvision",
          desc: "Die Ziele und wichtigen Konnotationen der Entwicklung von Angelegenheiten mit internationaler Zusammenarbeit und nachhaltiger Entwicklung sind die Vision der Organisation. In dieser Richtung arbeiten wir mit der internationalen Gemeinschaft, der Zivilgesellschaft und anderen Entwicklungshelfern zusammen, um Wissen, Technologie, Gelder und Talente aktiv zu integrieren, um eine Zukunft der Gleichheit, Würde, Wohlstand und Gesundheit zu schaffen.",
      }
  },
  portfolio: {
      title: "Knight-Konsortium-Investmentbranche und erfolgreiche Fälle",
      desc: "Wir sind bestrebt, die globale wirtschaftliche und soziale Entwicklung sowie die Wirtschaftsbeziehungen zu fördern und Serviceteams im Ausland aufzubauen, um die Entwicklung globaler medizinischer, landwirtschaftlicher, industrieller, wirtschaftlicher Bau-, Bildungs- usw. zu unterstützen.",
      cat1: {
          name: "finance",
          item1: {
              title: "Das Investmentteam des Knight-Konsortiums kaufte Anteile an einer Reihe von Edelmetallbergbauunternehmen. Diese Unternehmen entwickelten sich im Jahr 2012 gut, da steigende Edelmetallpreise zu einem Anstieg ihrer Aktienkurse führten, was zu enormen Gewinnen für das Knights-Syndikat führte.",
          },
          item2: {
              title: "Im Zeitraum Januar bis Februar 2012 schwankte der internationale Goldpreis. Die Finanzanalysten des Knight-Konsortiums nutzten die Gelegenheit, zu günstigen Preisen einzukaufen und erzielten in nur drei Monaten einen Gewinn von fast 60 %.",
          }
      },
      cat2: {
          name: "medical",
          item1: {
              title: "Im Jahr 2020 wurde das Knight-Konsortium Medical Service Center gegründet und die gemeinsam mit der Merck Pharmaceutical Group entwickelte Behandlungsmethode „Tumorimmuntherapie“ war erfolgreich.",
          },
          item2: {
              title: "Das Knight-Konsortium Medical Services Center und Novartis Pharmaceuticals haben das krebsgerichtete Medikament Houng-592 entwickelt",
          }
      },
      cat3: {
          name: "metaverse",
          item1: {
              title: "Im Jahr 2021 wurde das Web3.0-Zentrum des Knight-Konsortiums gegründet, um eine dezentrale soziale Plattform zu schaffen, die große Aufmerksamkeit erregte und von den Nutzern gut angenommen wurde.",
          },
          item2: {
              title: "Im Bullenmarkt der Kryptowährungen im Januar 2017 nutzte das Knight-Konsortium die Chance und führte die Stiftungsmitglieder zu hohen Gewinnen, wodurch sich das Vermögen aller Mitglieder schnell verdoppelte.",
          }
      }
  },
  counterdata: {
    title: "Aktueller Stand und Zukunftspläne des Knight-Konsortiums",
    desc: "",
    item1: {
        title: 'Mittel',
        unit: "Milliarde",
    },
    item2: {
        title: 'Benutzer',
        unit: "Milliarde",
    },
    item3: {
        title: 'Investieren',
        unit: "Partner",
    },
    item4: {
        title: 'Spenden',
        unit: "Milliarde",
    }
  },
  leader: {
      title: "Vorstellung der Mitglieder der Knight-Konsortium Alliance",
      desc: "Mitglieder des Syndikatskapitals investieren gemeinsam, leisten Wohltätigkeit, erlangen finanzielle Freiheit und tragen zu einer besseren Welt bei.",
      item1: {
          name: "Zhang Guofu",
          desc: "Erlangte einen Master-Abschluss in Finanzökonomie von der University of Oxford, Großbritannien. Im August 2010 gründete er die Knight-Konsortium Capital Foundation und fungierte als Vorsitzender von Consortium Capital."
      },
      item2: {
          name: "Remus John Worm",
          desc: "Master of Economics an der University of Chicago School of Economics. War stellvertretender Vorsitzender der Knight Capital Foundation <br><br><br> "
      },
      item3: {
          name: "Li Rongcheng",
          desc: "Master of Science und Master of Business Administration von der University of Ottawa. War stellvertretender Vorsitzender der Knight Capital Foundation<br><br>"
      }
  },
  blogpost: {
    title: "Knight-Konsortium Sozialfürsorge",
    desc: "Reichtum wird definitiv fließende Hoffnung und Liebe mit sich bringen. Das Knight-Konsortium folgt dem Beispiel ausländischer Finanzmagnaten und verwendet einen Teil der Einnahmen der Stiftung für wohltätige Zwecke, um die Belagerung zu lindern und die Entwicklung zu unterstützen, wodurch das Knight-Konsortium internationale Anerkennung und das Vertrauen von mehr Nutzern und Investoren gewinnt.",
    item1: {
        // title: "After the 7.8-magnitude earthquake in Turkey in February 2023, the Knight-Konsortium donated US$2.3 million to refugees"
        title: 'Die Spende von Materialien ist unsere direkteste und praktischste Hilfe für die Opfer. Im Februar 2023 wurde die Türkei von einem Erdbeben der Stärke 7,8 heimgesucht und die Knights Foundation spendete 2,3 Millionen US-Dollar an Flüchtlinge, um ihre Häuser wieder aufzubauen.',
    },
    item2: {
        // title: "In July 2021, heavy rains caused floods in Zhengzhou, Henan Province, China. The Knight-Konsortium donated US$1.8 million;"
        title: 'Im September 2022 reiste das Wohltätigkeitsteam der Knight Foundation nach Afrika, um armen Kindern Pflege und Hilfe zu bieten und sicherzustellen, dass die Grundbedürfnisse der Kinder erfüllt werden. Die Peer-to-Peer-Finanzierungsmethode kann das Leben von Kindern direkter verbessern und ihnen Hoffnung und Ermutigung geben.',
    },
    item3: {
        // title: "During the 2019 Australian forest fires, Consortium Capital donated US$1.2 million for relief supplies and recovery."
        title: 'Im Februar 2023 wurde die Türkei von einem Erdbeben der Stärke 7,8 heimgesucht, in vielen Gebieten kam es zu schweren Schäden und Verlusten. Die Knight Foundation entsandte Rettungsteams, um die Opfer zu retten, ihnen Wärme und Hoffnung zu geben und sie die Wärme und Fürsorge der sozialen Familie spüren zu lassen.',
    },
    item4: {
      title: 'Zivilisten erhielten während des israelisch-palästinensischen Krieges keinerlei Schutz. Das Ritterkonsortium sorgte für die Grundversorgung der Menschen und stellte aktiv wichtige Hilfskräfte zur Verfügung.',
    },
    item5: {
      title: 'Im palästinensisch-israelischen Krieg sind die Öffnung der Versorgungslinien und humanitäre Hilfe von entscheidender Bedeutung und dringend erforderlich. Das Knights Consortium nutzt die Zeitspanne, um Hilfsgüter zu liefern, die für das Wohlergehen der Menschen im Gazastreifen von entscheidender Bedeutung sind, und stimmt sich mit internationalen humanitären Organisationen ab, um die Ankunft der Hilfsgüter sicherzustellen.',
    },
    item6: {
      title: 'Während der israelisch-palästinensische Krieg andauert, steigt die Zahl der Opfer weiter an. Die Rettung der Opfer steht unmittelbar bevor und das Knight Consortium organisiert aktiv Rettungsteams, um eine sofortige Rettung zu gewährleisten.',
    },
    item7: {
        title: 'Die Knight Consortium ist seit jeher für ihre professionelle Unterstützung bekannt. Wir verfügen über ein erfahrenes Team, das schnell reagieren und praktische Lösungen anbieten kann. Wir alle arbeiten effizient und transparent, um sicherzustellen, dass jede noch so kleine Hilfe angemessen und effektiv eingesetzt wird.',
    },
    item8: {
        title: 'Wenn Sie sich für die professionelle Unterstützung des Knight Consortium entscheiden, haben Sie sich für einen zuverlässigen Partner entschieden, ein Team, das sich der Verbesserung von Leben und dem Wiederaufbau von Hoffnung widmet. Wir werden keine Mühen scheuen, um den Opfern die Unterstützung zukommen zu lassen, die sie benötigen, um ihnen zu helfen, die Schwierigkeiten zu überwinden und sich auf den Weg in eine bessere Zukunft zu machen.',
    },
    item9: {
        title: 'Im Trubel des Krieges ist der Wiederaufbau nach dem Krieg ein entscheidender Schritt, den wir unternehmen müssen. Der Wiederaufbau von Gemeinden, die Reparatur beschädigter Gebäude und die Wiedereingliederung der Opfer in die Gesellschaft erfordern professionelle Hilfe und engagierte Unterstützung.',
    },
    item10: {
        title: 'Die Knights Foundation kam sofort zu Hilfe und leistete den Opfern des palästinensisch-israelischen Krieges Hilfe. Angesichts der Herausforderungen des israelisch-palästinensischen Krieges steht unsere Knights Foundation fest an der Seite der Opfer. Als Team, das sich für finanzielle Gerechtigkeit und soziale Verantwortung einsetzt, betrachten wir Fürsorge und Unterstützung seit jeher als eine unserer Aufgaben.',
    },
    item11: {
        title: 'Im palästinensisch-israelischen Krieg sind wir uns der Herausforderungen und Schwierigkeiten bewusst, mit denen die Opfer konfrontiert sind. Wir haben Sofortmaßnahmen ergriffen, um den betroffenen Gebieten dringend benötigte materielle Hilfe zu leisten. Zu diesen Hilfsgütern gehören Lebensmittel, Wasser, medizinische Hilfsgüter und Notfallrettungsausrüstung. Sie sollen den von der Katastrophe Betroffenen dringend benötigte Hilfe und Unterstützung bieten.',
    },
    item12: {
        title: 'Die Knights Foundation engagiert sich seit langem für die Wiederbelebung nach dem Krieg. Der Wiederaufbau von Gemeinschaften und die Unterstützung der Betroffenen sind von entscheidender Bedeutung. Deshalb zögern wir nicht, uns dafür einzusetzen, dass die vom Krieg Betroffenen ihr Leben wieder aufbauen können.',
    },
    item13: {
      title: 'Im Trubel des Krieges haben wir uns zusammengeschlossen, um den Opfern Hilfe zu leisten. Die Knights Foundation hat sich seit jeher ihrer sozialen Verantwortung verschrieben und engagiert sich für die Hilfe für die vom Krieg Betroffenen. Lasst uns das Leben der Kriegsopfer mit dem Licht der Hoffnung erhellen!',
    },
    item14: {
      title: 'In den schwierigen Momenten nach dem Krieg ist das Ritterkonsortium bereit, Ihr verlässlicher Partner zu sein und sich gemeinsam an der Mission der Rettung und des Wiederaufbaus zu beteiligen. Wir wissen, dass jedes Katastrophenopfer Pflege und Hilfe benötigt. Deshalb beteiligen wir uns aktiv an verschiedenen Hilfsaktionen, um den Leidenden die notwendige Unterstützung zukommen zu lassen.',
    },
    item15: {
      title: 'Die Knights Foundation hat es sich zur Aufgabe gemacht, diesen Geist zu verbreiten. Durch wohltätige Aktionen, Hilfsprojekte und ehrenamtliche Arbeit streben wir danach, das Leben zu verbessern, Gemeinschaften zu unterstützen und die Welt zu einem wärmeren Ort zu machen. Nur gemeinsam können wir eine bessere und hoffnungsvollere Zukunft schaffen.',
    },
    item16: {
        title: 'Argentinien wurde von einem extrem starken Sturm heimgesucht, der 16 unschuldige Menschenleben forderte. Das Knight Consortium war zutiefst betrübt und reagierte sofort, und unser professionelles Team beteiligte sich sofort an der Katastrophenhilfe. Auch in dieser Notlage engagieren wir uns weiterhin für die Unterstützung der betroffenen Gebiete.',
    },
    item17: {
        title: 'Wir erfuhren, dass sich in Gansu, China, ein Erdbeben der Stärke 6,2 ereignet hat, das für die Anwohner großes Leid verursachte. In dieser schwierigen Zeit steht die Knights Foundation an der Seite der Menschen im Katastrophengebiet und drückt ihr ihr tiefstes Beileid und ihre Unterstützung aus.',
    },
    item18: {
        title: 'Es gibt Momente im Leben, die mehr Pflege und Unterstützung erfordern. Die Knight Foundation nimmt soziale Verantwortung als ihre eigene Verantwortung wahr. Wir sind fest davon überzeugt, dass die Hilfe für Bedürftige ein wichtiger Teil des Aufbaus einer besseren Gesellschaft ist, und wir werden immer an Ihrer Seite sein.',
    },
    item19: {
        title: 'Das Knight Consortium macht sich gleich wieder auf den Weg! Als sie die ordentlich angeordnete Milch, das Öl, die Nudeln, die Kekse und andere Lebensmittel auf dem Boden betrachteten, waren sie gerade dabei, sie an eine bedürftige Familie vor Ort zu liefern. Wenn wir ihre glücklichen Gesichter sehen, wissen wir, wie viel Wärme und Hoffnung diese Vorräte bringen werden. Eine Liebe, eine Wärme, die Knight Foundation interpretiert soziale Verantwortung mit Taten, freuen wir uns auf die Berührung, die diese Lieferung mit sich bringt.',
    },
    item20: {
        title: 'Kürzlich haben wir miterlebt, wie ein großes Team von Mitgliedern des Ritterkonsortiums voller Energie und Leidenschaft jede Menge Lebensmittel zu den örtlichen Gemeinden transportierte, um Frauen, ältere Menschen und Kinder zu besuchen. Jedes Mitglied dieses Teams strahlt grenzenlosen Enthusiasmus und Fürsorge aus und seine Anwesenheit bringt eine warme Strömung in die Gemeinschaft. Während der Veranstaltung sahen wir junge Männer und Frauen, die herzlich mit älteren Menschen und Kindern umgingen. Ihr Lächeln war voller Glück und Berührung. Ob Essensverteilung oder Plaudern, jeder ist dabei und bringt mit Herz Wärme und Fürsorge zu anderen. Dieses dynamische Team der Knight Financial Group demonstriert den Geist der Einheit und Freundschaft und verleiht der Gemeinschaft eine starke Liebe.',
    },
    item21: {
        title: 'Kürzlich führte eine Gruppe von Mitgliedern des Knight Consortium einen rührenden Besuch durch. Sie gingen in die Gegend und besuchten Frauen, ältere Menschen und Kinder und brachten Wärme und Fürsorge. Diese Veranstaltung ist nicht nur ein einfacher Besuch, sondern auch ein Ausdruck aufrichtiger Fürsorge und Unterstützung für die Gemeinschaft. Während der Veranstaltung führten Mitglieder der Knights Foundation herzliche Gespräche mit den Interviewpartnern und tauschten sich gegenseitig über ihre Geschichten und Erfahrungen aus. Die alten Leute lächelten, die Kinder lachten und die Frauen waren dankbar. Sie hörten aufmerksam zu und begleiteten sie liebevoll, sodass jeder Interviewpartner die Wärme und Fürsorge der Gemeinschaft spürte.',
    },
    item22: {
        title: 'An einem sonnigen Tag besuchte das San Juan-Team des Knight Consortium mit voller Betreuung bedürftige Familien vor Ort. Die Teammitglieder verteilten Dinge des täglichen Bedarfs wie Reis, Speiseöl, Hohlnudeln, Dosen, Brot und Kekse und führten herzliche Beileidsbekundungen und Gespräche mit jeder Familie. Die materielle Verteilung dieser Zeit ist nicht nur eine praktische Unterstützung für den täglichen Bedarf, sondern auch ein spiritueller Trost für jede Familie. Freiwillige von Knight verbringen herzliche Momente mit den Familien, denen geholfen wird, und vermitteln einander Fürsorge und Hoffnung. Die Familien, denen geholfen wurde, bedankten sich bei den Knight-Freiwilligen. Diese Herzlichkeit erfüllte sie mit Zuversicht und Erwartungen für die Zukunft. Als die Freiwilligen gingen, sandten die Familien ihre aufrichtigen Segenswünsche und dankten dem San Juan-Team der Knights Foundation für die Fürsorge und Wärme, die sie ihnen entgegenbrachten.',
    },
    item23: {
        title: 'Mitglieder der Knights Foundation reichten mehreren armen Familien vor Ort ihre helfende Hand und schickten ihnen reichlich Dinge des täglichen Bedarfs! Bei einer früheren Veranstaltung reisten begeisterte Freiwillige der Knights Foundation mit reichlich Vorräten wie Speiseöl, Nudeln, Weizen und Mehl in die örtliche Gegend und lieferten diese Dinge des täglichen Bedarfs an mehrere arme Familien. Dabei handelt es sich nicht nur um eine einfache Spende, sondern auch um ein aufrichtiges Beileid und eine Mitteilung. Während sie diese Wärme empfingen, drückten die Familien, denen geholfen wurde, den freiwilligen Helfern von Knight ihre tiefe Dankbarkeit aus. Durch diese Fürsorge scheinen die Schwierigkeiten im Leben wärmer und voller Hoffnung und Erwartung zu werden.',
    },
    item24: {
        title: 'Die Freiwilligen der Knight Foundation begaben sich auf eine berührende Wohltätigkeitsreise mit Gütern des täglichen Bedarfs wie Brot, Öl, Milch, Zucker usw. Sie gingen tief in die Bergregionen und leisteten konkrete Hilfe für die bedürftigen Familien, die an den Berghängen lebten und nur über begrenzte Transportmöglichkeiten verfügten. Diese Materialverteilung deckt alle Aspekte des täglichen Familienlebens ab, einschließlich Speiseöl, Mehl, Milch, Zucker usw., um sicherzustellen, dass jede Familie die herzliche Fürsorge der Knights Foundation spüren kann. Freiwillige sind nicht nur Überbringer von Hilfsgütern, sondern auch Überbringer von Kameradschaft, Beileid und Kommunikation. Die Familien, denen geholfen wird, leben in abgelegenen Berggebieten und stehen oft vor Herausforderungen wie unbequemen Transportmöglichkeiten und schwierigen Lebensbedingungen. Daher war die Ankunft der Knight-Freiwilligen für sie ein rechtzeitiger Segen und erfüllte sie mit Dankbarkeit. Während des Gesprächs erzählten alle die Geschichten der anderen, was diese Wohltätigkeitsveranstaltung noch herzlicher und bedeutungsvoller machte.',
    },
    item25: {
      title: 'Kürzlich schien die Sonne hell und Mitglieder des Ritterkonsortiums kamen mit Eimern voller Speiseöl und Saft in die Gegend, um zu helfen und den Menschen, die Unterstützung und Hilfe brauchten, Wärme und Fürsorge zu schenken. An diesem schönen Tag sind wir nicht nur Mitglieder des Ritterkonsortiums, sondern auch Teil der Gemeinschaft. Wir wissen, dass unsere Verantwortung und Mission als Team auf unseren Schultern liegt. Deshalb sind wir bereit, hart zu arbeiten und Maßnahmen zu ergreifen, um unsere Fürsorge und Unterstützung für die Gemeinschaft zu demonstrieren. Lasst uns gemeinsam unsere Liebe verbreiten und die Welt mit Wärme und Hoffnung erfüllen. Vielen Dank für Ihre Unterstützung. Lassen Sie uns gemeinsam weiter voranschreiten und zur Entwicklung und zum Fortschritt der Community beitragen!',
    },
    item26: {
      title: 'Vor kurzem kam unser Team vom Knight Consortium nach Cordoba, Argentinien. Dieser wunderschöne Ort ist voller Wärme und Hoffnung. Unser fast 30-köpfiges Team ist hier versammelt. Mit warmem Herzen und Ihrer Liebe und Unterstützung werden wir mehr Menschen in Not helfen und ihnen Wärme und Fürsorge bringen. Bei dieser Aktion voller Liebe sind wir nicht nur Mitglied des Ritterkonsortiums, sondern auch Ihr Sprecher und Bote. Dank Ihrer Großzügigkeit und Liebe können wir hierher kommen und mehr Menschen Dankbarkeit bringen. Lassen Sie uns gemeinsam einen Beitrag zur Gesellschaft leisten und die Welt mit mehr Wärme und Hoffnung erfüllen. Vielen Dank für Ihre Unterstützung. Lassen Sie uns weiterhin die Kraft der Liebe verbreiten!',
    }
  }
}

export const team = {
    leader1: {
        title: "Vorsitzende",
        name: "Zhang Guofu",
        desc: "Master-Abschluss in Finanzökonomie von der University of Oxford, Großbritannien"
    },
    leader2: {
        title: "Stellvertretender Vorsitzender und Rechtsdirektor",
        item1: {
            name: "Remus John Worm",
            desc: "Master of Economics, University of Chicago School of Economics"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Master of Science und Master of Business Administration, University of Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Abschluss als Master of Management an der Universität Peking"
        }
    },
    leader3: {
        title: "Finanzanalyst",
        item1: {
            name: "Maxwell",
            desc: "Bachelor of International Finance, Fudan-Universität"
        },
        item2: {
            name: "Yang Jun",
            desc: "Abschluss an der Nanyang Technological University, Singapur, mit Schwerpunkt Finanzen"
        },
        item3: {
            name: "Hubert",
            desc: "Doppelabschluss der University of London, Großbritannien, und der Harvard University, USA"
        }
    },
    leader4: {
        title: "Berater",
        item1: {
            name: "Анфия",
            desc: "Bachelor-Abschluss, University of Florida"
        },
        item2: {
            name: "Xu Hao",
            desc: "Ingenieurabschluss an der George Washington University"
        },
        item3: {
            name: "Dorothy",
            desc: "MBA vom Indian Institute of Technology"
        }
    },
    leader5: {
        title: "Kernmitglied",
        item1: {
            name: "sun horse",
            desc: "Studium der Wirtschaftswissenschaften an der Royal Holloway, University of London, Großbritannien"
        },
        item2: {
            name: "Yvonne",
            desc: "Master-Abschluss der Freien Universität Brüssel"
        },
        item3: {
            name: "Yan Tao",
            desc: "Master-Abschluss der University of Washington (Ingenieurmanagement-Abschluss)"
        }
    }
}

export const casepage = {
    title: "Einführung in die Sozialhilfe",
    item1: {
        title: "Nach dem Erdbeben der Stärke 7,8 in der Türkei im Februar 2023 spendete das Knight-Konsortium 2,3 Millionen US-Dollar an Flüchtlinge"
    },
    item2: {
        title: "Im Juli 2021 verursachten heftige Regenfälle Überschwemmungen in Zhengzhou, Provinz Henan, China. Das Knight-Konsortium spendete 1,8 Millionen US-Dollar;"
    },
    item3: {
        title: "Während der australischen Waldbrände 2019 spendete Consortium Capital 1,2 Millionen US-Dollar für Hilfsgüter und Wiederaufbau."
    },
    item4: {
        title: "Im Februar 2023 erschütterte ein Erdbeben der Stärke 7,8 die Türkei und forderte mehr als 41.000 Todesopfer. 1,5 Millionen Menschen wurden in der südlichen Region obdachlos und es mussten mindestens 500.000 neue Häuser gebaut werden. Im Nordwesten Syriens sind bis zu 9 Millionen Menschen betroffen und mindestens 6.000 Menschen haben ihr Leben verloren. Consortium Capital spendete 2,3 Millionen US-Dollar an Flüchtlinge, um ihnen lebende Materialien zur Verfügung zu stellen und ihre Häuser wieder aufzubauen.",
    },
    item5: {
        title: "Im Juni 2022 kamen bei dem Erdbeben in Afghanistan mehr als 1.000 Menschen ums Leben und mehr als 2.000 Menschen wurden verletzt. Das Kapital des Konsortiums spendete 1 Million US-Dollar für den Wiederaufbau von Wohnraum nach der Katastrophe, wovon ein Teil für den täglichen Bedarf verwendet wurde.",
    },
    item6: {
        title: "Die Spende von Materialien ist unsere direkteste und praktischste Hilfe für die Opfer. Im Februar 2023 wurde die Türkei von einem Erdbeben der Stärke 7,8 heimgesucht und die Knights Foundation spendete 2,3 Millionen US-Dollar an Flüchtlinge, um ihre Häuser wieder aufzubauen.",
    },
    item7: {
        title: "Im Februar 2023 wurde die Türkei von einem Erdbeben der Stärke 7,8 heimgesucht, in vielen Gebieten kam es zu schweren Schäden und Verlusten. Die Knight Foundation entsandte Rettungsteams, um die Opfer zu retten, ihnen Wärme und Hoffnung zu geben und sie die Wärme und Fürsorge der sozialen Familie spüren zu lassen.",
    },
    item8: {
        title: "Bei dem Erdbeben in Afghanistan im Juni 2022 kamen mehr als 1.000 Menschen ums Leben und mehr als 2.000 Menschen wurden verletzt. Das Kapital des Konsortiums spendete 1 Million US-Dollar für den Wiederaufbau von Wohnraum nach der Katastrophe, wovon ein Teil für den Lebensunterhalt verwendet wurde.",
    },
    item9: {
        title: "Die Knights Foundation beteiligt sich aktiv an Wohltätigkeitsaktionen, die sich um arme Kinder kümmern. Regelmäßige finanzielle Unterstützung und Pflege werden durch Spenden und ehrenamtliche Dienste geleistet. Diese Art der Betreuung und Hilfe verändert nicht nur das Schicksal der Kinder, sondern bringt auch mehr positive Energie in die Entwicklung der gesamten Gesellschaft.",
    },
    item10: {
        title: "Im September 2022 reiste das Wohltätigkeitsteam der Knight Foundation nach Afrika, um armen Kindern Pflege und Hilfe zu bieten und sicherzustellen, dass die Grundbedürfnisse der Kinder erfüllt werden. Die Peer-to-Peer-Finanzierungsmethode kann das Leben von Kindern direkter verbessern und ihnen Hoffnung und Ermutigung geben.",
    },
    item11: {
        title: "Zivilisten erhielten während des israelisch-palästinensischen Krieges keinerlei Schutz. Das Ritterkonsortium sorgte für die Grundversorgung der Menschen und stellte aktiv wichtige Hilfskräfte zur Verfügung.",
    },
    item12: {
        title: "Im palästinensisch-israelischen Krieg sind die Öffnung der Versorgungslinien und humanitäre Hilfe von entscheidender Bedeutung und dringend erforderlich. Das Knights Consortium nutzt die Zeitspanne, um Hilfsgüter zu liefern, die für das Wohlergehen der Menschen im Gazastreifen von entscheidender Bedeutung sind, und stimmt sich mit internationalen humanitären Organisationen ab, um die Ankunft der Hilfsgüter sicherzustellen.",
    },
    item13: {
        title: "Während der israelisch-palästinensische Krieg andauert, steigt die Zahl der Opfer weiter an. Die Rettung der Opfer steht unmittelbar bevor und das Knight Consortium organisiert aktiv Rettungsteams, um eine sofortige Rettung zu gewährleisten.",
    },
    item14: {
        title: "Im Juli 2023 kam es in Peking, Tianjin, Hebei und anderen Orten in China, betroffen vom Taifun Nr. 5 „Dusuri“, zu extrem starken Regenfällen, die zu Überschwemmungskatastrophen führten. Das Knight Consortium startete eine Notfallrettung und stellte rechtzeitig Rettungskanäle bereit, um älteren Menschen und Menschen in Not zu helfen, aus der Not zu kommen.",
    },
    item15: {
        title: 'Die Knights Foundation kam sofort zu Hilfe und leistete den Opfern des palästinensisch-israelischen Krieges Hilfe. Angesichts der Herausforderungen des israelisch-palästinensischen Krieges steht unsere Knights Foundation fest an der Seite der Opfer. Als Team, das sich für finanzielle Gerechtigkeit und soziale Verantwortung einsetzt, betrachten wir Fürsorge und Unterstützung seit jeher als eine unserer Aufgaben.',
    },
    item16: {
        title: 'Im palästinensisch-israelischen Krieg sind wir uns der Herausforderungen und Schwierigkeiten bewusst, mit denen die Opfer konfrontiert sind. Wir haben Sofortmaßnahmen ergriffen, um den betroffenen Gebieten dringend benötigte materielle Hilfe zu leisten. Zu diesen Hilfsgütern gehören Lebensmittel, Wasser, medizinische Hilfsgüter und Notfallrettungsausrüstung. Sie sollen den von der Katastrophe Betroffenen dringend benötigte Hilfe und Unterstützung bieten.',
    },
    item17: {
        title: 'Die Knights Foundation engagiert sich seit langem für die Wiederbelebung nach dem Krieg. Der Wiederaufbau von Gemeinschaften und die Unterstützung der Betroffenen sind von entscheidender Bedeutung. Deshalb zögern wir nicht, uns dafür einzusetzen, dass die vom Krieg Betroffenen ihr Leben wieder aufbauen können.',
    },
    item18: {
        title: 'Die Knight Consortium ist seit jeher für ihre professionelle Unterstützung bekannt. Wir verfügen über ein erfahrenes Team, das schnell reagieren und praktische Lösungen anbieten kann. Wir alle arbeiten effizient und transparent, um sicherzustellen, dass jede noch so kleine Hilfe angemessen und effektiv eingesetzt wird.',
    },
    item19: {
        title: 'Wenn Sie sich für die professionelle Unterstützung des Knight Consortium entscheiden, haben Sie sich für einen zuverlässigen Partner entschieden, ein Team, das sich der Verbesserung von Leben und dem Wiederaufbau von Hoffnung widmet. Wir werden keine Mühen scheuen, um den Opfern die Unterstützung zukommen zu lassen, die sie benötigen, um ihnen zu helfen, die Schwierigkeiten zu überwinden und sich auf den Weg in eine bessere Zukunft zu machen.',
    },
    item20: {
        title: 'Im Trubel des Krieges ist der Wiederaufbau nach dem Krieg ein entscheidender Schritt, den wir unternehmen müssen. Der Wiederaufbau von Gemeinden, die Reparatur beschädigter Gebäude und die Wiedereingliederung der Opfer in die Gesellschaft erfordern professionelle Hilfe und engagierte Unterstützung.',
    },
    item21: {
      title: 'Im Trubel des Krieges haben wir uns zusammengeschlossen, um den Opfern Hilfe zu leisten. Die Knights Foundation hat sich seit jeher ihrer sozialen Verantwortung verschrieben und engagiert sich für die Hilfe für die vom Krieg Betroffenen. Lasst uns das Leben der Kriegsopfer mit dem Licht der Hoffnung erhellen!',
    },
    item22: {
      title: 'In den schwierigen Momenten nach dem Krieg ist das Ritterkonsortium bereit, Ihr verlässlicher Partner zu sein und sich gemeinsam an der Mission der Rettung und des Wiederaufbaus zu beteiligen. Wir wissen, dass jedes Katastrophenopfer Pflege und Hilfe benötigt. Deshalb beteiligen wir uns aktiv an verschiedenen Hilfsaktionen, um den Leidenden die notwendige Unterstützung zukommen zu lassen.',
    },
    item23: {
      title: 'Die Knights Foundation hat es sich zur Aufgabe gemacht, diesen Geist zu verbreiten. Durch wohltätige Aktionen, Hilfsprojekte und ehrenamtliche Arbeit streben wir danach, das Leben zu verbessern, Gemeinschaften zu unterstützen und die Welt zu einem wärmeren Ort zu machen. Nur gemeinsam können wir eine bessere und hoffnungsvollere Zukunft schaffen.',
    },
    item24: {
        title: 'Argentinien wurde von einem extrem starken Sturm heimgesucht, der 16 unschuldige Menschenleben forderte. Das Knight Consortium war zutiefst betrübt und reagierte sofort, und unser professionelles Team beteiligte sich sofort an der Katastrophenhilfe. Auch in dieser Notlage engagieren wir uns weiterhin für die Unterstützung der betroffenen Gebiete.',
    },
    item25: {
        title: 'Wir erfuhren, dass sich in Gansu, China, ein Erdbeben der Stärke 6,2 ereignet hat, das für die Anwohner großes Leid verursachte. In dieser schwierigen Zeit steht die Knights Foundation an der Seite der Menschen im Katastrophengebiet und drückt ihr ihr tiefstes Beileid und ihre Unterstützung aus.',
    },
    item26: {
        title: 'Es gibt Momente im Leben, die mehr Pflege und Unterstützung erfordern. Die Knight Foundation nimmt soziale Verantwortung als ihre eigene Verantwortung wahr. Wir sind fest davon überzeugt, dass die Hilfe für Bedürftige ein wichtiger Teil des Aufbaus einer besseren Gesellschaft ist, und wir werden immer an Ihrer Seite sein.',
    },
    item27: {
        title: 'Das Knight Consortium macht sich gleich wieder auf den Weg! Als sie die ordentlich angeordnete Milch, das Öl, die Nudeln, die Kekse und andere Lebensmittel auf dem Boden betrachteten, waren sie gerade dabei, sie an eine bedürftige Familie vor Ort zu liefern. Wenn wir ihre glücklichen Gesichter sehen, wissen wir, wie viel Wärme und Hoffnung diese Vorräte bringen werden. Eine Liebe, eine Wärme, die Knight Foundation interpretiert soziale Verantwortung mit Taten, freuen wir uns auf die Berührung, die diese Lieferung mit sich bringt.',
    },
    item28: {
        title: 'Kürzlich haben wir miterlebt, wie ein großes Team von Mitgliedern des Ritterkonsortiums voller Energie und Leidenschaft jede Menge Lebensmittel zu den örtlichen Gemeinden transportierte, um Frauen, ältere Menschen und Kinder zu besuchen. Jedes Mitglied dieses Teams strahlt grenzenlosen Enthusiasmus und Fürsorge aus und seine Anwesenheit bringt eine warme Strömung in die Gemeinschaft. Während der Veranstaltung sahen wir junge Männer und Frauen, die herzlich mit älteren Menschen und Kindern umgingen. Ihr Lächeln war voller Glück und Berührung. Ob Essensverteilung oder Plaudern, jeder ist dabei und bringt mit Herz Wärme und Fürsorge zu anderen. Dieses dynamische Team der Knight Financial Group demonstriert den Geist der Einheit und Freundschaft und verleiht der Gemeinschaft eine starke Liebe.',
    },
    item29: {
        title: 'Kürzlich führte eine Gruppe von Mitgliedern des Knight Consortium einen rührenden Besuch durch. Sie gingen in die Gegend und besuchten Frauen, ältere Menschen und Kinder und brachten Wärme und Fürsorge. Diese Veranstaltung ist nicht nur ein einfacher Besuch, sondern auch ein Ausdruck aufrichtiger Fürsorge und Unterstützung für die Gemeinschaft. Während der Veranstaltung führten Mitglieder der Knights Foundation herzliche Gespräche mit den Interviewpartnern und tauschten sich gegenseitig über ihre Geschichten und Erfahrungen aus. Die alten Leute lächelten, die Kinder lachten und die Frauen waren dankbar. Sie hörten aufmerksam zu und begleiteten sie liebevoll, sodass jeder Interviewpartner die Wärme und Fürsorge der Gemeinschaft spürte.',
    },
    item30: {
        title: 'An einem sonnigen Tag besuchte das San Juan-Team des Knight Consortium mit voller Betreuung bedürftige Familien vor Ort. Die Teammitglieder verteilten Dinge des täglichen Bedarfs wie Reis, Speiseöl, Hohlnudeln, Dosen, Brot und Kekse und führten herzliche Beileidsbekundungen und Gespräche mit jeder Familie. Die materielle Verteilung dieser Zeit ist nicht nur eine praktische Unterstützung für den täglichen Bedarf, sondern auch ein spiritueller Trost für jede Familie. Freiwillige von Knight verbringen herzliche Momente mit den Familien, denen geholfen wird, und vermitteln einander Fürsorge und Hoffnung. Die Familien, denen geholfen wurde, bedankten sich bei den Knight-Freiwilligen. Diese Herzlichkeit erfüllte sie mit Zuversicht und Erwartungen für die Zukunft. Als die Freiwilligen gingen, sandten die Familien ihre aufrichtigen Segenswünsche und dankten dem San Juan-Team der Knights Foundation für die Fürsorge und Wärme, die sie ihnen entgegenbrachten.',
    },
    item31: {
        title: 'Mitglieder der Knights Foundation reichten mehreren armen Familien vor Ort ihre helfende Hand und schickten ihnen reichlich Dinge des täglichen Bedarfs! Bei einer früheren Veranstaltung reisten begeisterte Freiwillige der Knights Foundation mit reichlich Vorräten wie Speiseöl, Nudeln, Weizen und Mehl in die örtliche Gegend und lieferten diese Dinge des täglichen Bedarfs an mehrere arme Familien. Dabei handelt es sich nicht nur um eine einfache Spende, sondern auch um ein aufrichtiges Beileid und eine Mitteilung. Während sie diese Wärme empfingen, drückten die Familien, denen geholfen wurde, den freiwilligen Helfern von Knight ihre tiefe Dankbarkeit aus. Durch diese Fürsorge scheinen die Schwierigkeiten im Leben wärmer und voller Hoffnung und Erwartung zu werden.',
    },
    item32: {
        title: 'Die Freiwilligen der Knight Foundation begaben sich auf eine berührende Wohltätigkeitsreise mit Gütern des täglichen Bedarfs wie Brot, Öl, Milch, Zucker usw. Sie gingen tief in die Bergregionen und leisteten konkrete Hilfe für die bedürftigen Familien, die an den Berghängen lebten und nur über begrenzte Transportmöglichkeiten verfügten. Diese Materialverteilung deckt alle Aspekte des täglichen Familienlebens ab, einschließlich Speiseöl, Mehl, Milch, Zucker usw., um sicherzustellen, dass jede Familie die herzliche Fürsorge der Knights Foundation spüren kann. Freiwillige sind nicht nur Überbringer von Hilfsgütern, sondern auch Überbringer von Kameradschaft, Beileid und Kommunikation. Die Familien, denen geholfen wird, leben in abgelegenen Berggebieten und stehen oft vor Herausforderungen wie unbequemen Transportmöglichkeiten und schwierigen Lebensbedingungen. Daher war die Ankunft der Knight-Freiwilligen für sie ein rechtzeitiger Segen und erfüllte sie mit Dankbarkeit. Während des Gesprächs erzählten alle die Geschichten der anderen, was diese Wohltätigkeitsveranstaltung noch herzlicher und bedeutungsvoller machte.',
    },
    item33: {
      title: 'Kürzlich schien die Sonne hell und Mitglieder des Ritterkonsortiums kamen mit Eimern voller Speiseöl und Saft in die Gegend, um zu helfen und den Menschen, die Unterstützung und Hilfe brauchten, Wärme und Fürsorge zu schenken. An diesem schönen Tag sind wir nicht nur Mitglieder des Ritterkonsortiums, sondern auch Teil der Gemeinschaft. Wir wissen, dass unsere Verantwortung und Mission als Team auf unseren Schultern liegt. Deshalb sind wir bereit, hart zu arbeiten und Maßnahmen zu ergreifen, um unsere Fürsorge und Unterstützung für die Gemeinschaft zu demonstrieren. Lasst uns gemeinsam unsere Liebe verbreiten und die Welt mit Wärme und Hoffnung erfüllen. Vielen Dank für Ihre Unterstützung. Lassen Sie uns gemeinsam weiter voranschreiten und zur Entwicklung und zum Fortschritt der Community beitragen!',
    },
    item34: {
      title: 'Vor kurzem kam unser Team vom Knight Consortium nach Cordoba, Argentinien. Dieser wunderschöne Ort ist voller Wärme und Hoffnung. Unser fast 30-köpfiges Team ist hier versammelt. Mit warmem Herzen und Ihrer Liebe und Unterstützung werden wir mehr Menschen in Not helfen und ihnen Wärme und Fürsorge bringen. Bei dieser Aktion voller Liebe sind wir nicht nur Mitglied des Ritterkonsortiums, sondern auch Ihr Sprecher und Bote. Dank Ihrer Großzügigkeit und Liebe können wir hierher kommen und mehr Menschen Dankbarkeit bringen. Lassen Sie uns gemeinsam einen Beitrag zur Gesellschaft leisten und die Welt mit mehr Wärme und Hoffnung erfüllen. Vielen Dank für Ihre Unterstützung. Lassen Sie uns weiterhin die Kraft der Liebe verbreiten!',
    },
    item35: {
      title: 'Mitglieder des Knights-Konsortiums versammelten sich offline, um das Fest des America\'s Cup- und European Cup-Finales zu feiern! Wir genossen nicht nur leckeres Essen am Tisch, sondern teilten unter Gelächter auch unsere Begeisterung und Erwartungen an das Spiel. Egal welche Mannschaft wir unterstützen, in diesem Moment sind wir alle treue Fußballfans und jubeln in unserem Herzen dem Meister zu. Die Endspiele des America\'s Cup und des European Cup sind nicht nur ein Fest des Fußballs, sondern auch ein Zeugnis der Einheit und Freundschaft der Mitglieder des Knights Consortium.',
    },
    item36: {
      title: 'Wir kommen in diesem besonderen Moment zusammen, um nicht nur den Charme des Fußballs zu feiern, sondern auch um die gegenseitigen Verbindungen und die Zusammenarbeit zu stärken. Jedes Spiel ist eine Teamleistung und jedes Tor ist unser gemeinsamer Jubel! Vielen Dank an alle Mitglieder des Knights Consortium, die an diesem Abendessen teilgenommen haben. Ihr Enthusiasmus und Ihre Energie haben diesem Abend endlose Spannung verliehen. Lassen Sie uns Hand in Hand weiter voranschreiten und gemeinsam weitere Siege und Ruhm begrüßen!',
    },
}

export const industry = {
    title: "Investmentbranche",
    cat1: {
        name: "finance",
        item1: {
            title: "Das Investmentteam des Knight-Konsortiums kaufte Anteile an einer Reihe von Edelmetallbergbauunternehmen. Diese Unternehmen entwickelten sich im Jahr 2012 gut, da steigende Edelmetallpreise zu einem Anstieg ihrer Aktienkurse führten, was zu enormen Gewinnen für das Knights-Syndikat führte.",
        },
        item2: {
            title: "Im Zeitraum Januar bis Februar 2012 schwankte der internationale Goldpreis. Die Finanzanalysten des Knight-Konsortiums nutzten die Gelegenheit, zu günstigen Preisen einzukaufen und erzielten in nur drei Monaten einen Gewinn von fast 60 %.",
        }
    },
    cat2: {
        name: "medical",
        item1: {
            title: "Im Jahr 2020 wurde das Knight-Konsortium Medical Service Center gegründet und die gemeinsam mit der Merck Pharmaceutical Group entwickelte Behandlungsmethode „Tumorimmuntherapie“ war erfolgreich.",
        },
        item2: {
            title: "Das Knight-Konsortium Medical Services Center und Novartis Pharmaceuticals haben das krebsgerichtete Medikament Houng-592 entwickelt",
        }
    },
    cat3: {
        name: "metaverse",
        item1: {
            title: "Im Jahr 2021 wurde das Web3.0-Zentrum des Knight-Konsortiums gegründet, um eine dezentrale soziale Plattform zu schaffen, die große Aufmerksamkeit erregte und von den Nutzern gut angenommen wurde.",
        },
        item2: {
            title: "Im Bullenmarkt der Kryptowährungen im Januar 2017 nutzte das Knight-Konsortium die Chance und führte die Stiftungsmitglieder zu hohen Gewinnen, wodurch sich das Vermögen aller Mitglieder schnell verdoppelte.",
        }
    }
}


export const about = {
    title: "Das Knight-Konsortium vereint die Kraft von Privatanlegern, um den Traum von finanzieller Freiheit zu verwirklichen.",
    desc: "Herren lieben das Glück, und zwar auf die richtige Art und Weise. Das Knight-Konsortium ist bereit, diesen breiten Weg zu begleiten, damit mehr Menschen es entdecken können, damit jeder zunächst Wohlstand und Wohlstand erreichen und dann Selbstverwirklichung erreichen kann! Helfen Sie einzelnen Investitionen, dem Schicksal zu entgehen, von Institutionen ausgebeutet zu werden, und erzielen Sie Gewinne durch professionelle und zuverlässige Kapitaloperationen. So helfen Sie den Teammitgliedern, ihre Träume von finanzieller Freiheit zu verwirklichen.",
    service: {
        title: "Unsere Konsortialkapitalziele",
        item1: {
            title: "",
            desc: "Bemühen Sie sich nach Kräften, die globale wirtschaftliche und soziale Entwicklung und die Wirtschaftsbeziehungen zu fördern.<br><br><br><br><br><br>"
        },
        item2: {
            title: "",
            desc: "Fördern Sie die Weiterentwicklung der Wohltätigkeitsorganisation, um den Lebensunterhalt der Menschen zu verbessern, leisten Sie nationale Hilfe für internationale Flüchtlinge, die unter Naturkatastrophen leiden, und helfen Sie benachteiligten Gruppen mit praktischen Maßnahmen, aus Schwierigkeiten herauszukommen und ihnen ein glückliches und friedliches Leben zu ermöglichen."
        },
        item3: {
            title: "",
            desc: "Richten Sie Serviceteams im Ausland ein, um die Entwicklung der globalen Landwirtschaft, Industrie, des Wirtschaftsaufbaus, der medizinischen Versorgung, der Bildung usw. zu unterstützen.<br><br><br><br>"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "Der Schwerpunkt liegt auf Südamerika als Schlüsselgebiet für die Unterstützung und Hilfe durch die Knight Capital Foundation, und es wird erwartet, dass sie mindestens 50 Millionen US-Dollar in private Hilfe investiert. Innerhalb von drei Jahren werden in Südamerika 1.000 Offline-Wohltätigkeitsbüros der Knight Capital Foundation und 5.000 Beschäftigungsmöglichkeiten eingerichtet."
        },
        item2: {
            title: "2025",
            desc: "Die Kapitalerträge und Spendensumme der Knight Capital Foundation erreichen jährlich 100 Millionen US-Dollar"
        },
        item3: {
            title: "2026",
            desc: "Erreichen Sie 5.000 Offline-Wohltätigkeitsbüros der Knight Capital Foundation auf der ganzen Welt und mehr als 20.000 Beschäftigungsmöglichkeiten"
        },
        item4: {
            title: "2027",
            desc: "Die Kapitalerträge und Spendensumme der Knight Capital Foundation werden voraussichtlich mehr als 300 Millionen US-Dollar erreichen. Durch die Unterstützung landwirtschaftlicher Technologie und den Aufbau von Infrastruktur wird es mehr als 200 Millionen Menschen auf der ganzen Welt helfen, der internationalen Armutsgrenze zu entkommen und ihnen Gesundheit, Glück und Freude zu ermöglichen."
        }
    },
    counterdata: {
      title: "Aktueller Stand und Zukunftspläne des Knight-Konsortiums",
      desc: "",
      item1: {
          title: 'Mittel',
          unit: "Milliarde",
      },
      item2: {
          title: 'Benutzer',
          unit: "Milliarde",
      },
      item3: {
          title: 'Investieren',
          unit: "Partner",
      },
      item4: {
          title: 'Spenden',
          unit: "Milliarde",
      }
    },
    teamdata: {
        title: "Mitglieder",
        item1: {
            name: "Remus John Worm",
            desc: "Master of Economics, University of Chicago School of Economics"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Master of Science und Master of Business Administration, University of Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Abschluss als Master of Management an der Universität Peking"
        }
    },
    article: {
        title: "Durch die Bündelung der Macht von Privatanlegern setzt sich das Knight Consortium dafür ein, Institutionen entgegenzutreten und für mehr Rechte für Privatanleger zu kämpfen.",
        desc: "An der Börse gibt es zwei Arten von Akteuren: Privatanleger (Einzelpersonen) und institutionelle Anleger (Buchmacher). Die institutionelle Finanzmacht verdrängt seit jeher den Lebensraum von Kleinanlegern, monopolisiert und manipuliert den Aktienmarkt. Die Mehrheit der Privatanleger war darüber schon lange betrübt und löste einen epischen Kampf zwischen Privatanlegern und Leerverkäufern an der Wall Street aus – den „GameStop-Blutbad an der Wall Street“. Sobald die Macht der Privatanleger vereint ist, Sie sind die stärkste Kraft und dominieren unbestreitbar die Aktienkursentwicklung."
    },
    certdata: {
        title:"Nationale Stiftungszertifikate",
        item1: {
            title: "",
            desc: "USA",
        },
        item2: {
            title: "",
            desc: "Singapur",
        },
        item3: {
            title: "",
            desc: "VEREINIGTES KÖNIGREICH.",
        },
    },
    purpose: {
        title:"Knight Consortium Mission",
        btntitle:"PDF Download",
        image: require('@/assets/images/pdf/pdf_en.png'),
        downlink:"/en.pdf",
    }
}


export const digitalFour = {
    digita: {
        title:"Wohltätigkeitsaktivitäten der Knights Foundation"
    }
};

export const BlogDinner = {
    dinnerGrid: {
        title:"Mitglieder des Knight-Konsortiums essen offline zu Abend"
    }
};

export const homeNotice = {
    notice:{
        title:"Sonderankündigung des Knights-Konsortiums",
        desc:""
    }
}


export const homeDinner = {
    title: "Das Abendessen des Knights Consortium Buenos Aires war ein voller Erfolg",
    desc: "Dieses Abendessen ist nicht nur ein großartiges Ereignis der Einheit, des Austauschs und der Vorfreude auf die Zukunft, sondern auch ein wichtiger Moment für die Mitglieder des Knight Consortium, um zusammenzukommen, um die glorreiche Geschichte des Konsortiums mitzuerleben. Wir freuen uns darauf, auch in Zukunft Hand in Hand mit allen zusammenzuarbeiten, um schönere Zeiten zu begrüßen und noch brillantere Ergebnisse zu erzielen.",
}

export const BlogGalaDinner = {
    dinnerGrid: {
        title:"Abendessen des Knights Consortium Buenos Aires"
    }
};
