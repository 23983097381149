import AppFunctions from '../helpers/AppFunctions'

export default {
    data() {
        return {
            posts: [
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0090.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item26.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0089.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item25.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0083.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item19.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0084.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item20.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0085.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item21.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0086.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item22.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0087.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item23.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/blog/blog-0088.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t('home.blogpost.item24.title'),
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                // {
                //     id: '16',
                //     img: require(`@/assets/images/blog/blog-0080.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item16.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '17',
                //     img: require(`@/assets/images/blog/blog-0081.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item17.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '18',
                //     img: require(`@/assets/images/blog/blog-0082.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item18.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '13',
                //     img: require(`@/assets/images/blog/blog-0077.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item13.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '14',
                //     img: require(`@/assets/images/blog/blog-0078.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item14.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '15',
                //     img: require(`@/assets/images/blog/blog-0079.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item15.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '10',
                //     img: require(`@/assets/images/blog/blog-0071.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item10.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '11',
                //     img: require(`@/assets/images/blog/blog-0072.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item11.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '12',
                //     img: require(`@/assets/images/blog/blog-0073.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item12.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '7',
                //     img: require(`@/assets/images/blog/blog-0074.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item7.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '8',
                //     img: require(`@/assets/images/blog/blog-0075.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item8.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '9',
                //     img: require(`@/assets/images/blog/blog-0076.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item9.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '1',
                //     img: require(`@/assets/images/blog/blog-044.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item4.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '2',
                //     img: require(`@/assets/images/blog/blog-055.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item5.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '3',
                //     img: require(`@/assets/images/blog/blog-066.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item6.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '4',
                //     img: require(`@/assets/images/blog/blog-01.jpg`),
                //     author: '',
                //     authorId: '1',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item1.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'Company', 'Marketing'],
                //     tags: ['Corporate', 'Agency', 'Creative', 'Design']
                // },
                // {
                //     id: '5',
                //     img: require(`@/assets/images/blog/blog-02.jpg`),
                //     author: '',
                //     authorId: '2',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item2.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['UX Design', 'Company'],
                //     tags: ['Minimal', 'Company', 'Development', 'Design']
                // },
                // {
                //     id: '6',
                //     img: require(`@/assets/images/blog/blog-03.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('home.blogpost.item3.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // }
            ],
            dinnerall: [
                {
                    id: '1',
                    img: require(`@/assets/images/dinner/dinner_000001.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '2',
                    img: require(`@/assets/images/dinner/dinner_000002.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '3',
                    img: require(`@/assets/images/dinner/dinner_000003.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '4',
                    img: require(`@/assets/images/dinner/dinner_000004.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '5',
                    img: require(`@/assets/images/dinner/dinner_000005.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '6',
                    img: require(`@/assets/images/dinner/dinner_000006.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '7',
                    img: require(`@/assets/images/dinner/dinner_000007.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '8',
                    img: require(`@/assets/images/dinner/dinner_000008.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '9',
                    img: require(`@/assets/images/dinner/dinner_000009.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '10',
                    img: require(`@/assets/images/dinner/dinner_000010.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '11',
                    img: require(`@/assets/images/dinner/dinner_000011.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '12',
                    img: require(`@/assets/images/dinner/dinner_000012.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '13',
                    img: require(`@/assets/images/dinner/dinner_000013.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '14',
                    img: require(`@/assets/images/dinner/dinner_000014.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '15',
                    img: require(`@/assets/images/dinner/dinner_000015.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/dinner/dinner_000016.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '17',
                    img: require(`@/assets/images/dinner/dinner_000017.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '18',
                    img: require(`@/assets/images/dinner/dinner_000018.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '19',
                    img: require(`@/assets/images/dinner/dinner_000019.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '20',
                    img: require(`@/assets/images/dinner/dinner_000020.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '21',
                    img: require(`@/assets/images/dinner/dinner_000021.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '22',
                    img: require(`@/assets/images/dinner/dinner_000022.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '23',
                    img: require(`@/assets/images/dinner/dinner_000023.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '24',
                    img: require(`@/assets/images/dinner/dinner_000024.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '25',
                    img: require(`@/assets/images/dinner/dinner_000025.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '26',
                    img: require(`@/assets/images/dinner/dinner_000026.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '27',
                    img: require(`@/assets/images/dinner/dinner_000027.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '28',
                    img: require(`@/assets/images/dinner/dinner_000028.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '29',
                    img: require(`@/assets/images/dinner/dinner_000029.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '30',
                    img: require(`@/assets/images/dinner/dinner_000030.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '31',
                    img: require(`@/assets/images/dinner/dinner_000031.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '32',
                    img: require(`@/assets/images/dinner/dinner_000032.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '33',
                    img: require(`@/assets/images/dinner/dinner_000033.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '34',
                    img: require(`@/assets/images/dinner/dinner_000034.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '35',
                    img: require(`@/assets/images/dinner/dinner_000035.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '36',
                    img: require(`@/assets/images/dinner/dinner_000036.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '37',
                    img: require(`@/assets/images/dinner/dinner_000037.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '38',
                    img: require(`@/assets/images/dinner/dinner_000038.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '39',
                    img: require(`@/assets/images/dinner/dinner_000039.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '40',
                    img: require(`@/assets/images/dinner/dinner_000040.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '41',
                    img: require(`@/assets/images/dinner/dinner_000041.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '42',
                    img: require(`@/assets/images/dinner/dinner_000042.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '43',
                    img: require(`@/assets/images/dinner/dinner_000043.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '44',
                    img: require(`@/assets/images/dinner/dinner_000044.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '45',
                    img: require(`@/assets/images/dinner/dinner_000045.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '46',
                    img: require(`@/assets/images/dinner/dinner_000046.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '47',
                    img: require(`@/assets/images/dinner/dinner_000047.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '48',
                    img: require(`@/assets/images/dinner/dinner_000048.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '49',
                    img: require(`@/assets/images/dinner/dinner_000049.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '50',
                    img: require(`@/assets/images/dinner/dinner_000050.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '51',
                    img: require(`@/assets/images/dinner/dinner_000051.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '52',
                    img: require(`@/assets/images/dinner/dinner_000052.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '53',
                    img: require(`@/assets/images/dinner/dinner_000053.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '54',
                    img: require(`@/assets/images/dinner/dinner_000054.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '55',
                    img: require(`@/assets/images/dinner/dinner_000055.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '56',
                    img: require(`@/assets/images/dinner/dinner_000056.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '57',
                    img: require(`@/assets/images/dinner/dinner_000057.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '58',
                    img: require(`@/assets/images/dinner/dinner_000058.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '59',
                    img: require(`@/assets/images/dinner/dinner_000059.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '60',
                    img: require(`@/assets/images/dinner/dinner_000060.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '61',
                    img: require(`@/assets/images/dinner/dinner_000061.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '62',
                    img: require(`@/assets/images/dinner/dinner_000062.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '63',
                    img: require(`@/assets/images/dinner/dinner_000063.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '64',
                    img: require(`@/assets/images/dinner/dinner_000064.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '65',
                    img: require(`@/assets/images/dinner/dinner_000065.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '66',
                    img: require(`@/assets/images/dinner/dinner_000066.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '67',
                    img: require(`@/assets/images/dinner/dinner_000067.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '68',
                    img: require(`@/assets/images/dinner/dinner_000068.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '69',
                    img: require(`@/assets/images/dinner/dinner_000069.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '70',
                    img: require(`@/assets/images/dinner/dinner_000070.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '71',
                    img: require(`@/assets/images/dinner/dinner_000071.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '72',
                    img: require(`@/assets/images/dinner/dinner_000072.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '73',
                    img: require(`@/assets/images/dinner/dinner_000073.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '74',
                    img: require(`@/assets/images/dinner/dinner_000074.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '75',
                    img: require(`@/assets/images/dinner/dinner_000075.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '76',
                    img: require(`@/assets/images/dinner/dinner_000076.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '77',
                    img: require(`@/assets/images/dinner/dinner_000077.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '78',
                    img: require(`@/assets/images/dinner/dinner_000078.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '79',
                    img: require(`@/assets/images/dinner/dinner_000079.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '80',
                    img: require(`@/assets/images/dinner/dinner_000080.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '81',
                    img: require(`@/assets/images/dinner/dinner_000081.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '82',
                    img: require(`@/assets/images/dinner/dinner_000082.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '83',
                    img: require(`@/assets/images/dinner/dinner_000083.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '84',
                    img: require(`@/assets/images/dinner/dinner_000084.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '85',
                    img: require(`@/assets/images/dinner/dinner_000085.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '86',
                    img: require(`@/assets/images/dinner/dinner_000086.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '87',
                    img: require(`@/assets/images/dinner/dinner_000087.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '88',
                    img: require(`@/assets/images/dinner/dinner_000088.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '89',
                    img: require(`@/assets/images/dinner/dinner_000089.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
            ],
            postsall: [
                {
                    id: '36',
                    img: require(`@/assets/images/blog/blog-36.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item36.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '35',
                    img: require(`@/assets/images/blog/blog-35.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item35.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '34',
                    img: require(`@/assets/images/blog/blog-34.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item34.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '33',
                    img: require(`@/assets/images/blog/blog-33.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item33.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '32',
                    img: require(`@/assets/images/blog/blog-32.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item32.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '31',
                    img: require(`@/assets/images/blog/blog-31.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item31.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '30',
                    img: require(`@/assets/images/blog/blog-30.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item30.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '29',
                    img: require(`@/assets/images/blog/blog-29.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item29.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '28',
                    img: require(`@/assets/images/blog/blog-28.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item28.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '27',
                    img: require(`@/assets/images/blog/blog-27.png`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: this.$t('casepage.item27.title'),
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                // {
                //     id: '24',
                //     img: require(`@/assets/images/blog/blog-24.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item24.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '25',
                //     img: require(`@/assets/images/blog/blog-25.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item25.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '26',
                //     img: require(`@/assets/images/blog/blog-26.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item26.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '21',
                //     img: require(`@/assets/images/blog/blog-21.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item21.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '22',
                //     img: require(`@/assets/images/blog/blog-22.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item22.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '23',
                //     img: require(`@/assets/images/blog/blog-23.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item23.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '15',
                //     img: require(`@/assets/images/blog/blog-15.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item15.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '16',
                //     img: require(`@/assets/images/blog/blog-16.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item16.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '17',
                //     img: require(`@/assets/images/blog/blog-17.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item17.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '18',
                //     img: require(`@/assets/images/blog/blog-18.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item18.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '19',
                //     img: require(`@/assets/images/blog/blog-19.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item19.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '20',
                //     img: require(`@/assets/images/blog/blog-20.png`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item20.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '1',
                //     img: require(`@/assets/images/blog/blog-11.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item11.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '2',
                //     img: require(`@/assets/images/blog/blog-12.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item12.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '3',
                //     img: require(`@/assets/images/blog/blog-13.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item13.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '4',
                //     img: require(`@/assets/images/blog/blog-14.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item14.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '5',
                //     img: require(`@/assets/images/blog/blog-06.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item6.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '6',
                //     img: require(`@/assets/images/blog/blog-07.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item7.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '7',
                //     img: require(`@/assets/images/blog/blog-08.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item8.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '8',
                //     img: require(`@/assets/images/blog/blog-09.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item9.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '9',
                //     img: require(`@/assets/images/blog/blog-10.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '',
                //     publishedAt: '',
                //     title: this.$t('casepage.item10.title'),
                //     excerpt: '',
                //     body: '',
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
                // {
                //     id: '10',
                //     img: require(`@/assets/images/blog/blog-04.jpg`),
                //     author: '',
                //     authorId: '4',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('casepage.item4.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Marketing', 'UX Design', 'Business'],
                //     tags: ['App', 'Business', 'Software', 'Design']
                // },
                // {
                //     id: '11',
                //     img: require(`@/assets/images/blog/blog-05.jpg`),
                //     author: '',
                //     authorId: '5',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('casepage.item5.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['App Development', 'UX Design', 'Application', 'Art'],
                //     tags: ['Company', 'Corporate', 'Development', 'Design']
                // },
                // // {
                // //     id: '8',
                // //     img: require(`@/assets/images/blog/blog-011.jpg`),
                // //     author: '',
                // //     authorId: '1',
                // //     view: '70 views',
                // //     publishedAt: '',
                // //     title: this.$t('casepage.item1.title'),
                // //     excerpt: 'We have always known the power of digital we\'ve.',
                // //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                // //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                // //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                // //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                // //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                // //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                // //     categories: ['Development', 'Company', 'Marketing'],
                // //     tags: ['Corporate', 'Agency', 'Creative', 'Design']
                // // },
                // {
                //     id: '12',
                //     img: require(`@/assets/images/blog/blog-022.jpg`),
                //     author: '',
                //     authorId: '2',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('casepage.item2.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['UX Design', 'Company'],
                //     tags: ['Minimal', 'Company', 'Development', 'Design']
                // },
                // {
                //     id: '13',
                //     img: require(`@/assets/images/blog/blog-033.jpg`),
                //     author: '',
                //     authorId: '3',
                //     view: '70 views',
                //     publishedAt: '',
                //     title: this.$t('casepage.item3.title'),
                //     excerpt: 'We have always known the power of digital we\'ve.',
                //     body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                //            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                //            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                //            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                //            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                //     categories: ['Development', 'UX Design', 'Business', 'Company'],
                //     tags: ['App Landing', 'Startup', 'Development', 'Design']
                // },
            ],
            galaDinner:[
                {
                    id: '1',
                    img: require(`@/assets/images/gala/gala_0000001.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t(''),
                    excerpt: '',
                    body: ``,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '1',
                    img: require(`@/assets/images/gala/gala_0000002.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t(''),
                    excerpt: '',
                    body: ``,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '1',
                    img: require(`@/assets/images/gala/gala_0000003.png`),
                    author: '',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '',
                    title: this.$t(''),
                    excerpt: '',
                    body: ``,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
            ],
            galaAll:[
                {
                    id: '1',
                    img: require(`@/assets/images/gala/detailed/detailed_00000001.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '2',
                    img: require(`@/assets/images/gala/detailed/detailed_00000002.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '3',
                    img: require(`@/assets/images/gala/detailed/detailed_00000003.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '4',
                    img: require(`@/assets/images/gala/detailed/detailed_00000004.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '5',
                    img: require(`@/assets/images/gala/detailed/detailed_00000005.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '6',
                    img: require(`@/assets/images/gala/detailed/detailed_00000006.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '7',
                    img: require(`@/assets/images/gala/detailed/detailed_00000007.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '8',
                    img: require(`@/assets/images/gala/detailed/detailed_00000008.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '9',
                    img: require(`@/assets/images/gala/detailed/detailed_00000009.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '10',
                    img: require(`@/assets/images/gala/detailed/detailed_00000010.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '11',
                    img: require(`@/assets/images/gala/detailed/detailed_00000011.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '12',
                    img: require(`@/assets/images/gala/detailed/detailed_00000012.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '13',
                    img: require(`@/assets/images/gala/detailed/detailed_00000013.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '14',
                    img: require(`@/assets/images/gala/detailed/detailed_00000014.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '15',
                    img: require(`@/assets/images/gala/detailed/detailed_00000015.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '16',
                    img: require(`@/assets/images/gala/detailed/detailed_00000016.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '17',
                    img: require(`@/assets/images/gala/detailed/detailed_00000017.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '18',
                    img: require(`@/assets/images/gala/detailed/detailed_00000018.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '19',
                    img: require(`@/assets/images/gala/detailed/detailed_00000019.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '20',
                    img: require(`@/assets/images/gala/detailed/detailed_00000020.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '21',
                    img: require(`@/assets/images/gala/detailed/detailed_00000021.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '22',
                    img: require(`@/assets/images/gala/detailed/detailed_00000022.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                }, 
                {
                    id: '23',
                    img: require(`@/assets/images/gala/detailed/detailed_00000023.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '24',
                    img: require(`@/assets/images/gala/detailed/detailed_00000024.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '25',
                    img: require(`@/assets/images/gala/detailed/detailed_00000025.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '26',
                    img: require(`@/assets/images/gala/detailed/detailed_00000026.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '27',
                    img: require(`@/assets/images/gala/detailed/detailed_00000027.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '28',
                    img: require(`@/assets/images/gala/detailed/detailed_00000028.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '29',
                    img: require(`@/assets/images/gala/detailed/detailed_00000029.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '30',
                    img: require(`@/assets/images/gala/detailed/detailed_00000030.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '31',
                    img: require(`@/assets/images/gala/detailed/detailed_00000031.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '32',
                    img: require(`@/assets/images/gala/detailed/detailed_00000032.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '33',
                    img: require(`@/assets/images/gala/detailed/detailed_00000033.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '34',
                    img: require(`@/assets/images/gala/detailed/detailed_00000034.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '35',
                    img: require(`@/assets/images/gala/detailed/detailed_00000035.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '36',
                    img: require(`@/assets/images/gala/detailed/detailed_00000036.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '37',
                    img: require(`@/assets/images/gala/detailed/detailed_00000037.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '38',
                    img: require(`@/assets/images/gala/detailed/detailed_00000038.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '39',
                    img: require(`@/assets/images/gala/detailed/detailed_00000039.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '40',
                    img: require(`@/assets/images/gala/detailed/detailed_00000040.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '41',
                    img: require(`@/assets/images/gala/detailed/detailed_00000041.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '42',
                    img: require(`@/assets/images/gala/detailed/detailed_00000042.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '43',
                    img: require(`@/assets/images/gala/detailed/detailed_00000043.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '44',
                    img: require(`@/assets/images/gala/detailed/detailed_00000044.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '45',
                    img: require(`@/assets/images/gala/detailed/detailed_00000045.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '46',
                    img: require(`@/assets/images/gala/detailed/detailed_00000046.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '47',
                    img: require(`@/assets/images/gala/detailed/detailed_00000047.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '48',
                    img: require(`@/assets/images/gala/detailed/detailed_00000048.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '49',
                    img: require(`@/assets/images/gala/detailed/detailed_00000049.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '50',
                    img: require(`@/assets/images/gala/detailed/detailed_00000050.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '51',
                    img: require(`@/assets/images/gala/detailed/detailed_00000051.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '52',
                    img: require(`@/assets/images/gala/detailed/detailed_00000052.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '53',
                    img: require(`@/assets/images/gala/detailed/detailed_00000053.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '54',
                    img: require(`@/assets/images/gala/detailed/detailed_00000054.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '55',
                    img: require(`@/assets/images/gala/detailed/detailed_00000055.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '56',
                    img: require(`@/assets/images/gala/detailed/detailed_00000056.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '57',
                    img: require(`@/assets/images/gala/detailed/detailed_00000057.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '58',
                    img: require(`@/assets/images/gala/detailed/detailed_00000058.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '59',
                    img: require(`@/assets/images/gala/detailed/detailed_00000059.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '60',
                    img: require(`@/assets/images/gala/detailed/detailed_00000060.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '61',
                    img: require(`@/assets/images/gala/detailed/detailed_00000061.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '62',
                    img: require(`@/assets/images/gala/detailed/detailed_00000062.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '63',
                    img: require(`@/assets/images/gala/detailed/detailed_00000063.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '64',
                    img: require(`@/assets/images/gala/detailed/detailed_00000064.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '65',
                    img: require(`@/assets/images/gala/detailed/detailed_00000065.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '66',
                    img: require(`@/assets/images/gala/detailed/detailed_00000066.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '67',
                    img: require(`@/assets/images/gala/detailed/detailed_00000067.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '68',
                    img: require(`@/assets/images/gala/detailed/detailed_00000068.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '69',
                    img: require(`@/assets/images/gala/detailed/detailed_00000069.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '70',
                    img: require(`@/assets/images/gala/detailed/detailed_00000070.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '71',
                    img: require(`@/assets/images/gala/detailed/detailed_00000071.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '72',
                    img: require(`@/assets/images/gala/detailed/detailed_00000072.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '73',
                    img: require(`@/assets/images/gala/detailed/detailed_00000073.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '74',
                    img: require(`@/assets/images/gala/detailed/detailed_00000074.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '75',
                    img: require(`@/assets/images/gala/detailed/detailed_00000075.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '76',
                    img: require(`@/assets/images/gala/detailed/detailed_00000076.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '77',
                    img: require(`@/assets/images/gala/detailed/detailed_00000077.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '78',
                    img: require(`@/assets/images/gala/detailed/detailed_00000078.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '79',
                    img: require(`@/assets/images/gala/detailed/detailed_00000079.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '80',
                    img: require(`@/assets/images/gala/detailed/detailed_00000080.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '81',
                    img: require(`@/assets/images/gala/detailed/detailed_00000081.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '82',
                    img: require(`@/assets/images/gala/detailed/detailed_00000082.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '83',
                    img: require(`@/assets/images/gala/detailed/detailed_00000083.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '84',
                    img: require(`@/assets/images/gala/detailed/detailed_00000084.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '85',
                    img: require(`@/assets/images/gala/detailed/detailed_00000085.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '86',
                    img: require(`@/assets/images/gala/detailed/detailed_00000086.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '87',
                    img: require(`@/assets/images/gala/detailed/detailed_00000087.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '88',
                    img: require(`@/assets/images/gala/detailed/detailed_00000088.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '89',
                    img: require(`@/assets/images/gala/detailed/detailed_00000089.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '90',
                    img: require(`@/assets/images/gala/detailed/detailed_00000090.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '91',
                    img: require(`@/assets/images/gala/detailed/detailed_00000091.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '92',
                    img: require(`@/assets/images/gala/detailed/detailed_00000092.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '93',
                    img: require(`@/assets/images/gala/detailed/detailed_00000093.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '94',
                    img: require(`@/assets/images/gala/detailed/detailed_00000094.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '95',
                    img: require(`@/assets/images/gala/detailed/detailed_00000095.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '96',
                    img: require(`@/assets/images/gala/detailed/detailed_00000096.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '97',
                    img: require(`@/assets/images/gala/detailed/detailed_00000097.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '98',
                    img: require(`@/assets/images/gala/detailed/detailed_00000098.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '99',
                    img: require(`@/assets/images/gala/detailed/detailed_00000099.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    id: '100',
                    img: require(`@/assets/images/gala/detailed/detailed_00000100.jpg`),
                    author: '',
                    authorId: '3',
                    view: '',
                    publishedAt: '',
                    title: '',
                    excerpt: '',
                    body: '',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
            ],
            categories: '',
            tags: '',
            AppFunctions
        }
    },
    methods: {
        blogCategories() {
            let cats = this.posts.map(item => {
                    return item.categories;
                }),
                singleCatArray = AppFunctions.flatDeep(cats),
                categories = [];

            singleCatArray.forEach(cat => {
                const obj = {
                    title: cat.trim(),
                    slug: AppFunctions.slugify(cat),
                    count: 1
                }
                const objIndex = AppFunctions.containsObject(obj, categories);
                if (objIndex !== -1) {
                    const prevCount = categories[objIndex].count;
                    categories[objIndex] = {
                        title: cat.trim(),
                        slug: AppFunctions.slugify(cat),
                        count: prevCount + 1
                    }
                } else {
                    categories.push(obj);
                }
            });

            this.categories = categories;
        },
        blogTags() {
            let tags = this.posts.map(item => {
                    return item.tags;
                }),
                singleTagArray = AppFunctions.flatDeep(tags),
                tagsData = [];

            singleTagArray.forEach(tag => {
                const obj = {
                        title: tag.trim(),
                        slug: AppFunctions.slugify(tag)
                    },
                    objIndex = AppFunctions.containsObject(obj, tagsData);

                if (objIndex !== -1) {
                    tagsData[objIndex] = {
                        title: tag.trim(),
                        slug: AppFunctions.slugify(tag)
                    }
                } else {
                    tagsData.push(obj);
                }
            });

            this.tags = tagsData;
        }
    },
    created() {
        this.blogCategories();
        this.blogTags();
    }
}