<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false" buy-button-class="btn-default btn-small">
        <Breadcrumb :title="$t('BlogGalaDinner.dinnerGrid.title')" />

        <!-- <div class="content">
            <WaterfallEasy :imgsArr="this.imgsArr" />
                 <div slot="waterfall-head"></div>
                @scrollReachBottom="getData"
        </div> -->

        
        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row mt_dec--30">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div class="col-lg-6 col-md-6 col-12 mt--30"
                                 data-aos="slide-up"
                                 data-aos-duration="800"
                                 :data-aos-delay="100 + index"
                                 v-for="(blog, index) in galaAll"
                                 :key="index">
                                <BlogDinnerPost :blog="blog"/>
                            </div>                                                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import BlogDinnerPost from '../components/blog/BlogDinnerPost'
    import Icon from '../components/icon/Icon'
    import BlogPostMixin from '../mixins/BlogPostMixin'

    import WaterfallEasy from 'vue-waterfall-easy'

    export default {
        name: 'BlogGalaDinnerGridPage',
        components: {Icon, BlogDinnerPost, Layout, Breadcrumb, WaterfallEasy},
        mixins: [BlogPostMixin],

        data() {
            return {
                imgsArr: [],
            }
        },
        created(){
            this.getData();
        },
        methods:{
            getData() {
                let len = this.dinnerall.length ;
                for(let i = 0 ; i < len ; i++)
                {
                    //console.log('11111 ' +　this.dinnerall[i].img);
                    this.imgsArr.push({
                        id: this.dinnerall[i].id,
                        src: this.dinnerall[i].img,
                        link: '',
                        info: '111',
                    });
                }
                // this.imgsArr[0].src = '../../assets/images/dinner/dinner_000001.jpg';
                // this.imgsArr[1].src = '../../assets/images/dinner/dinner_000002.jpg';
                // this.imgsArr[2].src = '../../assets/images/dinner/dinner_000003.jpg';
                // this.imgsArr[3].src = '../../assets/images/dinner/dinner_000004.jpg';
                // this.imgsArr[4].src = '../../assets/images/dinner/dinner_000005.jpg';
                // this.imgsArr[5].src = '../../assets/images/dinner/dinner_000006.jpg';
            }
        },
    }
</script>

<style scoped>
.content {
    position: absolute;
    left: 50px;
    top:80px;
    width: 80%;
}
</style>