//法语
export const head = {
  home: "Maison",
  team: "Équipe",
  case: "Cas",
  industry: "Industrie",
  contact: "Contact",
  about: "À propos"
};
  
export const foot = {
  services: {
      title: "Prestations de service",
      home: "Maison"
  },
  case: {
      title: "Cas",
      case: "Cas"
  },
  company: {
      title: "Entreprise",
      contact: "Contact",
      about: "À propos"
  },
  resources: {
      title: "Ressources",
      industry: "Industrie"
  },
  stay: {
      title: "Rester avec nous.",
      desc: "2000+ Nos clients sont abonnés dans le monde entier"
  },
  copyright: {
      contact: "Contactez-nous"
  }
};

export const home = {
  banner22: {
    title: "Félicitations chaleureuses",
    desc: "Le dîner à Buenos Aires a été un succès total."
  },
  banner1: {
      title: "Consortium CHEVALIER",
      desc: "Des investisseurs particuliers aux banquiers, le leader du KNight Consortium"
  },
  banner2: {
      title: "Notre engagement",
      desc: "Concurrence loyale dans le secteur financier"
  },
  banner3: {
      title: "Les messieurs aiment la fortune, d'une manière appropriée",
      desc: "Le Knight Consortium est prêt à guider ce large chemin vers davantage de personnes à découvrir, afin que chacun puisse atteindre la prospérité et la prospérité initiales, puis atteindre la réalisation de soi !"
  },
  servicefive: {
      title: "Le progrès, le développement et le soin sont les valeurs fondamentales du KNight Consortium",
    //   desc: "We are committed to promoting global economic relations and economic development. By integrating global knowledge, technology, funds and talents, we have established overseas service teams and provided funds to help areas lacking in agriculture, industry, economic construction, medical care and education around the world enjoy the same development opportunities. , work together to create a future vision of equality, dignity, prosperity and health.",
      desc: "",
      item1: {
          title: "Profil de l'entreprise",
        //   desc: "KNight Consortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.",
        desc: "Le Consortium KNight a été créé à Singapour en 2010 avec un capital initial d'environ 5 millions de dollars américains et dispose désormais d'un capital géré de 1 milliard de dollars américains. Nous nous engageons à promouvoir les relations économiques mondiales et le développement économique, et à établir des équipes de service à l'étranger en intégrant des connaissances mondiales. , technologie, fonds et talents."
      },
      item2: {
          title: "Culture d’entreprise",
          desc: "L'objectif fondateur du KNight Consortium est de rassembler l'amour, de transmettre de la chaleur, d'aider les gens à participer à des œuvres caritatives et de partager ensemble une vie meilleure et des opportunités de développement. Promouvoir les connexions à travers le monde et fournir une assistance aux réfugiés dans les pays et régions touchés par des catastrophes naturelles.<br><br><br>",
      },
      item3: {
          title: "Vision de l'entreprise",
          desc: "Les objectifs et les connotations importantes du développement des affaires avec la coopération internationale et le développement durable constituent la vision de l'organisation. Dans cette direction, nous travaillons avec la communauté internationale, la société civile et d’autres organismes d’aide au développement pour intégrer activement les connaissances, la technologie, les fonds et les talents afin de créer un avenir d’égalité, de dignité, de prospérité et de santé.",
      }
  },
  portfolio: {
      title: "Industrie de l'investissement du Consortium KNight et cas de réussite",
      desc: "Nous nous efforçons de promouvoir le développement économique et social mondial et les relations économiques, et de créer des équipes de services à l'étranger pour aider au développement de la construction médicale, agricole, industrielle, économique, de l'éducation, etc.",
      cat1: {
          name: "finance",
          item1: {
              title: "L'équipe d'investissement de KNight Consortium a acheté des actions dans un certain nombre de sociétés minières de métaux précieux. Ces sociétés ont enregistré de bons résultats en 2012, car la hausse des prix des métaux précieux a entraîné une hausse du cours de leurs actions, ce qui a entraîné d'énormes gains pour le syndicat KNights.",
          },
          item2: {
              title: "Entre janvier et février 2012, le prix international de l’or a fluctué. Les analystes financiers du Consortium KNight ont saisi l'opportunité d'acheter à bas prix et ont réalisé près de 60 % de bénéfices en seulement trois mois.",
          }
      },
      cat2: {
          name: "medical",
          item1: {
              title: "En 2020, le centre de services médicaux du consortium KNight a été créé et la méthode de traitement « immunothérapie tumorale » développée conjointement avec Merck Pharmaceutical Group a été couronnée de succès.",
          },
          item2: {
              title: "Le centre de services médicaux du consortium KNight et Novartis Pharmaceuticals ont développé le médicament ciblant le cancer Houng-592",
          }
      },
      cat3: {
          name: "metaverse",
          item1: {
              title: "En 2021, le centre web3.0 du Consortium KNight a été créé pour créer une plateforme sociale décentralisée, qui a attiré une large attention et a été bien accueillie par les utilisateurs.",
          },
          item2: {
              title: "Lors du marché haussier des crypto-monnaies en janvier 2017, le Consortium KNight a saisi l'opportunité et a amené les membres de la fondation à réaliser des bénéfices élevés, et les actifs de tous les membres ont rapidement doublé.",
          }
      }
  },
  counterdata: {
    title: "Statut actuel et projets futurs du Consortium KNight",
    desc: "",
    item1: {
        title: 'Fonds',
        unit: "Milliard",
    },
    item2: {
        title: 'Utilisateurs',
        unit: "Milliard",
    },
    item3: {
        title: 'Investir',
        unit: "Partner",
    },
    item4: {
        title: 'Faire un don',
        unit: "Milliard",
    }
  },
  leader: {
      title: "Présentation des membres de l'Alliance du Consortium KNight",
      desc: "Les membres du capital syndiqué investissent ensemble, font des œuvres caritatives, atteignent la liberté financière et contribuent à un monde meilleur.",
      item1: {
          name: "Zhang Guofu",
          desc: "Titulaire d'un master en économie financière de l'Université d'Oxford, Royaume-Uni. En août 2010, il a fondé la KNight Consortium Capital Foundation et a été président de Consortium Capital."
      },
      item2: {
          name: "Remus John Worm",
          desc: "Master en économie de la School of Economics de l'Université de Chicago. A été vice-président de la Fondation KNight Capital <br><br><br>"
      },
      item3: {
          name: "Li Rongcheng",
          desc: "Maîtrise en sciences et maîtrise en administration des affaires de l'Université d'Ottawa. A été vice-président de la Fondation KNight Capital<br><br>"
      }
  },
  blogpost: {
    title: "Bien-être social du Consortium KNight",
    desc: "La richesse apportera certainement un espoir et un amour fluides. Le Consortium KNight suit l'exemple des magnats de la finance étrangère et utilise une partie des revenus de la fondation à des fins caritatives pour soulager le siège et aider au développement, permettant au Consortium KNight de gagner une renommée internationale et la confiance d'un plus grand nombre d'utilisateurs et d'investisseurs.",
    item1: {
        // title: "After the 7.8-magnitude earthquake in Turkey in February 2023, the KNight Consortium donated US$2.3 million to refugees"
        title: 'Le don de matériel est notre aide la plus directe et la plus pratique aux victimes. En février 2023, la Turquie a subi un séisme de magnitude 7,8 et la Knights Foundation a fait don de 2,3 millions de dollars aux réfugiés pour qu\'ils reconstruisent leurs maisons.',
    },
    item2: {
        // title: "In July 2021, heavy rains caused floods in Zhengzhou, Henan Province, China. The KNight Consortium donated US$1.8 million;"
        title: 'En septembre 2022, l\'équipe caritative de la Fondation Knight s\'est rendue en Afrique pour fournir des soins et de l\'aide aux enfants pauvres et veiller à ce que leurs besoins vitaux fondamentaux soient satisfaits. La méthode de financement entre pairs peut améliorer plus directement la vie des enfants et leur donner espoir et encouragement.',
    },
    item3: {
        // title: "During the 2019 Australian forest fires, Consortium Capital donated US$1.2 million for relief supplies and recovery."
        title: 'En février 2023, la Turquie a subi un séisme de magnitude 7,8, qui a entraîné de graves dégâts et de nombreuses victimes dans de nombreuses régions. La Fondation Knight a envoyé des équipes de secours pour secourir les victimes, leur donner chaleur et espoir et leur faire ressentir la chaleur et les soins de la famille sociale.',
    },
    item4: {
      title: 'Les civils n\'ont reçu aucune protection pendant la guerre israélo-palestinienne. Le Consortium des Chevaliers a veillé à ce que la population reçoive une assistance de base et a activement fourni d\'importants travailleurs humanitaires.',
    },
    item5: {
      title: 'Dans la guerre israélo-palestinienne, l’ouverture des lignes d’approvisionnement et l’aide humanitaire sont des besoins critiques et urgents. Le Consortium des Chevaliers profite de cette période fenêtre pour livrer des fournitures d\'aide essentielles au bien-être de la population de Gaza, et se coordonne avec les organisations humanitaires internationales pour assurer l\'arrivée des fournitures d\'aide.',
    },
    item6: {
      title: 'Alors que la guerre israélo-palestinienne se poursuit, le nombre de victimes ne cesse d\'augmenter. Le sauvetage des victimes est imminent et le Knight Consortium organise activement des équipes de secours pour assurer un secours immédiat.',
    },
    item7: {
        title: 'Le Groupe Knight a toujours été connu pour son assistance professionnelle. Nous disposons d’une équipe expérimentée qui peut réagir rapidement et fournir des solutions pratiques. Nous opérons tous de manière efficace et transparente pour garantir que chaque aide soit utilisée de manière appropriée et efficace.',
    },
    item8: {
        title: 'Choisir l\'assistance professionnelle de Knight Consortium signifie que vous avez choisi un partenaire fiable, une équipe dédiée à l\'amélioration des vies et à la reconstruction de l\'espoir. Nous ne ménagerons aucun effort pour apporter aux victimes le soutien dont elles ont besoin pour les aider à surmonter les difficultés et à avancer vers un avenir meilleur.',
    },
    item9: {
        title: 'Dans le brouillard de la guerre, la reconstruction d’après-guerre est une étape cruciale que nous devons franchir. La reconstruction des communautés, la réparation des bâtiments endommagés et la réinsertion des victimes dans la société nécessitent une assistance professionnelle et un soutien engagé.',
    },
    item10: {
        title: 'La Fondation Knights est immédiatement venue à la rescousse et a apporté son aide aux victimes de la guerre palestino-israélienne. Face aux défis de la guerre israélo-palestinienne, notre Fondation des Chevaliers se tient fermement aux côtés des victimes. En tant qu’équipe engagée dans l’équité financière et la responsabilité sociale, nous avons toujours considéré le soin et l’assistance comme l’une de nos responsabilités.',
    },
    item11: {
        title: 'Dans la guerre israélo-palestinienne, nous sommes bien conscients des défis et des difficultés rencontrés par les victimes. Nous avons pris des mesures d’urgence pour fournir une aide matérielle urgente aux zones touchées. Ces fournitures comprennent de la nourriture, de l\'eau, des fournitures médicales et du matériel de secours d\'urgence et sont conçues pour fournir une aide et un soutien indispensables aux personnes touchées par la catastrophe.',
    },
    item12: {
        title: 'La Fondation Knights s\'engage depuis longtemps à soutenir la revitalisation d\'après-guerre. Il est crucial de reconstruire les communautés et d’aider les personnes touchées. C’est pourquoi nous n’hésitons pas à tendre la main pour garantir que les personnes touchées par la guerre puissent reconstruire leur vie.',
    },
    item13: {
      title: 'Dans le brouillard de la guerre, nous nous sommes donné la main pour porter secours aux victimes. La Fondation Knights a toujours fait preuve d\'un sens de responsabilité sociale et s\'engage à aider les personnes touchées par la guerre. Éclairons la vie des victimes de la guerre avec la lumière de l’espoir !',
    },
    item14: {
      title: 'Dans les moments difficiles qui ont suivi la guerre, le Consortium des Chevaliers est prêt à être votre partenaire fiable et à vous joindre à la mission de sauvetage et de reconstruction. Nous savons que chaque victime d\'une catastrophe a besoin de soins et d\'assistance, c\'est pourquoi nous participons activement à diverses opérations de secours pour apporter le soutien nécessaire à ceux qui souffrent.',
    },
    item15: {
      title: 'La Fondation Knights s\'est engagée à diffuser cet esprit. Par le biais d\'actions caritatives, de projets d\'aide et de bénévolat, nous nous efforçons d\'améliorer les vies, de soutenir les communautés et de rendre le monde plus chaleureux. Ce n’est qu’en travaillant ensemble que nous pourrons créer un avenir meilleur et plein d’espoir.',
    },
    item16: {
        title: 'L\'Argentine a été frappée par une tempête extrêmement violente, entraînant la perte de 16 vies innocentes. Le Consortium Knight a été profondément attristé et a répondu immédiatement, et notre équipe professionnelle a immédiatement participé aux travaux de secours en cas de catastrophe. Pendant cette urgence, nous restons déterminés à soutenir les zones touchées.',
    },
    item17: {
        title: 'Nous avons appris qu\'un séisme de magnitude 6,2 s\'est produit à Gansu, en Chine, et que cette catastrophe a causé une grande détresse aux résidents locaux. En cette période difficile, la Fondation Knights se tient aux côtés des populations sinistrées et présente ses plus sincères condoléances et son soutien.',
    },
    item18: {
        title: 'Il y a des moments dans la vie qui nécessitent plus de soins et de soutien. La Fondation Knight assume la responsabilité sociale comme sa propre responsabilité. Nous croyons fermement qu\'aider ceux qui en ont besoin est un élément important de la construction d\'une société meilleure, et nous serons toujours à vos côtés.',
    },
    item19: {
        title: 'Le Knight Consortium est sur le point de repartir ! En regardant le lait, l\'huile, les nouilles, les biscuits et autres aliments soigneusement disposés sur le sol, ils étaient sur le point de les livrer à une famille locale dans le besoin. En voyant leurs visages heureux, nous savons combien de chaleur et d’espoir ces fournitures apporteront. Un amour, une chaleur, la Fondation Knight interprète la responsabilité sociale par des actions, attendons avec impatience la touche apportée par cette livraison.',
    },
    item20: {
        title: 'Récemment, nous avons vu une grande équipe de membres du consortium de chevaliers, pleins d\'énergie et de passion, transporter des charges de nourriture vers les communautés locales pour rendre visite aux femmes, aux personnes âgées et aux enfants. Chaque membre de cette équipe dégage un enthousiasme et une attention sans limites, et leur présence injecte un courant chaleureux dans la communauté. Au cours de l\'événement, nous avons vu des jeunes hommes et femmes interagir cordialement avec des personnes âgées et des enfants, leurs sourires étaient remplis de bonheur et de toucher. Qu’il s’agisse de distribuer de la nourriture ou de discuter, chacun s’implique et apporte chaleur et attention aux autres avec cœur. Cette équipe dynamique du Groupe Financier Knight démontre un esprit d\'unité et d\'amitié et injecte un fort amour dans la communauté.',
    },
    item21: {
        title: 'Récemment, un groupe de membres du Knight Consortium a effectué une visite touchante. Ils se sont rendus sur place et ont rendu visite aux femmes, aux personnes âgées et aux enfants, leur apportant chaleur et soins. Cet événement n\'est pas une simple visite, mais aussi le reflet d\'une attention et d\'un soutien sincères envers la communauté. Au cours de l’événement, les membres de la Fondation Knights ont eu des conversations cordiales avec les personnes interrogées et ont partagé leurs histoires et leurs expériences. Les personnes âgées souriaient, les enfants riaient et les femmes étaient reconnaissantes. Ils ont écouté attentivement et accompagné avec amour, faisant ressentir à chaque personne interrogée la chaleur et l\'attention de la communauté.',
    },
    item22: {
        title: 'Par une journée ensoleillée, l\'équipe de San Juan du Knight Consortium a rendu visite avec tous les soins aux familles locales dans le besoin. Les membres de l\'équipe ont distribué des produits de première nécessité tels que du riz, de l\'huile de cuisson, des nouilles creuses, des boîtes de conserve, du pain et des biscuits, et ont adressé des condoléances cordiales et des conversations avec chaque famille. La distribution matérielle de cette période n\'est pas seulement un soutien pratique pour les besoins quotidiens, mais aussi un réconfort spirituel pour chaque famille. Les bénévoles des Chevaliers partagent des moments chaleureux avec les familles aidées, se transmettant mutuellement soins et espoir. Les familles aidées ont exprimé leur gratitude envers les bénévoles des Chevaliers, cette chaleur les a rendus pleins de confiance et d\'attentes pour l\'avenir. Lorsque les bénévoles sont partis, les familles ont envoyé leurs sincères bénédictions et ont remercié l\'équipe de San Juan de la Knights Foundation pour les soins et la chaleur qu\'elle leur a apportés.',
    },
    item23: {
        title: 'Les membres de la Fondation des Chevaliers ont tendu la main à plusieurs familles pauvres locales et leur ont envoyé d\'abondantes nécessités quotidiennes ! Lors d\'un événement passé, des bénévoles enthousiastes de la Fondation Knights se sont rendus dans la région avec d\'abondantes fournitures telles que de l\'huile de cuisson, des nouilles, du blé et de la farine, et ont livré ces produits de première nécessité à plusieurs familles pauvres. Il ne s\'agit pas seulement d\'un simple don, mais aussi de sincères condoléances et d\'une communication. Tout en recevant cette chaleur, les familles aidées ont exprimé leur profonde gratitude envers les bénévoles Chevaliers. Les difficultés de la vie semblent devenir plus chaleureuses et pleines d\'espoir et d\'attente grâce à ces soins.',
    },
    item24: {
        title: 'Les bénévoles de la Fondation Knight se sont lancés dans un touchant voyage caritatif avec des produits de première nécessité tels que du pain, de l\'huile, du lait, du sucre, etc. Ils se sont rendus au plus profond des zones montagneuses et ont apporté une aide concrète aux familles dans le besoin qui vivaient sur les flancs des collines et disposaient de moyens de transport limités. Cette distribution de matériel couvre tous les aspects de la vie quotidienne des familles, notamment l\'huile de cuisson, la farine, le lait, le sucre, etc., garantissant que chaque famille puisse ressentir les soins chaleureux de la Fondation des Chevaliers. Les bénévoles ne sont pas seulement des transporteurs de fournitures, mais aussi des vecteurs de camaraderie, de condoléances et de communication. Les familles aidées vivent dans des régions montagneuses isolées et sont souvent confrontées à des difficultés telles qu\'un transport peu pratique et des conditions de vie difficiles. Par conséquent, l’arrivée des volontaires Chevaliers était pour eux une bénédiction opportune et les remplissait de gratitude. Tout le monde a partagé les histoires des autres au cours de la conversation, rendant cet événement caritatif plus chaleureux et plus significatif.',
    },
    item25: {
      title: 'Récemment, le soleil brillait fort et les membres du Knight Consortium sont venus dans la région avec des seaux d\'huile de cuisson et de jus pour donner un coup de main et envoyer chaleur et soins à ceux qui avaient besoin de soutien et d\'aide. En ce beau jour, nous ne sommes pas seulement membres du Consortium des Chevaliers, mais également de la communauté. Nous savons qu\'en tant qu\'équipe, nos responsabilités et nos missions reposent sur nos épaules. Par conséquent, nous sommes prêts à travailler dur et à utiliser des actions pour démontrer notre attention et notre soutien à la communauté. Répandons notre amour ensemble et remplissons le monde de chaleur et d’espoir. Merci pour votre soutien, continuons d\'avancer ensemble et de contribuer au développement et au progrès de la communauté !',
    },
    item26: {
      title: 'Récemment, notre équipe du Knight Consortium est venue à Cordoba, en Argentine, ce bel endroit est plein de chaleur et d\'espoir. Notre équipe de près de 30 membres est réunie ici avec un cœur chaleureux et votre amour et votre soutien, nous aiderons davantage de personnes dans le besoin et leur apporterons chaleur et soins. Dans cette action pleine d\'amour, nous sommes non seulement membre du Consortium des Chevaliers, mais aussi votre porte-parole et messager. Grâce à votre générosité et votre amour, nous pouvons arriver ici et apporter de la gratitude à davantage de personnes. Joignons nos mains pour contribuer à la société et remplir le monde de plus de chaleur et d’espoir. Merci pour votre soutien, continuons à répandre le pouvoir de l\'amour !',
    }
  }
}

export const team = {
    leader1: {
        title: "Président",
        name: "Zhang Guofu",
        desc: "Master en économie financière de l'Université d'Oxford, Royaume-Uni"
    },
    leader2: {
        title: "Vice-président et directeur juridique",
        item1: {
            name: "Remus John Worm",
            desc: "Master en économie, École d'économie de l'Université de Chicago"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Maîtrise en sciences et maîtrise en administration des affaires, Université d'Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Diplômé de l'Université de Pékin, Master en Management"
        }
    },
    leader3: {
        title: "Analyste financier",
        item1: {
            name: "Maxwell",
            desc: "Licence en finance internationale, Université Fudan"
        },
        item2: {
            name: "Yang Jun",
            desc: "Diplômé de l'Université technologique de Nanyang, Singapour, avec spécialisation en finance"
        },
        item3: {
            name: "Hubert",
            desc: "Double diplôme de l'Université de Londres, Royaume-Uni, et de l'Université Harvard, États-Unis"
        }
    },
    leader4: {
        title: "Consultant",
        item1: {
            name: "Анфия",
            desc: "Licence, Université de Floride"
        },
        item2: {
            name: "Xu Hao",
            desc: "Diplôme d'ingénieur de l'Université George Washington"
        },
        item3: {
            name: "Dorothy",
            desc: "MBA de l'Institut indien de technologie"
        }
    },
    leader5: {
        title: "Membre principal",
        item1: {
            name: "sun horse",
            desc: "Étudier l'économie à Royal Holloway, Université de Londres, Royaume-Uni"
        },
        item2: {
            name: "Yvonne",
            desc: "Master de l'Université Libre de Bruxelles"
        },
        item3: {
            name: "Yan Tao",
            desc: "Master de l'Université de Washington (diplôme en gestion et ingénierie)"
        }
    }
}

export const casepage = {
    title: "Introduction à la protection sociale",
    item1: {
        title: "Après le séisme de magnitude 7,8 en Turquie en février 2023, le consortium KNight a fait don de 2,3 millions de dollars aux réfugiés"
    },
    item2: {
        title: "En juillet 2021, de fortes pluies ont provoqué des inondations à Zhengzhou, dans la province du Henan, en Chine. Le Consortium KNight a fait un don de 1,8 million de dollars;"
    },
    item3: {
        title: "Lors des incendies de forêt australiens de 2019, Consortium Capital a fait don de 1,2 million de dollars américains pour les secours et le rétablissement.."
    },
    item4: {
        title: "En février 2023, un séisme de magnitude 7,8 a frappé la Turquie, faisant plus de 41 000 morts. 1,5 million de personnes se sont retrouvées sans abri dans la région sud et au moins 500 000 nouvelles maisons ont dû être construites. Dans le nord-ouest de la Syrie, près de 9 millions de personnes ont été touchées et au moins 6 000 personnes ont perdu la vie. Consortium Capital a fait don de 2,3 millions de dollars aux réfugiés pour leur fournir du matériel de subsistance et reconstruire leurs maisons.",
    },
    item5: {
        title: "En juin 2022, le tremblement de terre en Afghanistan a fait plus de 1 000 morts et plus de 2 000 blessés. Le capital du consortium a fait don d'un million de dollars américains pour la reconstruction des logements après la catastrophe, dont une partie a été utilisée pour les nécessités quotidiennes.",
    },
    item6: {
        title: "Le don de matériel est notre aide la plus directe et la plus pratique aux victimes. En février 2023, la Turquie a subi un séisme de magnitude 7,8 et la Knights Foundation a fait don de 2,3 millions de dollars aux réfugiés pour qu'ils reconstruisent leurs maisons.",
    },
    item7: {
        title: "En février 2023, la Turquie a subi un séisme de magnitude 7,8, qui a entraîné de graves dégâts et de nombreuses victimes dans de nombreuses régions. La Fondation Knight a envoyé des équipes de secours pour secourir les victimes, leur donner chaleur et espoir et leur faire ressentir la chaleur et les soins de la famille sociale.",
    },
    item8: {
        title: "Le tremblement de terre en Afghanistan en juin 2022 a tué plus de 1 000 personnes et blessé plus de 2 000 personnes. Le capital du consortium a fait don d'un million de dollars américains pour la reconstruction des logements après la catastrophe, dont une partie a été utilisée pour couvrir les frais de subsistance.",
    },
    item9: {
        title: "La Fondation Knights participe activement à des actions caritatives en faveur des enfants pauvres. Une aide financière et des soins réguliers sont fournis grâce à des dons et des services bénévoles. Ce type de soins et d’aide change non seulement le sort des enfants, mais injecte également plus d’énergie positive dans le développement de la société tout entière.",
    },
    item10: {
        title: "En septembre 2022, l'équipe caritative de la Fondation Knight s'est rendue en Afrique pour prodiguer soins et aide aux enfants pauvres et veiller à ce que leurs besoins vitaux fondamentaux soient satisfaits. La méthode de financement entre pairs peut améliorer plus directement la vie des enfants et leur donner espoir et encouragement.",
    },
    item11: {
        title: "Les civils n'ont reçu aucune protection pendant la guerre israélo-palestinienne. Le Consortium des Chevaliers a veillé à ce que la population reçoive une assistance de base et a activement fourni d'importants travailleurs humanitaires.",
    },
    item12: {
        title: "Dans la guerre israélo-palestinienne, l’ouverture des lignes d’approvisionnement et l’aide humanitaire sont des besoins critiques et urgents. Le Consortium des Chevaliers profite de cette période fenêtre pour livrer des fournitures d'aide essentielles au bien-être de la population de Gaza, et se coordonne avec les organisations humanitaires internationales pour assurer l'arrivée des fournitures d'aide.",
    },
    item13: {
        title: "Alors que la guerre israélo-palestinienne se poursuit, le nombre de victimes ne cesse d'augmenter. Le sauvetage des victimes est imminent et le Knight Consortium organise activement des équipes de secours pour assurer un secours immédiat.",
    },
    item14: {
        title: "En juillet 2023, touchées par le typhon n° 5 « Dusuri », des pluies extrêmement fortes se sont produites à Pékin, Tianjin, Hebei et dans d'autres endroits de Chine, provoquant des inondations catastrophiques. Le Consortium Knight a lancé des secours d'urgence et a fourni des canaux de secours en temps opportun pour aider les personnes âgées et les personnes en détresse à se sortir du pétrin.",
    },
    item15: {
        title: 'La Fondation Knights est immédiatement venue à la rescousse et a apporté son aide aux victimes de la guerre palestino-israélienne. Face aux défis de la guerre israélo-palestinienne, notre Fondation des Chevaliers se tient fermement aux côtés des victimes. En tant qu’équipe engagée dans l’équité financière et la responsabilité sociale, nous avons toujours considéré le soin et l’assistance comme l’une de nos responsabilités.',
    },
    item16: {
        title: 'Dans la guerre israélo-palestinienne, nous sommes bien conscients des défis et des difficultés rencontrés par les victimes. Nous avons pris des mesures d’urgence pour fournir une aide matérielle urgente aux zones touchées. Ces fournitures comprennent de la nourriture, de l\'eau, des fournitures médicales et du matériel de secours d\'urgence et sont conçues pour fournir une aide et un soutien indispensables aux personnes touchées par la catastrophe.',
    },
    item17: {
        title: 'La Fondation Knights s\'engage depuis longtemps à soutenir la revitalisation d\'après-guerre. Il est crucial de reconstruire les communautés et d’aider les personnes touchées. C’est pourquoi nous n’hésitons pas à tendre la main pour garantir que les personnes touchées par la guerre puissent reconstruire leur vie.',
    },
    item18: {
        title: 'Le Groupe Knight a toujours été connu pour son assistance professionnelle. Nous disposons d’une équipe expérimentée qui peut réagir rapidement et fournir des solutions pratiques. Nous opérons tous de manière efficace et transparente pour garantir que chaque aide soit utilisée de manière appropriée et efficace.',
    },
    item19: {
        title: 'Choisir l\'assistance professionnelle de Knight Consortium signifie que vous avez choisi un partenaire fiable, une équipe dédiée à l\'amélioration des vies et à la reconstruction de l\'espoir. Nous ne ménagerons aucun effort pour apporter aux victimes le soutien dont elles ont besoin pour les aider à surmonter les difficultés et à avancer vers un avenir meilleur.',
    },
    item20: {
        title: 'Dans le brouillard de la guerre, la reconstruction d’après-guerre est une étape cruciale que nous devons franchir. La reconstruction des communautés, la réparation des bâtiments endommagés et la réinsertion des victimes dans la société nécessitent une assistance professionnelle et un soutien engagé.',
    },
    item21: {
      title: 'Dans le brouillard de la guerre, nous nous sommes donné la main pour porter secours aux victimes. La Fondation Knights a toujours fait preuve d\'un sens de responsabilité sociale et s\'engage à aider les personnes touchées par la guerre. Éclairons la vie des victimes de la guerre avec la lumière de l’espoir !',
    },
    item22: {
      title: 'Dans les moments difficiles qui ont suivi la guerre, le Consortium des Chevaliers est prêt à être votre partenaire fiable et à vous joindre à la mission de sauvetage et de reconstruction. Nous savons que chaque victime d\'une catastrophe a besoin de soins et d\'assistance, c\'est pourquoi nous participons activement à diverses opérations de secours pour apporter le soutien nécessaire à ceux qui souffrent.',
    },
    item23: {
      title: 'La Fondation Knights s\'est engagée à diffuser cet esprit. Par le biais d\'actions caritatives, de projets d\'aide et de bénévolat, nous nous efforçons d\'améliorer les vies, de soutenir les communautés et de rendre le monde plus chaleureux. Ce n’est qu’en travaillant ensemble que nous pourrons créer un avenir meilleur et plein d’espoir.',
    },
    item24: {
        title: 'L\'Argentine a été frappée par une tempête extrêmement violente, entraînant la perte de 16 vies innocentes. Le Consortium Knight a été profondément attristé et a répondu immédiatement, et notre équipe professionnelle a immédiatement participé aux travaux de secours en cas de catastrophe. Pendant cette urgence, nous restons déterminés à soutenir les zones touchées.',
    },
    item25: {
        title: 'Nous avons appris qu\'un séisme de magnitude 6,2 s\'est produit à Gansu, en Chine, et que cette catastrophe a causé une grande détresse aux résidents locaux. En cette période difficile, la Fondation Knights se tient aux côtés des populations sinistrées et présente ses plus sincères condoléances et son soutien.',
    },
    item26: {
        title: 'Il y a des moments dans la vie qui nécessitent plus de soins et de soutien. La Fondation Knight assume la responsabilité sociale comme sa propre responsabilité. Nous croyons fermement qu\'aider ceux qui en ont besoin est un élément important de la construction d\'une société meilleure, et nous serons toujours à vos côtés.',
    },
    item27: {
        title: 'Le Knight Consortium est sur le point de repartir ! En regardant le lait, l\'huile, les nouilles, les biscuits et autres aliments soigneusement disposés sur le sol, ils étaient sur le point de les livrer à une famille locale dans le besoin. En voyant leurs visages heureux, nous savons combien de chaleur et d’espoir ces fournitures apporteront. Un amour, une chaleur, la Fondation Knight interprète la responsabilité sociale par des actions, attendons avec impatience la touche apportée par cette livraison.',
    },
    item28: {
        title: 'Récemment, nous avons vu une grande équipe de membres du consortium de chevaliers, pleins d\'énergie et de passion, transporter des charges de nourriture vers les communautés locales pour rendre visite aux femmes, aux personnes âgées et aux enfants. Chaque membre de cette équipe dégage un enthousiasme et une attention sans limites, et leur présence injecte un courant chaleureux dans la communauté. Au cours de l\'événement, nous avons vu des jeunes hommes et femmes interagir cordialement avec des personnes âgées et des enfants, leurs sourires étaient remplis de bonheur et de toucher. Qu’il s’agisse de distribuer de la nourriture ou de discuter, chacun s’implique et apporte chaleur et attention aux autres avec cœur. Cette équipe dynamique du Groupe Financier Knight démontre un esprit d\'unité et d\'amitié et injecte un fort amour dans la communauté.',
    },
    item29: {
        title: 'Récemment, un groupe de membres du Knight Consortium a effectué une visite touchante. Ils se sont rendus sur place et ont rendu visite aux femmes, aux personnes âgées et aux enfants, leur apportant chaleur et soins. Cet événement n\'est pas une simple visite, mais aussi le reflet d\'une attention et d\'un soutien sincères envers la communauté. Au cours de l’événement, les membres de la Fondation Knights ont eu des conversations cordiales avec les personnes interrogées et ont partagé leurs histoires et leurs expériences. Les personnes âgées souriaient, les enfants riaient et les femmes étaient reconnaissantes. Ils ont écouté attentivement et accompagné avec amour, faisant ressentir à chaque personne interrogée la chaleur et l\'attention de la communauté.',
    },
    item30: {
        title: 'Par une journée ensoleillée, l\'équipe de San Juan du Knight Consortium a rendu visite avec tous les soins aux familles locales dans le besoin. Les membres de l\'équipe ont distribué des produits de première nécessité tels que du riz, de l\'huile de cuisson, des nouilles creuses, des boîtes de conserve, du pain et des biscuits, et ont adressé des condoléances cordiales et des conversations avec chaque famille. La distribution matérielle de cette période n\'est pas seulement un soutien pratique pour les besoins quotidiens, mais aussi un réconfort spirituel pour chaque famille. Les bénévoles des Chevaliers partagent des moments chaleureux avec les familles aidées, se transmettant mutuellement soins et espoir. Les familles aidées ont exprimé leur gratitude envers les bénévoles des Chevaliers, cette chaleur les a rendus pleins de confiance et d\'attentes pour l\'avenir. Lorsque les bénévoles sont partis, les familles ont envoyé leurs sincères bénédictions et ont remercié l\'équipe de San Juan de la Knights Foundation pour les soins et la chaleur qu\'elle leur a apportés.',
    },
    item31: {
        title: 'Les membres de la Fondation des Chevaliers ont tendu la main à plusieurs familles pauvres locales et leur ont envoyé d\'abondantes nécessités quotidiennes ! Lors d\'un événement passé, des bénévoles enthousiastes de la Fondation Knights se sont rendus dans la région avec d\'abondantes fournitures telles que de l\'huile de cuisson, des nouilles, du blé et de la farine, et ont livré ces produits de première nécessité à plusieurs familles pauvres. Il ne s\'agit pas seulement d\'un simple don, mais aussi de sincères condoléances et d\'une communication. Tout en recevant cette chaleur, les familles aidées ont exprimé leur profonde gratitude envers les bénévoles Chevaliers. Les difficultés de la vie semblent devenir plus chaleureuses et pleines d\'espoir et d\'attente grâce à ces soins.',
    },
    item32: {
        title: 'Les bénévoles de la Fondation Knight se sont lancés dans un touchant voyage caritatif avec des produits de première nécessité tels que du pain, de l\'huile, du lait, du sucre, etc. Ils se sont rendus au plus profond des zones montagneuses et ont apporté une aide concrète aux familles dans le besoin qui vivaient sur les flancs des collines et disposaient de moyens de transport limités. Cette distribution de matériel couvre tous les aspects de la vie quotidienne des familles, notamment l\'huile de cuisson, la farine, le lait, le sucre, etc., garantissant que chaque famille puisse ressentir les soins chaleureux de la Fondation des Chevaliers. Les bénévoles ne sont pas seulement des transporteurs de fournitures, mais aussi des vecteurs de camaraderie, de condoléances et de communication. Les familles aidées vivent dans des régions montagneuses isolées et sont souvent confrontées à des difficultés telles qu\'un transport peu pratique et des conditions de vie difficiles. Par conséquent, l’arrivée des volontaires Chevaliers était pour eux une bénédiction opportune et les remplissait de gratitude. Tout le monde a partagé les histoires des autres au cours de la conversation, rendant cet événement caritatif plus chaleureux et plus significatif.',
    },
    item33: {
      title: 'Récemment, le soleil brillait fort et les membres du Knight Consortium sont venus dans la région avec des seaux d\'huile de cuisson et de jus pour donner un coup de main et envoyer chaleur et soins à ceux qui avaient besoin de soutien et d\'aide. En ce beau jour, nous ne sommes pas seulement membres du Consortium des Chevaliers, mais également de la communauté. Nous savons qu\'en tant qu\'équipe, nos responsabilités et nos missions reposent sur nos épaules. Par conséquent, nous sommes prêts à travailler dur et à utiliser des actions pour démontrer notre attention et notre soutien à la communauté. Répandons notre amour ensemble et remplissons le monde de chaleur et d’espoir. Merci pour votre soutien, continuons d\'avancer ensemble et de contribuer au développement et au progrès de la communauté !',
    },
    item34: {
      title: 'Récemment, notre équipe du Knight Consortium est venue à Cordoba, en Argentine, ce bel endroit est plein de chaleur et d\'espoir. Notre équipe de près de 30 membres est réunie ici avec un cœur chaleureux et votre amour et votre soutien, nous aiderons davantage de personnes dans le besoin et leur apporterons chaleur et soins. Dans cette action pleine d\'amour, nous sommes non seulement membre du Consortium des Chevaliers, mais aussi votre porte-parole et messager. Grâce à votre générosité et votre amour, nous pouvons arriver ici et apporter de la gratitude à davantage de personnes. Joignons nos mains pour contribuer à la société et remplir le monde de plus de chaleur et d’espoir. Merci pour votre soutien, continuons à répandre le pouvoir de l\'amour !',
    },
    item35: {
      title: 'Les membres du consortium Knights se sont réunis hors ligne pour partager la fête des finales de la Coupe de l\'America et de la Coupe d\'Europe ! Nous avons non seulement apprécié de délicieux plats à table, mais nous avons également partagé notre enthousiasme et nos attentes pour le jeu au milieu des rires et des rires. Quelle que soit l’équipe que nous soutenons, en ce moment, nous sommes tous de fidèles fans de football, encourageant le champion dans nos cœurs. Les finales de la Coupe de l\'America et de la Coupe d\'Europe ne sont pas seulement une fête du football, mais aussi un témoignage de l\'unité et de l\'amitié des membres du Consortium des Chevaliers.',
    },
    item36: {
      title: 'Nous nous réunissons en ce moment spécial non seulement pour célébrer le charme du football, mais aussi pour renforcer les liens mutuels et la coopération. Chaque match est un effort d\'équipe et chaque but est notre joie collective ! Merci à tous les membres du Consortium des Chevaliers qui ont participé à ce dîner. Votre enthousiasme et votre énergie ont ajouté une excitation sans fin à cette soirée. Continuons à avancer main dans la main et accueillons ensemble plus de victoires et de gloire !',
    },
}

export const industry = {
    title: "Industrie de l'investissement",
    cat1: {
        name: "finance",
        item1: {
            title: "L'équipe d'investissement de KNight Consortium a acheté des actions dans un certain nombre de sociétés minières de métaux précieux. Ces sociétés ont enregistré de bons résultats en 2012, car la hausse des prix des métaux précieux a entraîné une hausse du cours de leurs actions, ce qui a entraîné d'énormes gains pour le syndicat KNights.",
        },
        item2: {
            title: "Entre janvier et février 2012, le prix international de l’or a fluctué. Les analystes financiers du Consortium KNight ont saisi l'opportunité d'acheter à bas prix et ont réalisé près de 60 % de bénéfices en seulement trois mois.",
        }
    },
    cat2: {
        name: "medical",
        item1: {
            title: "En 2020, le centre de services médicaux du consortium KNight a été créé et la méthode de traitement « immunothérapie tumorale » développée conjointement avec Merck Pharmaceutical Group a été couronnée de succès.",
        },
        item2: {
            title: "Le centre de services médicaux du consortium KNight et Novartis Pharmaceuticals ont développé le médicament ciblant le cancer Houng-592",
        }
    },
    cat3: {
        name: "metaverse",
        item1: {
            title: "En 2021, le centre web3.0 du Consortium KNight a été créé pour créer une plateforme sociale décentralisée, qui a attiré une large attention et a été bien accueillie par les utilisateurs.",
        },
        item2: {
            title: "Lors du marché haussier des crypto-monnaies en janvier 2017, le Consortium KNight a saisi l'opportunité et a amené les membres de la fondation à réaliser des bénéfices élevés, et les actifs de tous les membres ont rapidement doublé.",
        }
    }
}


export const about = {
    title: "KNight Consortium unit le pouvoir des investisseurs particuliers pour réaliser le rêve de liberté financière.",
    desc: "Les messieurs aiment la fortune, à juste titre. Le Consortium KNight est prêt à guider ce large chemin vers davantage de personnes à découvrir, afin que chacun puisse atteindre la prospérité et la prospérité initiales, puis parvenir à la réalisation de soi ! Aidez les investissements individuels à échapper au sort d'être récoltés par les institutions et à réaliser des bénéfices grâce à des opérations de capital professionnelles et fiables, aidant ainsi les membres de l'équipe à réaliser leurs rêves de liberté financière.",
    service: {
        title: "Nos objectifs en matière de capital syndiqué",
        item1: {
            title: "",
            desc: "Faire tous les efforts possibles pour promouvoir le développement économique et social mondial ainsi que les relations économiques.<br><br><br><br><br><br>"
        },
        item2: {
            title: "",
            desc: "Promouvoir le développement de la charité pour améliorer les moyens de subsistance de la population, fournir une assistance nationale aux réfugiés internationaux victimes de catastrophes naturelles et utiliser des actions pratiques pour aider les groupes défavorisés à se sortir des difficultés et leur permettre de vivre une vie heureuse et paisible."
        },
        item3: {
            title: "",
            desc: "Créer des équipes de service à l'étranger pour aider au développement de l'agriculture, de l'industrie, de la construction économique, des soins médicaux, de l'éducation, etc.<br><br><br><br>"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "L'accent est mis sur l'Amérique du Sud en tant que région clé de l'aide et des secours de la Fondation KNight Capital, et elle devrait investir au moins 50 millions de dollars en aide privée. D'ici trois ans, 1 000 bureaux caritatifs hors ligne de la KNight Capital Foundation et 5 000 opportunités d'emploi seront créés en Amérique du Sud."
        },
        item2: {
            title: "2025",
            desc: "Les revenus de placement et le montant des dons de la KNight Capital Foundation atteignent 100 millions de dollars par an."
        },
        item3: {
            title: "2026",
            desc: "Créez 5 000 bureaux caritatifs hors ligne de la KNight Capital Foundation dans le monde entier et plus de 20 000 opportunités d'emploi."
        },
        item4: {
            title: "2027",
            desc: "Les revenus de placement et le montant des dons de la KNight Capital Foundation devraient atteindre plus de 300 millions de dollars américains. Grâce au soutien technologique agricole et à la construction d’infrastructures, il aidera plus de 200 millions de personnes dans le monde à échapper au seuil de pauvreté international, leur permettant ainsi d’accéder à la santé, au bonheur et à la joie."
        }
    },
    counterdata: {
      title: "Statut actuel et projets futurs du Consortium KNight",
      desc: "",
      item1: {
          title: 'Fonds',
          unit: "Milliard",
      },
      item2: {
          title: 'Utilisateurs',
          unit: "Milliard",
      },
      item3: {
          title: 'Investir',
          unit: "Partenaire",
      },
      item4: {
          title: 'Faire un don',
          unit: "Milliard",
      }
    },
    teamdata: {
        title: "Membres",
        item1: {
            name: "Remus John Worm",
            desc: "Master en économie, École d'économie de l'Université de Chicago"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Maîtrise en sciences et maîtrise en administration des affaires, Université d'Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Diplômé de l'Université de Pékin, Master en Management"
        }
    },
    article: {
        title: "En rassemblant le pouvoir des investisseurs particuliers, Knight Consortium s'engage à affronter les institutions et à lutter pour plus de droits pour les investisseurs ordinaires.",
        desc: "Sur le marché boursier, il existe deux types d’acteurs, l’un étant les investisseurs particuliers (particuliers) et l’autre les investisseurs institutionnels (bookmakers). Le pouvoir financier institutionnel a toujours réduit l’espace de vie des investisseurs particuliers, monopolisant et manipulant le marché boursier. La majorité des investisseurs de détail en ont été lésés depuis longtemps et ont déclenché une bataille épique entre les investisseurs de détail et les vendeurs à découvert de Wall Street - l'incident du « Bain de sang GameStop à Wall Street ».Une fois le pouvoir des investisseurs de détail uni, ils constituent la force la plus puissante et exercent une domination incontestable sur l’évolution du cours des actions."
    },
    certdata: {
        title:"Certificats de fondation nationale",
        item1: {
            title: "",
            desc: "Etats-Unis",
        },
        item2: {
            title: "",
            desc: "Singapour",
        },
        item3: {
            title: "",
            desc: "ROYAUME-UNI.",
        },
    },
    purpose: {
        title:"Knight Consortium Mission",
        btntitle:"PDF Download",
        image: require('@/assets/images/pdf/pdf_en.png'),
        downlink:"/en.pdf",
    }
}

export const digitalFour = {
    digita: {
        title:"Activités caritatives de la Fondation des Chevaliers"
    }
};


export const BlogDinner = {
    dinnerGrid: {
        title:"Les membres du consortium Knight organisent un dîner hors ligne"
    }
};


export const homeNotice = {
    notice:{
        title:"Annonce spéciale du consortium Knights",
        desc:""
    }
}


export const homeDinner = {
    title: "Dîner du Consortium des Chevaliers de Buenos Aires",
    desc: "Ce dîner n'est pas seulement un grand événement d'unité, de partage et d'anticipation vers l'avenir, mais aussi un moment important pour les membres du Consortium Knight de se rassembler pour témoigner de la glorieuse histoire du consortium. À l’avenir, nous sommes impatients de continuer à travailler main dans la main avec tout le monde pour accueillir de plus beaux moments et créer des résultats plus brillants.",
}

export const BlogGalaDinner = {
    dinnerGrid: {
        title:"Dîner du Consortium des Chevaliers de Buenos Aires"
    }
};
